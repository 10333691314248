// @ts-strict-ignore
import { AccountCircle } from '@livechat/design-system-icons';

import { toKeyMap } from 'helpers/array';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

import { type IAgentFilterItem } from './agent-filter';

export const agentFilterConfigMultiple: FilterConfig = {
  title: 'Agent',
  icon: AccountCircle,
  mobileViewTitle: 'Select agents',
  multiple: true,
  getFilterLabelValues: (
    filterValues: string[] | null,
    agents: IAgentFilterItem[],
    filterCompareValues: string[] | null
  ): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    const agentsHash = toKeyMap(agents, 'id');

    if (filterCompareValues) {
      const groupLabels = filterValues.reduce((acc: string[], agentLogin) => {
        if (agentsHash[agentLogin]) {
          acc.push(agentsHash[agentLogin].name);
        }

        return acc;
      }, []);

      const compareGroupLabels = filterCompareValues.reduce((acc: string[], agentLogin) => {
        if (agentsHash[agentLogin]) {
          acc.push(agentsHash[agentLogin].name);
        }

        return acc;
      }, []);

      return [{ key: '0', label: `${groupLabels.join(' OR ')} compare to ${compareGroupLabels.join(' OR ')}` }];
    }

    return filterValues.reduce((acc: FilterLabelValue[], agentLogin) => {
      if (agentsHash[agentLogin]) {
        acc.push({ key: agentsHash[agentLogin].id, label: agentsHash[agentLogin].name });
      }

      return acc;
    }, []);
  },
};
