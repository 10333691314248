import debug from 'debug';
import mitt, { type Emitter } from 'mitt';

import { App } from 'config/setup';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { WebSocketAction } from 'constants/web-socket-action';
import promiseBackoff, { BackoffError, type BackoffStatus, STOP_RETRYING_ERROR_MESSAGE } from 'helpers/promise-backoff';
import type { IncomingMessage } from 'interfaces/incoming-message';

import { type PlatformProtocolParser } from '../../../../platform-protocol-parser';
import { connectivityStatusClient } from '../status/client';
import { ConnectivityStatusEvent, type ConnectivityStatusInstance } from '../status/types';

import {
  type Reconnection,
  ReconnectionStatus,
  type ReconnectionDetails,
  ReconnectorEvent,
  type ReconnectorEventMap,
} from './types';

const RECONNECTION_SUCCEEDED_STATUS_LENGTH = 2500;

const log = debug(DebugLogsNamespace.AppLc3PlatformProtocolParser);

let reconnector: Reconnector | null = null;

/**
 * Initializes the Reconnector instance.
 * @param parser - The platform protocol parser.
 * @returns The initialized Reconnector instance.
 */
export function initializeReconnector(parser: PlatformProtocolParser): Reconnector {
  if (reconnector) {
    log('Reconnector already initialized');

    return reconnector;
  }

  if (!parser) {
    throw new Error('Parser is required to initialize the Reconnector');
  }

  reconnector = new Reconnector(parser);
  log('Reconnector initialized');

  return reconnector;
}

/**
 * Retrieves the Reconnector instance.
 * @throws Error if the Reconnector is not initialized.
 * @returns The Reconnector instance.
 */
export function getReconnector(): Reconnector {
  if (!reconnector) {
    throw new Error('Reconnector not initialized');
  }

  return reconnector;
}

/**
 * Represents a reconnection service that handles the reconnection process
 * for a WebSocket connection.
 */
export class Reconnector {
  private messagesBuffer: IncomingMessage[] = [];

  private connectivityStatus: ConnectivityStatusInstance;
  private reconnection: Reconnection | null = null;
  private shouldStopReconnecting = false;
  private reconnectionDetails: ReconnectionDetails = {
    status: ReconnectionStatus.Idle,
    nextReconnectAttemptTimestamp: null,
  };

  private parser: PlatformProtocolParser;
  private emitter: Emitter<ReconnectorEventMap>;

  /**
   * Creates an instance of Reconnector.
   * @param parser - The platform protocol parser.
   */
  constructor(parser: PlatformProtocolParser) {
    this.parser = parser;
    this.connectivityStatus = connectivityStatusClient;
    void this.connectivityStatus.initialize();
    this.emitter = mitt();
  }

  /**
   * Retrieves the reconnection details.
   * @returns The reconnection details.
   */
  public getReconnectionDetails(): ReconnectionDetails {
    return this.reconnectionDetails;
  }

  /**
   * Sets the reconnection details.
   * @param details - The reconnection details.
   */
  public setReconnectionDetails(details: ReconnectionDetails): void {
    this.reconnectionDetails = details;
    this.emitter.emit(ReconnectorEvent.RECONNECTION_DETAILS_CHANGED, details);
    log(`Reconnection details updated: ${JSON.stringify(details)}`);
  }

  /**
   * Gets the online status of the service.
   * @returns {boolean} The online status of the service.
   */
  public get isOnline(): boolean {
    return this.connectivityStatus.getIsOnline();
  }

  public get isReconnecting(): boolean {
    return [ReconnectionStatus.Retrying, ReconnectionStatus.Scheduled].includes(this.reconnectionDetails.status);
  }

  /**
   * Subscribes to the online status change event.
   *
   * @param handler - The callback function to be called when the online status changes.
   */
  public subscribeToOnlineChange(handler: (isOnline: boolean) => void): void {
    return this.connectivityStatus.subscribe(ConnectivityStatusEvent.ONLINE_STATUS_CHANGED, handler);
  }

  /**
   * Subscribes to reconnection details change events.
   * @param handler - The callback function to be called when reconnection details change.
   */
  public subscribeToReconnectionDetailsChange(handler: (details: ReconnectionDetails) => void): void {
    this.emitter.on(ReconnectorEvent.RECONNECTION_DETAILS_CHANGED, handler);
  }

  /**
   * Unsubscribes from reconnection details change events.
   * @param handler - The callback function to be removed.
   */
  public unsubscribeFromReconnectionDetailsChange(handler: (details: ReconnectionDetails) => void): void {
    this.emitter.off(ReconnectorEvent.RECONNECTION_DETAILS_CHANGED, handler);
  }

  /**
   * Reconnects the service using the provided reconnection options.
   * Reconnection process can be stopped (see "stop" method) due to "offline" browser event.
   * That's why we use "onFail" callback instead of classic "Promise.reject" approach.
   *
   * @param reconnection - The reconnection options.
   * @returns A Promise that resolves when the reconnection is successful, or rejects with an error if the reconnection fails.
   */
  public async reconnect(reconnection: Reconnection): Promise<void> {
    if (this.isReconnecting) {
      return;
    }

    const { reason, maxAttempts = 1, onFail } = reconnection;
    this.reconnection = reconnection;

    log(`Reconnecting because of: ${reason}...`);

    try {
      await this.performReconnectionWithBackoff(maxAttempts);
      this.handleSuccessfulReconnection(reason);
    } catch (error) {
      this.handleFailedReconnection(error, reason, onFail);
    }
  }

  /**
   * Restarts the reconnection process.
   * If there is no stored reconnection, it logs a message and returns.
   * Otherwise, it sets the `shouldStopReconnecting` flag to false,
   * and initiates the reconnection process with the stored reconnection.
   */
  public restart(): void {
    if (!this.reconnection) {
      log('No stored reconnection to restart');

      return;
    }
    this.shouldStopReconnecting = false;

    void this.reconnect(this.reconnection);

    return;
  }

  /**
   * Stops the reconnection process if it is currently in progress.
   */
  public stop(): void {
    if (this.reconnectionDetails.status === ReconnectionStatus.Idle) {
      log('No reconnection in progress to stop');

      return;
    }

    this.setReconnectionDetails({
      status: ReconnectionStatus.Idle,
      nextReconnectAttemptTimestamp: null,
    });

    this.shouldStopReconnecting = true;
  }

  /**
   * Gets a value indicating whether the reconnector is currently handling reconnection.
   * The reconnector is considered to be handling reconnection if it is not in the 'idle' state.
   * Note that the reconnector can still be handling reconnection even if the 'offline' event was fired before
   * the browser went 'online'.
   * @returns A boolean value indicating whether the reconnector is handling reconnection.
   */
  public get isHandlingReconnection(): boolean {
    return !!this.reconnection;
  }

  /**
   * Parses the incoming message and handles it based on the current state of the reconnector.
   * If the reconnector is in the idle state or reconnecting state with a login action, the message is parsed immediately.
   * If the reconnector is in the reconnecting state without a login action, the message is buffered for later parsing.
   *
   * @param message - The incoming message to be parsed.
   */
  public parseMessage(message: IncomingMessage): void {
    if (
      this.reconnectionDetails.status === ReconnectionStatus.Idle ||
      (this.isReconnecting && message.action === WebSocketAction.Login)
    ) {
      this.parser.parse(message);

      return;
    }

    /**
     * This is to buffer messages that were ignored during the reconnect process.
     * There can be a situation when, after a successful login, we can receive
     * immediate messages from WebSocket but the state of `Reconnector` hasn't been changed.
     * We are buffering such messages and parsing them after a successful reconnect.
     */
    if (this.isReconnecting) {
      this.messagesBuffer.push(message);

      return;
    }
  }

  /**
   * Performs the reconnection process with backoff.
   *
   * @param maxAttempts - Maximum number of reconnection attempts.
   */
  private async performReconnectionWithBackoff(maxAttempts: number): Promise<void> {
    await promiseBackoff(
      () => this.closeAndLogin(),
      maxAttempts,
      () => this.shouldStopReconnecting,
      {
        onRetryScheduled: this.updateReconnectionDetails.bind(this),
        onRetry: this.updateReconnectionDetails.bind(this),
      }
    );
  }

  /**
   * Closes the server and logs in to the WebSocket.
   *
   * @returns A Promise that resolves if login is successful, otherwise rejects.
   */
  private closeAndLogin(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (App.server && App.loginWithCookies) {
        App.server.close();
        App.loginWithCookies()
          .then((ok) => (ok ? resolve() : reject(new Error('Login with cookies failed'))))
          .catch(reject);
      }
    });
  }

  /**
   * Handles successful reconnection.
   *
   * @param reason - Reason for reconnection.
   */
  private handleSuccessfulReconnection(reason: string): void {
    log(`Successful reconnection (reason: ${reason})`);
    this.setReconnectionDetails({
      nextReconnectAttemptTimestamp: null,
      status: ReconnectionStatus.Succeeded,
    });
    this.reconnection = null;
    this.shouldStopReconnecting = false;
    this.flushMessagesBuffer();

    setTimeout(() => {
      if (this.reconnectionDetails.status === ReconnectionStatus.Succeeded) {
        this.setReconnectionDetails({
          nextReconnectAttemptTimestamp: null,
          status: ReconnectionStatus.Idle,
        });
      }
    }, RECONNECTION_SUCCEEDED_STATUS_LENGTH);
  }

  /**
   * Handles failed reconnection.
   *
   * @param error - Error that occurred during reconnection.
   * @param reason - Reason for reconnection.
   * @param onFail - Optional callback to handle failure.
   */
  private handleFailedReconnection(error: unknown, reason: string, onFail?: (error: unknown) => void): void {
    this.shouldStopReconnecting = false;

    if ((error as Error)?.message === STOP_RETRYING_ERROR_MESSAGE) {
      return;
    }

    const errorDetails = error instanceof BackoffError ? error.backoffErrors.toString() : (error as Error).message;
    log(`Could not reconnect on '${reason}' - ${errorDetails}`);

    if (onFail) {
      this.reconnection = null;
    }

    this.updateReconnectionDetails(ReconnectionStatus.Failed, null, !onFail);
    onFail?.(error);
  }

  /**
   * Flushes the messages buffer by parsing each message using the parser.
   * After parsing, the messages buffer is cleared.
   */
  private flushMessagesBuffer(): void {
    this.messagesBuffer.forEach(this.parser.parse, this.parser);

    this.messagesBuffer = [];
  }

  /**
   * Updates the reconnection details.
   *
   * @param status - The status of the reconnection.
   * @param nextReconnectAttemptTimestamp - The timestamp of the next reconnect attempt.
   * @param allowManual - Indicates whether manual reconnection is allowed. Default is true.
   */
  private updateReconnectionDetails(
    status: ReconnectionDetails['status'] | BackoffStatus,
    nextReconnectAttemptTimestamp: ReconnectionDetails['nextReconnectAttemptTimestamp'],
    allowManual = true
  ): void {
    this.setReconnectionDetails({
      status: status as ReconnectionStatus,
      nextReconnectAttemptTimestamp,
      allowManual,
    });
  }
}
