import { type FC, createContext, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppsViewActions } from 'store/views/apps/actions';

const AppsContext = createContext<null>(null);

export const AppsContextProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pathname.includes('/apps/marketplace')) {
      dispatch(AppsViewActions.setMarketplaceRoute(null));
    }
  }, [dispatch, pathname]);

  return <AppsContext.Provider value={null}>{children}</AppsContext.Provider>;
};
