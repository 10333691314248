import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { fetchStartupData } from 'entries/base/services/initial-data/fetch-startup-data';
import { initializeApp } from 'services/startup/initialize/initialize-app';

import { connectAndLogin } from './connect-and-login';
import { handleStartupError } from './handle-startup-error';
import { type IServerConnectOptions } from './interfaces';

const log = debug(DebugLogsNamespace.AppServerConnection);

export async function reinitializeApp(options: IServerConnectOptions): Promise<void> {
  const { isGhost } = options;

  log('Fetching startup data');
  const startupDataResult = await fetchStartupData(isGhost);

  if (startupDataResult.success === false) {
    const error = startupDataResult.failReason;

    await handleStartupError(error);

    // do not attempt to connect any further
    return;
  }

  const { startupData } = startupDataResult;
  initializeApp(startupData);
  log('Startup data fetched and stored');

  await connectAndLogin(options);
}
