import { createElement, type FC, type ReactChild, type ReactNode } from 'react';

import type { BadgeType } from 'components/badge/Badge';
import { shortenedCountNumber } from 'helpers/string';
import { useParamSelector } from 'hooks/use-param-selector';
import type { NavigationItemBadgeComponent } from 'interfaces/components/navigation/navigation-item-badge';
import type { INavigationItemBadge } from 'store/views/navigation/interfaces';
import { getNavigationItemBadge } from 'store/views/navigation/selectors';

interface Props {
  id: string;
  independent?: boolean;
  component?: NavigationItemBadgeComponent;
  type?: BadgeType;
  content?: ReactChild;
  active?: boolean;
}

const calculateContent = (fromProps: ReactNode, fromStore: INavigationItemBadge): ReactNode => {
  if (fromProps) {
    return fromProps;
  }
  if (fromStore?.numeric) {
    return shortenedCountNumber(fromStore.numeric);
  }

  return null;
};

export const NavigationItemBadge: FC<Props> = ({ independent, component, type, content, id, active }) => {
  const calculatedContent = calculateContent(content, useParamSelector(getNavigationItemBadge, id));

  if (component) {
    if (independent) {
      return createElement(component, { active });
    }
    if (calculatedContent) {
      return createElement(component, { type, active }, calculatedContent);
    }
  }

  return null;
};
