import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

import { ONE_COLUMN_BREAKPOINT } from 'constants/column-layout';
import { withVar } from 'helpers/styles';

export const wrapper = css`
  display: grid;
  grid-template-columns: 545px 320px;
  gap: ${withVar(SpacingToken.Spacing24)};
  padding: 100px ${withVar(SpacingToken.Spacing5)};
  justify-content: center;
  align-items: start;
  height: 100%;
  background-color: ${withVar(DesignToken.SurfacePrimaryDefault)};

  @media (max-width: ${ONE_COLUMN_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

export const iconStyles = css`
  margin-top: -6px;
  align-self: start;
`;

export const content = css`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: ${withVar(SpacingToken.Spacing5)};
`;

export const promo = css`
  display: grid;
  gap: ${withVar(SpacingToken.Spacing6)};
`;

export const buttonStyles = css`
  max-width: 204px;
`;

export const imageWrapper = css`
  position: sticky;
  top: 10px;
`;

export const imageStyles = css`
  max-width: 320px;
  width: 100%;
`;
