export const enum PostMessageEvent {
  AppInstalled = 'app_installed',
  AppPurchased = 'app_purchased',
  AppRemoved = 'app_removed',
  AppSettingsOpened = 'app_settings_opened',
  AppEvent = 'app_event',
  CartPurchased = 'cart_purchased',
  CartUpdated = 'cart_updated',
  Loaded = 'loaded',
  MarketplaceCartUpdate = 'marketplace_cart_update',
  Navigate = 'navigate',
  OpenInAppUpgradesModal = 'open_in_app_upgrades_modal',
  Preload = 'preload',
  RouteChange = 'route_change',
  QueryStringChange = 'query_string_change',
  RefreshInstalledApps = 'refresh_installed_apps',
  TogglePinApp = 'toggle_pin_app',
  MailTo = 'mailto',
  RefreshSubscriptionInfo = 'refresh_subscription_info',
  CheckOnboarding = 'check_onboarding',
  Breadcrumbs = 'breadcrumbs',
}

export const enum IncomingOneClickPaymentEvent {
  RegisterTransactionPending = 'register_transaction_pending',
  RegisterTransactionSuccess = 'register_transaction_success',
  RegisterTransactionFailure = 'register_transaction_failure',
}

export const enum OutgoingOneClickPaymentEvent {
  TransactionAccepted = 'transaction_accepted',
  TransactionFailed = 'transaction_failed',
  TransactionDeclined = 'transaction_declined',
  UpdateBillingCycle = 'update_billing_cycle',
}
