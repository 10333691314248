import { App } from 'config/setup';
import { type ActionsApi, type AppApi, type DataApi, type SupportApi } from 'entries/base/interfaces/app-api';
import { externalApiReady } from 'entries/base/services/external-api/api';
import { getConfig } from 'helpers/config';
import { downloadBugReport } from 'helpers/download-bug-report';
import { downloadState } from 'helpers/download-state';
import { isGhostLogin } from 'helpers/ghost';
import { navigate as navigateTo } from 'helpers/routing';
import { type DesktopAppApi } from 'interfaces/desktop-app/api';
import type { IReportsViewUpdateFiltersPayload } from 'interfaces/reports';
import { AppStateProvider } from 'services/app-state-provider';
import { getDesktopApp } from 'services/desktop-application/desktop-app';
import { getIsPaidLicense, getLicenseId, getPlanType } from 'store/features/session/selectors';
import { ReportsViewActions } from 'store/views/reports/actions';

function getStoreProvider(): unknown {
  return AppStateProvider;
}

function downloadCurrentState(): Promise<void> {
  return downloadState();
}

function navigate(pathname: string): void {
  navigateTo(pathname);
}

function getLicense(): string {
  return AppStateProvider.selectFromStore(getLicenseId)?.toString() ?? '';
}

function isPaidLicense(): boolean {
  return AppStateProvider.selectFromStore(getIsPaidLicense);
}

function getSalesPlan(): string {
  return AppStateProvider.selectFromStore(getPlanType)?.toString() ?? '';
}

function setReportsFilters(filters: IReportsViewUpdateFiltersPayload): void {
  AppStateProvider.dispatch(ReportsViewActions.setFilters(filters));
}

/**
 * Please make sure that store is initialized and all data extracted by the api is available in the store
 * and all services are ready to be used before broadcasting the external api is ready
 */
export function initializeExternalApi(): void {
  const actions: ActionsApi = {
    navigate,
    setReportsFilters,
  };

  const data: DataApi = {
    getConfig,
    getLicense,
    isPaidLicense,
    getSalesPlan,
    isGhostLogin,
  };

  const desktopApp: DesktopAppApi = getDesktopApp();

  const support: SupportApi = {
    getStoreProvider,
    downloadCurrentState,
    downloadBugReport,
  };

  const api: AppApi = {
    actions,
    data,
    desktopApp,
    support,

    // deprecated section
    navigate,
    getLicense,
    isPaidLicense,
    getSalesPlan,
    getConfig,
    isGhostLogin,
    setReportsFilters,
  };

  // todo: replace the usage of `App.api` with `App.getApi()` in external scripts
  App.api = api;

  externalApiReady(api);
}
