import { PostMessageEvent } from 'constants/post-message-event';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { type IApplication } from 'store/entities/applications/interfaces';

export const preloadMarketplacePage = (path = '/apps/'): void => {
  sendPostMessageToMarketplace(PostMessageEvent.Preload, { pathname: path });

  // navigate in the background - reduces MP page flickering when navigating between sections and MP
  if (!window.location.pathname.includes('/apps/marketplace')) {
    sendPostMessageToMarketplace(PostMessageEvent.Navigate, { pathname: path, replace: true });
  }
};

export const sortByCustomApplicationOrder = (applicationsOrder: string[]) => (a: IApplication, b: IApplication) => {
  const appAIndex = applicationsOrder.indexOf(a.id);
  const appBIndex = applicationsOrder.indexOf(b.id);

  return appAIndex - appBIndex;
};
