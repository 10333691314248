import { type ReactNode, createElement } from 'react';

function mapNodeListToReactElements(nodes: NodeList, allowedNodeNames: string[]): ReactNode {
  const { map } = Array.prototype;

  return map.call(nodes, (node: HTMLElement, index: number) => {
    const props: any = { key: index };

    if (node.nodeName === '#text') {
      return node.textContent;
    }

    if (!allowedNodeNames.includes(node.nodeName)) {
      return node.hasChildNodes() ? mapNodeListToReactElements(node.childNodes, allowedNodeNames) : null;
    }

    if (node.nodeName === 'A' && node.hasAttribute('href')) {
      props.href = node.getAttribute('href');
      props.target = '_blank';
      props.rel = 'nofollow noopener';
    }

    return createElement(node.nodeName.toLowerCase(), {
      ...props,
      ...(node.hasChildNodes() && { children: mapNodeListToReactElements(node.childNodes, allowedNodeNames) }),
    });
  });
}

export function mapTextToReactElements(text: string, allowedNodeNames: string[]): ReactNode {
  const parser = new DOMParser();
  const { body } = parser.parseFromString(text, 'text/html');

  return body ? mapNodeListToReactElements(body.childNodes, allowedNodeNames) : '';
}
