import { type SVGProps, type FC } from 'react';

import { SideNavigationGroup, SideNavigationItem, Icon } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { AppsEvent, AppsSection } from 'constants/apps/events';
import { navigateToMarketplace } from 'helpers/routing';
import { preloadMarketplacePage } from 'routes/apps/helpers';
import { trackAppsSectionEvent } from 'services/event-tracking';
import { AppsViewActions } from 'store/views/apps/actions';
import { getCurrentMarketplaceRoute } from 'store/views/apps/selectors';

import { checkIfActive } from '../helpers';

import { ADDITIONAL_SECTIONS_LINKS, MARKETPLACE_SECTIONS_LINKS } from './constants';

export const AdditionalSectionsList: FC = () => {
  const currentMarketplaceRoute = useSelector(getCurrentMarketplaceRoute);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const handleMarketplaceSectionClick = (link: string, label: string): void => {
    navigateToMarketplace(link);
    dispatch(AppsViewActions.setMarketplaceRoute(link));
    trackAppsSectionEvent(AppsEvent.SectionItemClicked, AppsSection.SideNavigation, { label });
  };

  const handleAdditionalSectionClick = (link: string, label: string): void => {
    navigate(link);
    trackAppsSectionEvent(AppsEvent.SectionItemClicked, AppsSection.SideNavigation, {
      label,
    });
  };
  const handleHoverMarketplaceItem = (link: string): void => preloadMarketplacePage(link);

  return (
    <SideNavigationGroup>
      {MARKETPLACE_SECTIONS_LINKS.map(({ label, link, icon }) => (
        <SideNavigationItem
          isActive={checkIfActive(link, currentMarketplaceRoute)}
          key={link}
          url={link}
          label={label}
          onClick={() => handleMarketplaceSectionClick(link, label)}
          onItemHover={() => handleHoverMarketplaceItem(link)}
          leftNode={<Icon source={icon as FC<SVGProps<SVGSVGElement>>} />}
        />
      ))}
      {ADDITIONAL_SECTIONS_LINKS.map(({ label, link, icon }) => {
        const isActive = Boolean(matchPath({ path: link }, pathname));

        return (
          <SideNavigationItem
            isActive={isActive}
            key={link}
            url={link}
            label={label}
            onClick={() => handleAdditionalSectionClick(link, label)}
            leftNode={<Icon source={icon} />}
          />
        );
      })}
    </SideNavigationGroup>
  );
};
