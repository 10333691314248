import { type FC } from 'react';

import { cx } from '@emotion/css';
import { Text } from '@livechat/design-system-react-components';
import Markdown, { type Options } from 'react-markdown';
import { useSelector } from 'react-redux';

import { getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

import { useCopilotMessageContext } from '../CopilotMessageContext';

import { CopilotAvatar } from './CopilotAvatar';
import { CopilotReasoningStatus } from './CopilotReasoningStatus';

import * as styles from './styles';

const componentsConfig: Options['components'] = {
  pre: ({ children }) => <>{children}</>,
};

export const CopilotStreamMessage: FC = () => {
  const { isReasoning, message } = useCopilotMessageContext();
  const isCopilotExpanded = useSelector(getIsCopilotModalExpanded);

  if (!message && !isReasoning) {
    return null;
  }

  return (
    <div className={styles.bubblePosition}>
      {isCopilotExpanded && <CopilotAvatar />}
      <div className={cx(styles.eventsWrapper(false, isCopilotExpanded))}>
        <div className={cx(styles.messageEventWrapper(), 'bubble-wrapper')}>
          <div className={styles.eventWrapper()}>
            <div className={cx('bubble', styles.bubble)}>
              <Text className={styles.eventText}>
                <div>
                  {message && (
                    <Markdown components={componentsConfig} className={styles.markdown}>
                      {message}
                    </Markdown>
                  )}
                  {isReasoning && !message && <CopilotReasoningStatus />}
                </div>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
