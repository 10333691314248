import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { DEFAULT_RESPONSE_TIMEOUT } from 'constants/server';
import { delay } from 'helpers/delay';
import { uniqueId } from 'helpers/string';
import {
  type IMessageErrorPayload,
  type IncomingMessage,
  type IncomingResponseMessage,
} from 'interfaces/incoming-message';
import { type IServer } from 'interfaces/server';
import { type WebSocketEvent } from 'interfaces/web-socket-events';

const log = debug(DebugLogsNamespace.AppWebSocket);

export async function sendEvent<T>(
  server: IServer,
  webSocketEvent: WebSocketEvent
): Promise<IncomingResponseMessage<T>> {
  const { action, payload, version } = webSocketEvent;
  const requestId = uniqueId();

  const message = {
    action,
    payload,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    request_id: requestId,
    ...(version && { version }),
  };

  log(`Sending message: ${action}`, requestId, payload);

  const handleMessage = new Promise<IncomingResponseMessage<T>>((resolve) => {
    const handler = (message: IncomingMessage): void => {
      if (message.type === 'response' && message.request_id === requestId) {
        server.off('message', handler);
        resolve(message);
      }
    };

    server.on('message', handler);
  });

  server.send(message);

  const response = await Promise.race([delay(DEFAULT_RESPONSE_TIMEOUT), handleMessage]);

  if (response) {
    log(`Request response for ${action}`, requestId, response);

    return response;
  }

  log(`Timeout for ${action} ${requestId}`);

  const error: IMessageErrorPayload = {
    error: { type: 'request_timeout', message: 'Protocol event response timeout' },
  };

  throw error;
}
