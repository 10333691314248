// @ts-strict-ignore
import { NotificationStatus } from 'constants/browser-notifications';
import { Cookie } from 'constants/cookies';
import { TopBarNotificationType } from 'constants/notifications';
import { getCookie } from 'helpers/cookies';
import { getTopBarNotificationOptions } from 'helpers/notification-bar';
import { getNotificationPermissionStatus, deviceSupportsNotifications } from 'helpers/notifications';
import { type ITopBarNotification } from 'interfaces/notification-bar';

export const comparePriority = (a: ITopBarNotification, b: ITopBarNotification): number =>
  getTopBarNotificationOptions(b.name).priority - getTopBarNotificationOptions(a.name).priority;

export const shouldShowBrowserNotificationsBar = (): boolean | TopBarNotificationType => {
  const supportsNotifications: boolean = deviceSupportsNotifications();
  const notificationStatus = getNotificationPermissionStatus();
  const hasEnabledNotifications = notificationStatus === NotificationStatus.Enabled && supportsNotifications;
  const hasDefaultNotificationSettings = notificationStatus === NotificationStatus.Default;

  if (hasEnabledNotifications) {
    return false;
  }

  if (hasDefaultNotificationSettings && getCookie(Cookie.EnableNotificationsBarSkipped) === 1) {
    return false;
  }

  if (getCookie(Cookie.UnblockNotificationsBarSkipped) === 1) {
    return false;
  }

  let barType: TopBarNotificationType;

  if (!supportsNotifications) barType = TopBarNotificationType.ChangeBrowser;
  if (hasDefaultNotificationSettings) barType = TopBarNotificationType.EnableNotifications;
  if (notificationStatus === NotificationStatus.Disabled) barType = TopBarNotificationType.UnblockNotifications;

  return barType;
};
