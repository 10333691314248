const DEFAULT_FRACTION = 0.01; // 1%

export function logCodeUsage(
  snippetName: string,
  params: Record<string, unknown> = {},
  fraction = DEFAULT_FRACTION
): void {
  if (Math.random() < fraction) {
    // TODO: Write a new way to log code usage
    // eslint-disable-next-line no-console
    console.warn(`Code usage: ${snippetName}`, params);
  }
}
