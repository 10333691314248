// @ts-strict-ignore
import { Calendar } from '@livechat/design-system-icons';
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import {
  CustomDateRangeFilterId,
  DateRangeFilterFormatedValues,
  type CustomDateRange,
  type CustomDateRangeFilter,
} from 'constants/filters/date-range-filter';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

import * as styles from './styles';

export const dateRangeFilterConfig: FilterConfig = {
  title: 'Date',
  icon: Calendar,
  multiple: false,
  getFilterLabelValues: (
    filterValues: CustomDateRangeFilter | null,
    items,
    filterCompareValues: CustomDateRange | null
  ): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    let label;
    if (filterValues.id !== CustomDateRangeFilterId.CustomPeriod || !filterValues.from || !filterValues.to) {
      label = DateRangeFilterFormatedValues[filterValues.id];
    } else {
      label = `${format(filterValues.from, DateFormat.ShortDate)} - ${format(filterValues.to, DateFormat.ShortDate)}`;
    }

    if (filterCompareValues) {
      let compareLabel: string;
      if (
        filterCompareValues.id !== CustomDateRangeFilterId.CustomPeriod ||
        !filterCompareValues.from ||
        !filterCompareValues.to
      ) {
        compareLabel = DateRangeFilterFormatedValues[filterCompareValues.id];
      } else {
        compareLabel = `${format(filterCompareValues.from, DateFormat.ShortDate)} - ${format(
          filterCompareValues.to,
          DateFormat.ShortDate
        )}`;
      }
      label += ` compare to ${compareLabel}`;
    }

    return [
      {
        key: filterValues.id,
        label,
      },
    ];
  },
  getFilterBoxClassName: (filterValue: CustomDateRange, filterCompareValues: CustomDateRange) => {
    if (
      (filterValue === null || filterValue.id !== CustomDateRangeFilterId.CustomPeriod) &&
      (filterCompareValues === null ||
        (filterCompareValues && filterCompareValues.id !== CustomDateRangeFilterId.CustomPeriod))
    ) {
      return null;
    }

    return styles.filterBox;
  },
  getFilterButtonClassName: (filterValue: CustomDateRangeFilter) => {
    if (filterValue === null || filterValue.id !== CustomDateRangeFilterId.CustomPeriod) {
      return null;
    }

    return styles.filterButton;
  },
};
