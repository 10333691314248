import { type Task } from 'redux-saga';

import { entitiesSagas } from '../entities/entities-sagas';
import { featuresSagas } from '../features/features-sagas';
import { viewSagas } from '../views/views-sagas';

import { applicationsSagas } from './applications/applications-sagas';
import { billingSagas } from './billing/billing-sagas';
import { sagaMiddleware } from './middleware';
import { productCartSagas } from './product-cart/product-cart-sagas';
import { subscriptionSagas } from './subscription/subscription-sagas';

const tasks: Task[] = [];

export function runAppSagas(sagas = sagaMiddleware): void {
  tasks.push(
    sagas.run(viewSagas),
    sagas.run(featuresSagas),
    sagas.run(entitiesSagas),
    sagas.run(applicationsSagas),
    sagas.run(billingSagas),
    sagas.run(productCartSagas),
    sagas.run(subscriptionSagas)
  );
}

export function stopAppSagas(): void {
  tasks.forEach((task) => {
    task.cancel();
  });
}
