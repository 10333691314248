import type { FC } from 'react';

import { cx } from '@emotion/css';
import { Text } from '@livechat/design-system-react-components';
import Markdown, { type Options } from 'react-markdown';

import { getIsCopilotStreamingEnabled } from 'helpers/copilot-streaming';
import { type CopilotMessageEvent as CopilotMessageEventType } from 'store/entities/copilot/interfaces';

import { ActionButtonsMap } from '../buttons/action-buttons-map';
import { CopilotAssistantMessageBottomBar } from '../buttons/CopilotAssistantMessageBottomBar';
import { NavigateButton } from '../buttons/NavigateButton';

import { CopilotAgentMessage } from './CopilotAgentMessage';
import { CopilotLinkWithTooltip } from './CopilotLinkWithTooltip';

import * as styles from './styles';

interface IProps {
  event: CopilotMessageEventType;
  isAgentMessage: boolean;
}

const componentsConfig: Options['components'] = {
  a: ({ children, href }) => <CopilotLinkWithTooltip href={href}>{children}</CopilotLinkWithTooltip>,
  pre: ({ children }) => <>{children}</>,
};

export const CopilotMessageEvent: FC<IProps> = ({ event, isAgentMessage }) => {
  const navigateButtons = event?.properties?.navigateButtons;
  const isWithNavigateButtons = !!navigateButtons?.length;
  const actionButtons = event?.properties?.actionButtons;
  const isWithActionButtons = !!actionButtons?.length;
  const isWithHeader = !!event.header;
  const hasCancelledRequest = event?.properties?.hasCancelledRequest;

  const shouldDisplayBottomBar = !isAgentMessage && !event?.properties?.withoutActions;
  const isMessageWithButtons = isWithNavigateButtons || isWithActionButtons;
  const isStreamingEnabled = getIsCopilotStreamingEnabled();

  if (isAgentMessage) {
    return <CopilotAgentMessage hasCancelledRequest={hasCancelledRequest && !isStreamingEnabled} text={event.text} />;
  }

  return (
    <div className={cx(styles.messageEventWrapper(), 'bubble-wrapper')}>
      <div className={styles.eventWrapper()} key={event.eventId}>
        <div className={cx('bubble', styles.bubble)}>
          <Text className={styles.eventText}>
            <div className={styles.outerWrapper}>
              {isWithHeader && <p className={styles.messageHeading}>{event.header}</p>}
              <Markdown
                className={cx(styles.markdown, {
                  [styles.cancelledMessage]: hasCancelledRequest && isStreamingEnabled,
                })}
                components={componentsConfig}
              >
                {event.text}
              </Markdown>
            </div>
          </Text>

          {isMessageWithButtons && (
            <div className={styles.buttonContainer}>
              {navigateButtons?.map(({ url, ...props }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <NavigateButton key={url} url={url} {...props} />
              ))}
              {actionButtons?.map(({ type, ...props }) => {
                const Component = ActionButtonsMap[type];

                // eslint-disable-next-line react/jsx-props-no-spreading
                return <Component eventId={event.eventId} key={type} {...props} />;
              })}
            </div>
          )}
        </div>
      </div>
      {shouldDisplayBottomBar && <CopilotAssistantMessageBottomBar event={event} />}
    </div>
  );
};
