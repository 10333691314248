//@ts-strict-ignore
import { isAfter, isBefore } from 'date-fns';
import { createSelector } from 'reselect';

import type { IStoreState } from 'interfaces/store/store-state';
import {
  getCanManageBots,
  getCreationTimestamp,
  isAtLeastAdmin as getIsAtLeastAdmin,
  isOwner,
  type IWithSessionState,
} from 'store/features/session/selectors';

import type { IChatbotInLivechatState } from './interfaces';
export interface IWithChatbotInLivechatData {
  entities: {
    chatbotInLivechat: IChatbotInLivechatState;
  };
}

const RELEASE_DATE = new Date('2024-01-12 08:00:00');

const getIsLicenseCreatedBeforeReleaseDate = (state: IWithSessionState): boolean => {
  const creationDate = new Date(getCreationTimestamp(state));

  return isBefore(creationDate, RELEASE_DATE);
};

export function shouldInitChatbotInLivechat(state: IStoreState): boolean {
  const today = new Date();
  const isOwnerUser = isOwner(state);

  return isOwnerUser && isAfter(today, new Date(RELEASE_DATE));
}

export const getCanAccessChatbotInLivechat = createSelector(
  getIsLicenseCreatedBeforeReleaseDate,
  getCanManageBots,
  getIsAtLeastAdmin,
  (isLicenseCreatedBeforeReleaseDate, canManageBots, isAtLeastAdmin) =>
    !isLicenseCreatedBeforeReleaseDate && canManageBots && isAtLeastAdmin,
);
