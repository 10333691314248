import { type ChatEventType } from 'constants/chat-event-type';
import type { ChatBaseResult } from 'interfaces/api/chat';
import type { ThreadEventResult } from 'interfaces/api/event/thread';
import { type Properties } from 'interfaces/property';

import { type IThreadBaseResult } from '../interfaces/thread';

export const enum APIErrorMessage {
  ChatAlreadyActive = 'Chat is already active',
  ChatInaccessible = 'No Access',
  OneOfThreadsRemoved = '`chat.id` not found',
  MissingAccessGroup = 'Requester missing access. Details: missing access to group',
}

// API response interfaces (may have common parts with RTM responses)
export interface ILastEventResult {
  event: ThreadEventResult;
  restricted_access?: string;
  thread_id: string;
  thread_created_at?: string;
}

export interface ILastEventPerTypeResult {
  [ChatEventType.Event]?: ILastEventResult;
  [ChatEventType.FilledForm]?: ILastEventResult;
  [ChatEventType.Message]?: ILastEventResult;
  [ChatEventType.RichMessage]?: ILastEventResult;
  [ChatEventType.SystemMessage]?: ILastEventResult;
}

/**
 * Last thread summary structure.
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#chat-summaries}
 */
export interface ILastThreadSummaryResult extends IThreadBaseResult {}

/**
 * Chat summary structure.
 * @see {@link https://platform.text.com/docs/messaging/agent-chat-api/data-structures#chat-summaries}
 */
export interface ChatSummaryResult extends ChatBaseResult {
  last_event_per_type?: ILastEventPerTypeResult;
  last_thread_summary?: ILastThreadSummaryResult;
}

export interface ThreadDetailsResult extends IThreadBaseResult {
  events?: ThreadEventResult[];
  tags?: string[];
}

export interface IChatResult extends ChatBaseResult {
  thread?: ThreadDetailsResult;
}

export type ChatResultType = IChatResult | ChatSummaryResult;
export type ThreadResultType = ThreadDetailsResult | ILastThreadSummaryResult;

/**
 * Request result for `Send event` method.
 * @see https://developers.livechat.com/docs/messaging/agent-chat-api/#send-event
 */
export interface ISendEventResult {
  event_id: string;
}

export interface IUpdateEventBody {
  chat_id: string;
  thread_id: string;
  event_id: string;
  properties: Properties;
}
