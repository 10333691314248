import { useCallback, type FC } from 'react';

import { SideNavigationItem } from 'components/side-navigation-v2/SideNavigationItem';
import { SideNavigationList } from 'components/side-navigation-v2/SideNavigationList';
import { AppsEvent, AppsSection } from 'constants/apps/events';
import { navigate } from 'helpers/routing';
import { trackAppsSectionEvent } from 'services/event-tracking';
import { ApplicationWidgetPlacement, type IApplication } from 'store/entities/applications/interfaces';

import { ApplicationListItem } from '../ApplicationListItem/ApplicationListItem';

import { YourAppsListSubItem } from './constants';
import { YourAppsItemBadge } from './YourAppsItemBadge';
import { YourAppsItemPin } from './YourAppsItemPin';

import { collapseLabel, labelItem, nestedItem, nestedItemAnchor } from './styles';

interface Props {
  app: IApplication;
  listPathname: string;
}

export const YourAppsItem: FC<Props> = ({ app, listPathname }) => {
  const {
    id: appId,
    name,
    slug,
    elements,
    alternativeFullscreenWidgetPath,
    interfaceDarkIcons,
    icons,
    isInternalProduct,
  } = app;

  const widgets = Object.values(elements.widgets || {});
  const fullscreenWidget = widgets.find(({ placement }) => placement === ApplicationWidgetPlacement.FullScreen);

  const settingsLink = `/apps/settings/${slug || appId}`;
  const fullscreenAppLink = alternativeFullscreenWidgetPath
    ? `${alternativeFullscreenWidgetPath}/${fullscreenWidget?.id}`
    : `/apps/${slug || fullscreenWidget?.id}`;
  const appIcon = interfaceDarkIcons?.small || icons?.small;

  const handleYourAppsListSubItemClick = useCallback(
    (link: string, label: YourAppsListSubItem) => {
      navigate(link);

      trackAppsSectionEvent(
        label === YourAppsListSubItem.FullscreenApp ? AppsEvent.FullscreenAppClicked : AppsEvent.SettingsClicked,
        AppsSection.SideNavigation,
        {
          label,
          applicationId: appId,
          applicationName: name,
        },
      );
    },
    [appId, name],
  );

  const handleApplicationListItemClick = useCallback(() => {
    trackAppsSectionEvent(AppsEvent.YourAppsChevronItemClicked, AppsSection.SideNavigation, {
      applicationId: appId,
      applicationName: name,
    });
  }, [appId, name]);

  return (
    <SideNavigationList
      label={<ApplicationListItem appIcon={appIcon} name={name} onClick={handleApplicationListItemClick} />}
      labelItemClassName={labelItem}
      labelClassName={collapseLabel}
      noGaps
      isCollapsible
      shouldOpenOnActive
      rightNode={fullscreenWidget && <YourAppsItemBadge widgetId={fullscreenWidget.id} />}
    >
      {fullscreenWidget && (
        <SideNavigationItem
          label={<span className={nestedItemAnchor}>{YourAppsListSubItem.FullscreenApp}</span>}
          onClick={() => handleYourAppsListSubItemClick(fullscreenAppLink, YourAppsListSubItem.FullscreenApp)}
          isActive={listPathname === fullscreenAppLink}
          className={nestedItem}
          rightNode={<>{!isInternalProduct ? <YourAppsItemPin appId={appId} /> : null}</>}
        />
      )}
      <SideNavigationItem
        label={<span className={nestedItemAnchor}>{YourAppsListSubItem.Settings}</span>}
        onClick={() => handleYourAppsListSubItemClick(settingsLink, YourAppsListSubItem.Settings)}
        isActive={listPathname === settingsLink}
      />
    </SideNavigationList>
  );
};
