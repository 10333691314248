// @ts-strict-ignore
import { isAfter } from 'date-fns';
import { createSelector } from 'reselect';

import { ChatEventSubType, ChatEventType } from 'constants/chat-event-type';
import { ChatThreadStatus, ChatThreadVisualStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { DEFAULT_AGENT_NAME, DEFAULT_VISITOR_NAME } from 'constants/localization';
import { RatingScore } from 'constants/rating';
import { UserType } from 'constants/user-type';
import { toKeyMap } from 'helpers/array';
import { ChatChannel, getChatChannel, getChatChannelByClientId } from 'helpers/chat-channels';
import { type GroupedEventsIds } from 'helpers/chat-events-groups';
import { getConfig } from 'helpers/config';
import { getEarlyAccessConfigForField } from 'helpers/early-access/helpers';
import { type EarlyAccessConfig } from 'helpers/early-access/interfaces';
import { getStatusFromFeatureFlag } from 'helpers/get-status-from-feature-flag';
import { type KeyMap } from 'helpers/interface';
import type { Properties } from 'interfaces/property';
import { ThreadCustomPropertyName } from 'services/socket-lc3/chat/event-handling/constants';
import { type IAgentsState } from 'store/entities/agents/interfaces';
import { type WithAgentsState } from 'store/entities/agents/selectors';
import type { IWithBotsState } from 'store/entities/bots/selectors';
import type { ICustomersState } from 'store/entities/customers/interfaces';
import type { IIntegrationLicensePropertiesState } from 'store/entities/integration-license-properties/interfaces';
import { getTextEnhancementsStatus } from 'store/entities/integration-license-properties/selectors';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import {
  getAgentCustomProperty,
  type IWithAgentCustomPropertiesState,
} from 'store/features/agent-custom-properties/selectors';
import { isAtLeastBusinessPlan, isAtLeastTeamPlan, type IWithSessionState } from 'store/features/session/selectors';
import {
  type IWithRequestsState,
  createRequestErrorSelector,
  createRequestFetchingSelector,
} from 'store/requests/selectors';
import { getThreadStoppingEvent } from 'store/views/archives/helpers/get-thread-stopping-event';
import { type IWithArchivesViewState } from 'store/views/archives/interfaces';
import { getArchive } from 'store/views/archives/selectors';
import { getAgentOrBot } from 'store/views/team/computed';

import { ChatsEntitiesRequestFetchingSelectorsNames } from './actions';
import {
  getThreadVisualStatus,
  isActiveThread,
  isActiveUnassignedChat,
  isAgentOrSupervisor,
  isClosedThread,
  isCustomer,
  isEventFromAgent,
  isEventNotFromSupervisor,
  isEventWithAuthor,
  isEventWithStatus,
  isMessage,
  isMyChat,
  isOtherChat,
  isPrivateMessage,
  isQueuedChat,
  isSupervisedChat,
  isUnassignedChat,
} from './helpers/common';
import type {
  ChatEventEntity,
  ChatThreadEntity,
  IAttachmentMessage,
  IChatHistorySummary,
  IChatHistoryThreadEntity,
  IChatHistoryTimeline,
  IChatUser,
  IChatsState,
  IMessage,
  IQueuedChat,
  ISystemMessage,
} from './interfaces';

const EMPTY_ARRAY: string[] = [];
const EMPTY_ARRAY_OF_ARRAYS: string[][] = [];
const EMPTY_OBJECT = {};

const CHAT_SENTIMENT_APP_NAMESPACE = getConfig().chatSentimentNamespace;
const CLIENT_ID = getConfig().accountsClientId;
const SENTIMENT_CLIENT_ID = getConfig().chatSentimentNamespace;

export interface IWithChatsEntityState {
  entities: {
    agents: IAgentsState;
    chats: IChatsState;
    customers: ICustomersState;
  };
}

export function getAllChats(state: IWithChatsEntityState): KeyMap<ChatThreadEntity> {
  return state.entities.chats.threads;
}

export const getChatUsersByThreadIdCached = createSelector(
  (state: IWithChatsEntityState) => state,
  getChatIdByThreadId,
  getChatUsers,
);

export const getMyChats = createSelector(getAllChats, (allChats): KeyMap<ChatThreadEntity> => {
  const myChatsThreads = Object.values(allChats).filter((thread) => thread.type === ChatType.My);

  return myChatsThreads.length ? toKeyMap(myChatsThreads, 'threadId') : EMPTY_OBJECT;
});

export const getAllMyChatCustomerIds = createSelector(
  [getMyChats, (state: IWithChatsEntityState) => state.entities.chats.chatsUsers],
  (chats, chatsUsers) => {
    const customerIds = new Set<string>();
    Object.values(chats).forEach((chat) => {
      const users = chatsUsers[chat.chatId];
      if (users) {
        users.forEach((user) => {
          if (user.type === UserType.Customer) {
            customerIds.add(user.id);
          }
        });
      }
    });

    return customerIds;
  },
);

export const getSupervisedChats = createSelector(getAllChats, (allChats): KeyMap<ChatThreadEntity> => {
  const mySupervisedThreads = Object.values(allChats).filter((thread) => thread.type === ChatType.Supervised);

  return mySupervisedThreads.length ? toKeyMap(mySupervisedThreads, 'threadId') : EMPTY_OBJECT;
});

export const getOngoingChatsCount = createSelector(getAllChats, (allChats): number => {
  const ongoingThreads = Object.values(allChats).filter(
    (thread) =>
      !isClosedThread(thread) &&
      (thread.type === ChatType.My || thread.type === ChatType.Supervised || thread.type === ChatType.Other),
  );

  return ongoingThreads.length;
});

export function getUnassignedIds(state: IWithChatsEntityState): string[] {
  return state.entities.chats.unassignedIds;
}

export function getMyChatsIds(state: IWithChatsEntityState): string[] {
  return state.entities.chats.myChatsIds;
}

export function getQueuedIds(state: IWithChatsEntityState): string[] {
  return state.entities.chats.queuedIds;
}

export function getSupervisedIds(state: IWithChatsEntityState): string[] {
  return state.entities.chats.supervisedIds;
}

export function getOtherAgentsChatsIds(state: IWithChatsEntityState): string[] {
  return state.entities.chats.otherAgentsChatIds;
}

export function hasAnyChats(state: IWithChatsEntityState): boolean {
  return (
    getMyChatsIds(state).length > 0 ||
    getUnassignedIds(state).length > 0 ||
    getQueuedIds(state).length > 0 ||
    getSupervisedIds(state).length > 0
  );
}

export function hasAnyActiveChat(state: IWithChatsEntityState): boolean {
  const myChats = getMyChats(state);

  return Object.values(myChats).some((chat) => isMyChat(chat) && chat.status === ChatThreadStatus.Active);
}

export function getIsChatSupervised(state: IWithChatsEntityState, threadId: string): boolean {
  const supervisedIds = getSupervisedIds(state);

  return supervisedIds.includes(threadId);
}

export function getThread(state: IWithChatsEntityState, threadId: string): ChatThreadEntity {
  return state.entities.chats.threads[threadId];
}

export function getStartedTimestampThread(state: IWithChatsEntityState, threadId: string): number {
  return getThread(state, threadId)?.startedTimestamp;
}

export function getHistoryThread(
  state: IWithChatsEntityState,
  threadId: string,
  historyThreadId: string,
): IChatHistoryThreadEntity {
  const historyThreads = state.entities.chats.chatsHistory.threads[threadId];

  return historyThreads && historyThreads[historyThreadId];
}

export function getIsQueueThread(state: IWithChatsEntityState, threadId: string): boolean {
  const chat = getThread(state, threadId);

  return chat && chat.type === ChatType.Queued;
}

export function getIsOtherAgentThread(state: IWithChatsEntityState, threadId: string): boolean {
  const chat = getThread(state, threadId);

  return chat && chat.type === ChatType.Other;
}

export function getIsUnassignedThread(state: IWithChatsEntityState, threadId: string): boolean {
  const thread = getThread(state, threadId);

  return !!thread && isUnassignedChat(thread);
}

export function getChatType(state: IWithChatsEntityState, threadId: string): ChatType {
  const chat = getThread(state, threadId);

  return chat ? chat.type : null;
}

export function getThreadBy(
  state: IWithChatsEntityState,
  predicate: (thread: ChatThreadEntity) => boolean,
): ChatThreadEntity {
  return Object.values(state.entities.chats.threads).find(predicate);
}

export function getChatGroupIds(state: IWithChatsEntityState, threadId: string): string[] {
  const chat = getThread(state, threadId);

  return chat ? chat.groupIds : EMPTY_ARRAY;
}

export function getThreadByChatId(state: IWithChatsEntityState, chatId: string): ChatThreadEntity {
  return getThreadBy(state, (thread: ChatThreadEntity) => thread.chatId === chatId);
}

export function getThreadByCustomerId(state: IWithChatsEntityState, customerId: string): ChatThreadEntity {
  return getThreadBy(state, (thread: ChatThreadEntity) => thread.customerId === customerId);
}

export function getThreadIdByCustomerId(state: IWithChatsEntityState, customerId: string): string {
  const chat = getThreadByCustomerId(state, customerId);

  return chat ? chat.threadId : null;
}

export function getChatIdByCustomerId(state: IWithChatsEntityState, customerId: string): string {
  const chat = getThreadByCustomerId(state, customerId);

  return chat?.chatId ?? null;
}

export function getChatIdByThreadId(state: IWithChatsEntityState, threadId: string): string | null {
  const chat = getThread(state, threadId);

  return chat ? chat.chatId : null;
}

export function getThreadGroupedEventsIds(state: IWithChatsEntityState, threadId: string): GroupedEventsIds {
  return state.entities.chats.events.groupedIds[threadId] || EMPTY_ARRAY_OF_ARRAYS;
}

export function getAllHistoryThreadsOrders(state: IWithChatsEntityState): KeyMap<string[]> {
  return state.entities.chats.chatsHistory.threadsOrder;
}

export const getParentThreadIdByHistoryThreadId = (state: IWithChatsEntityState, historyThreadId: string): string =>
  createSelector(getAllHistoryThreadsOrders, (threadsOrder) => {
    return Object.keys(threadsOrder).find((threadId) => threadsOrder[threadId].includes(historyThreadId)) || null;
  })(state);

export function getHistoryThreadExists(state: IWithChatsEntityState, historyThreadId: string): boolean {
  const parentThreadId = getParentThreadIdByHistoryThreadId(state, historyThreadId);

  return !!getHistoryThread(state, parentThreadId, historyThreadId);
}

export function getHistoryThreadsOrder(state: IWithChatsEntityState, threadId: string): string[] {
  return state.entities.chats.chatsHistory.threadsOrder[threadId] || EMPTY_ARRAY;
}

export function getHistoryThreadGroupedEventsIds(
  state: IWithChatsEntityState,
  threadId: string,
  historyThreadId: string,
): GroupedEventsIds {
  const threadsOrder = getHistoryThreadsOrder(state, threadId);

  if (!threadsOrder.length) {
    return null;
  }

  return state.entities.chats.chatsHistory.events.groupedIds[threadId][historyThreadId] || EMPTY_ARRAY_OF_ARRAYS;
}

export function getEvents(state: IWithChatsEntityState): KeyMap<KeyMap<ChatEventEntity>> {
  return state.entities.chats.events.byIds || EMPTY_OBJECT;
}

export function getThreadEvents(state: IWithChatsEntityState, threadId: string): KeyMap<ChatEventEntity> {
  return state.entities.chats.events.byIds[threadId] || EMPTY_OBJECT;
}

export function getThreadEvent(state: IWithChatsEntityState, threadId: string, eventId: string): ChatEventEntity {
  return state.entities.chats.events.byIds[threadId]?.[eventId];
}

export function getThreadMessageEvent(
  state: IWithChatsEntityState,
  threadId: string,
  messageId: string,
): ChatEventEntity {
  const threadEvents = getThreadEvents(state, threadId);
  const messageEvent = Object.values(threadEvents).find((message: IMessage) => message.messageId === messageId);

  return messageEvent || null;
}

export function getHistoryThreadEvent(
  state: IWithChatsEntityState,
  threadId: string,
  historyThreadId: string,
  eventId: string,
): ChatEventEntity {
  return state.entities.chats.chatsHistory.events.byIds[threadId][historyThreadId]?.[eventId];
}

export function getSneakPeek(state: IWithChatsEntityState, threadId: string): string {
  return state.entities.chats.sneakPeeks[threadId] || '';
}

export function getAgentSneakPeek(state: IWithChatsEntityState, threadId: string): string {
  return state.entities.chats.agentsSneakPeeks[threadId] || '';
}

export function getThreadExists(state: IWithChatsEntityState, threadId: string): boolean {
  return !!getThread(state, threadId);
}

export function getThreadCustomerId(state: IWithChatsEntityState, threadId: string): string {
  const chat = getThread(state, threadId);

  return chat ? chat.customerId : null;
}

export function getThreadCustomerEmail(state: IWithChatsEntityState, threadId: string): string {
  const chat = getThread(state, threadId);

  return chat ? chat.customerEmail : null;
}

export function getThreadAgentId(state: IWithChatsEntityState, threadId: string): string | null {
  const chat = getThread(state, threadId);

  return chat ? chat.currentAgentId : null;
}

export function getThreadCustomerName(state: IWithChatsEntityState, threadId: string): string {
  const chat = getThread(state, threadId);

  return chat ? chat.customerName : null;
}

export function getThreadStatus(state: IWithChatsEntityState, threadId: string): ChatThreadStatus {
  const thread = getThread(state, threadId);

  if (thread && (isMyChat(thread) || isSupervisedChat(thread) || isUnassignedChat(thread))) {
    return thread.status;
  }

  return null;
}

export function getThreadType(state: IWithChatsEntityState, threadId: string): ChatType {
  const chat = getThread(state, threadId);

  return chat ? chat.type : null;
}

export function getThreadQueuedAtInMs(state: IWithChatsEntityState, threadId: string): number {
  const chat = getThread(state, threadId);

  return isQueuedChat(chat) ? chat.queuedAtInMs : null;
}

export function getActiveThreadByCustomerId(state: IWithChatsEntityState, customerId: string): ChatThreadEntity {
  return getThreadBy(state, (thread: ChatThreadEntity) => thread.customerId === customerId && !isClosedThread(thread));
}

export function getQueueStartTime(state: IWithChatsEntityState, customerId: string): number | null {
  const thread = getActiveThreadByCustomerId(state, customerId);

  return isQueuedChat(thread) ? thread.queuedAtInMs : null;
}

export function getChatClientId(state: IWithChatsEntityState, threadId: string): string {
  const chat = getThread(state, threadId);

  return chat ? chat.chatClientId : null;
}

export function messageExists(state: IWithChatsEntityState, threadId: string, eventId: string): boolean {
  const event = getThreadEvent(state, threadId, eventId);

  return (
    !!event &&
    (event.type === ChatEventType.Message ||
      event.type === ChatEventType.HiddenMessage ||
      event.type === ChatEventType.RichMessage)
  );
}

export function getFirstThreadEvent(state: IWithChatsEntityState, threadId: string): ChatEventEntity {
  const threadGroupedEventsIds = getThreadGroupedEventsIds(state, threadId);

  const firstGroupElement = threadGroupedEventsIds[0];

  if (!firstGroupElement || !firstGroupElement[0]) {
    return null;
  }

  return getThreadEvent(state, threadId, firstGroupElement[0]);
}

export function getThreadLastEventsIdsGroup(state: IWithChatsEntityState, threadId: string): string[] | null {
  const threadGroupedEventsIds = getThreadGroupedEventsIds(state, threadId);
  const lastGroupOfEventsIds = threadGroupedEventsIds[threadGroupedEventsIds.length - 1];

  if (!lastGroupOfEventsIds) {
    return null;
  }

  return lastGroupOfEventsIds;
}

export function isLastGroupOfThreadEvents(
  state: IWithChatsEntityState,
  threadId: string,
  eventsIds: string[],
): boolean {
  const lastGroupOfEventsIds = getThreadLastEventsIdsGroup(state, threadId);

  if (lastGroupOfEventsIds === eventsIds) {
    return true;
  }

  return false;
}

export function getThreadTagSuggestions(state: IWithChatsEntityState, threadId: string): string[] {
  const tagSuggestions = state.entities.chats.tags.tagSuggestions.tags[threadId]?.data;

  if (!tagSuggestions) {
    return EMPTY_ARRAY;
  }

  return tagSuggestions;
}

export function hasFetchedTagSuggestions(state: IWithChatsEntityState, threadId: string): boolean {
  return !!state.entities.chats.tags.tagSuggestions[threadId]?.data;
}

export function getCurrentThreadCustomerId(state: IWithChatsEntityState, threadId: string): string {
  const chat = getThread(state, threadId);

  return chat ? chat.customerId : null;
}

function getImportantChatIds(state: IWithChatsEntityState): string[] {
  return state.entities.chats.importantIds;
}

export function getIsChatImportant(state: IWithChatsEntityState, threadId: string): boolean {
  const importantIds = getImportantChatIds(state);

  return importantIds.includes(threadId);
}

export function getUnassingedChatsNextPageId(state: IWithChatsEntityState): string {
  const unassingedChatsNextPageId = state.entities.chats.unassignedChatsNextPageId;

  return unassingedChatsNextPageId;
}

export function getIsActiveUnassignedThreadByChatId(state: IWithChatsEntityState, chatId: string): boolean {
  const chat = getThreadBy(state, (thread: ChatThreadEntity) => thread.chatId === chatId);

  return isActiveUnassignedChat(chat);
}

export function getThreadRate(state: IWithChatsEntityState, threadId: string): RatingScore {
  const events = Object.values(getThreadEvents(state, threadId));

  const rates = events
    .filter(
      (event: ISystemMessage) =>
        event.subType === ChatEventSubType.RatedOnly || event.subType === ChatEventSubType.RateCanceled,
    )
    .map(
      (event: ISystemMessage) =>
        (event && event.textVariables && (event.textVariables.score as RatingScore)) || RatingScore.NotRated,
    );

  return rates.length ? rates.pop() : RatingScore.NotRated;
}

function getChatTimeline(state: IWithChatsEntityState, threadId: string): IChatHistoryTimeline {
  return state.entities.chats.chatsHistory.timeline[threadId] || null;
}

export function getChatHistorySummary(state: IWithChatsEntityState, threadId: string): IChatHistorySummary {
  return state.entities.chats.chatsHistory.summary[threadId] || null;
}

export function getHistoryThreadStartTimestamp(
  state: IWithChatsEntityState,
  threadId: string,
  historyThreadId: string,
): number {
  const historyThreads = state.entities.chats.chatsHistory.threads[threadId];

  if (!historyThreads) {
    return null;
  }

  const historyThread = historyThreads[historyThreadId];

  return historyThread ? historyThread.startedTimestamp : null;
}

export function getChatHistoryOrder(state: IWithChatsEntityState, threadId: string): string[] {
  return state.entities.chats.chatsHistory.threadsOrder[threadId] || EMPTY_ARRAY;
}

export function getHasHistory(state: IWithChatsEntityState, threadId: string): boolean {
  const order = getChatHistoryOrder(state, threadId);

  return order ? order.length > 0 : false;
}

export function getHasMoreHistory(state: IWithChatsEntityState, threadId: string): boolean {
  const timeline = getChatTimeline(state, threadId);
  const summary = getChatHistorySummary(state, threadId);

  if (timeline) {
    return timeline.hasMoreHistory;
  }

  if (summary) {
    return summary.hasMoreHistory;
  }

  return true;
}

export function getEventAuthorName(
  state: IWithChatsEntityState & WithAgentsState & IWithBotsState,
  threadId: string,
  eventId: string,
  historicalThreadId: string = null,
): string {
  const thread = getThread(state, threadId);
  const event = historicalThreadId
    ? getHistoryThreadEvent(state, threadId, historicalThreadId, eventId)
    : getThreadEvent(state, threadId, eventId);

  if (!thread || !event || !isEventWithAuthor(event)) {
    return null;
  }

  const { authorType, authorId } = event;

  if (authorType === UserType.Customer) {
    return thread.customerName || DEFAULT_VISITOR_NAME;
  }

  const agent = getAgentOrBot(state, authorId);

  return (agent && agent.name) || DEFAULT_AGENT_NAME;
}

export function getWasThreadSupervised(state: IWithChatsEntityState, threadId: string): boolean {
  const thread = getThread(state, threadId) as IQueuedChat;

  return Boolean(thread?.supervisorsIds?.length > 0);
}

export function getOngoingActiveThreadInChat(state: IWithChatsEntityState, threadId: string): ChatThreadEntity {
  const thread = getThread(state, threadId);

  if (!thread) {
    return null;
  }

  const ongoingActiveThread = getThreadBy(
    state,
    (t: ChatThreadEntity): boolean => thread.chatId === t.chatId && t.threadId !== threadId && !isClosedThread(t),
  );

  return ongoingActiveThread;
}

/**
 * For the provided threadId it returns another thread related to the same customer (same chat id)
 * which is ongoing with another agent
 */
export function getAnotherActiveThreadWithOtherAgent(state: IWithChatsEntityState, threadId: string): ChatThreadEntity {
  const thread = getThread(state, threadId);

  if (!thread) {
    return null;
  }

  const ongoingOtherThread = getThreadBy(
    state,
    (t: ChatThreadEntity): boolean =>
      thread.chatId === t.chatId && t.threadId !== threadId && isOtherChat(t) && !isClosedThread(t),
  );

  return ongoingOtherThread;
}

function getChatChannelCustomerUpdate(state: IWithChatsEntityState, userId: string): ChatChannel {
  const threadId = getThreadIdByCustomerId(state, userId);

  // if it's empty we know it's from chat widget
  return threadId ? getChatChannelByClientId(getChatClientId(state, threadId)) : ChatChannel.ChatWidget;
}

function getChatChannelCustomerUpdateArchive(state: IWithArchivesViewState, threadId: string): ChatChannel {
  const archivedThread = getArchive(state, threadId);

  return archivedThread ? getChatChannel(archivedThread) : null;
}

export function isChannelAvailableForCustomerUpdateOnChats(state: IWithChatsEntityState, userId: string): boolean {
  return [ChatChannel.AgentApp, ChatChannel.ChatWidget].includes(getChatChannelCustomerUpdate(state, userId));
}

export function isChannelAvailableForCustomerUpdateOnArchives(
  state: IWithArchivesViewState,
  threadId: string,
): boolean {
  return [ChatChannel.AgentApp, ChatChannel.ChatWidget].includes(getChatChannelCustomerUpdateArchive(state, threadId));
}

export function getHistoryLimitReached(state: IWithChatsEntityState, threadId: string): boolean {
  return state.entities.chats.chatsHistory.historyLimitReached[threadId] || false;
}

export function getChatUsers(state: IWithChatsEntityState, chatId: string): IChatUser[] {
  return state.entities.chats.chatsUsers[chatId];
}

export const getAssigningChatError = (state: IWithRequestsState): boolean => {
  return !!createRequestErrorSelector([ChatsEntitiesRequestFetchingSelectorsNames.ASSIGN_CHAT])(state);
};

export function getIsThreadCompleted(state: IWithChatsEntityState, threadId: string): boolean {
  return state.entities.chats.threads[threadId]?.incomplete !== false;
}

export function getThreadSupervisorsIds(state: IWithChatsEntityState, threadId: string): string[] {
  return state.entities.chats.threads[threadId]?.supervisorsIds || EMPTY_ARRAY;
}

function getIsTextEnhancementsEnabledForLicense(
  state: IWithSessionState & IIntegrationLicensePropertiesState & IWithAgentCustomPropertiesState,
): boolean {
  const isEnabledInPlan = isAtLeastBusinessPlan(state);
  const textEnhancementsStatus = getTextEnhancementsStatus(state);

  return getStatusFromFeatureFlag(textEnhancementsStatus, isEnabledInPlan);
}

export function getCanUseTextEnhancementsFeatures(
  state: IWithSessionState & IIntegrationLicensePropertiesState & IWithAgentCustomPropertiesState,
): boolean {
  const earlyAccessConfig = getAgentCustomProperty(
    state,
    AgentCustomPropertyName.EarlyAccessConfig,
  ) as Partial<EarlyAccessConfig>;
  const isEarlyAccessEnabled = getEarlyAccessConfigForField(earlyAccessConfig, 'text-enhancement');

  return isEarlyAccessEnabled || getIsTextEnhancementsEnabledForLicense(state);
}

function getIsTagSuggestionsEnabledForLicense(state: IWithSessionState): boolean {
  return isAtLeastTeamPlan(state);
}

export function getCanUseTagsSuggestionFeature(
  state: IWithSessionState & IIntegrationLicensePropertiesState & IWithAgentCustomPropertiesState,
): boolean {
  const earlyAccessConfig = getAgentCustomProperty(
    state,
    AgentCustomPropertyName.EarlyAccessConfig,
  ) as Partial<EarlyAccessConfig>;
  const isEarlyAccessEnabled = getEarlyAccessConfigForField(earlyAccessConfig, 'tag-suggestions');

  return isEarlyAccessEnabled || getIsTagSuggestionsEnabledForLicense(state);
}

export function getThreadIntegrationProperties(
  state: IWithChatsEntityState,
  threadId: string,
  clientId: string,
): Properties | null {
  const thread = getThread(state, threadId);

  if (!thread?.customProperties?.[clientId]) return null;

  return thread.customProperties[clientId] as Properties;
}

export function getThreadCustomProperties(state: IWithChatsEntityState, threadId: string): KeyMap<Properties> | null {
  const thread = getThread(state, threadId);

  return thread?.customProperties;
}

export function getThreadIntegrationProperty(
  state: IWithChatsEntityState,
  threadId: string,
  propertyName: string,
): string | null {
  const propertiesAgentApp = getThreadIntegrationProperties(state, threadId, CLIENT_ID);
  const propertiesSentiment = getThreadIntegrationProperties(state, threadId, SENTIMENT_CLIENT_ID);
  const properties = { ...propertiesAgentApp, ...propertiesSentiment };

  if (!properties) return null;

  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  return properties?.[propertyName]?.toString() || null;
}

export function getNumberOfMessagesFromLastChatSummary(
  state: IWithChatsEntityState,
  threadId: string,
  chatSummaryDate: Date,
): number {
  return Object.values(getThreadEvents(state, threadId)).reduce((count, event) => {
    if (isMessage(event) && isAfter(event.timestampInMs, new Date(chatSummaryDate))) {
      return count + 1;
    }

    return count;
  }, 0);
}

export function getIsQueuedChatVisuallyClosed(state: IWithChatsEntityState, threadId: string): boolean {
  const thread = getThread(state, threadId);

  return getThreadVisualStatus(thread) === ChatThreadVisualStatus.QueuedChatVisuallyClosed;
}

export function getIsMyChatVisuallyClosed(state: IWithChatsEntityState, threadId: string): boolean {
  const thread = getThread(state, threadId);

  return getThreadVisualStatus(thread) === ChatThreadVisualStatus.MyChatVisuallyClosed;
}

const getCustomerLastMessage = (state: IWithChatsEntityState, threadId: string): ChatEventEntity => {
  const threads = getThreadEvents(state, threadId);
  const threadsWithMessage = Object.values(threads).filter(
    (thread: ChatEventEntity) => isEventWithStatus(thread) && thread.authorType === UserType.Customer,
  );
  const lastThread = threadsWithMessage[threadsWithMessage.length - 1];

  return lastThread;
};

export function getSentiment(
  state: IWithChatsEntityState,
  threadId: string,
): { sentimentScore: number; sentimentMagnitude: number; isSentimentUnknown: boolean; isSentimentError: boolean } {
  const thread = getCustomerLastMessage(state, threadId) as IMessage;

  return {
    sentimentScore: +thread?.properties?.[CHAT_SENTIMENT_APP_NAMESPACE]?.sentimentScore,
    sentimentMagnitude: +thread?.properties?.[CHAT_SENTIMENT_APP_NAMESPACE]?.sentimentMagnitude,
    isSentimentUnknown: thread?.properties?.[CHAT_SENTIMENT_APP_NAMESPACE]?.sentimentUnknown as boolean,
    isSentimentError: thread?.properties?.[CHAT_SENTIMENT_APP_NAMESPACE]?.sentimentError as boolean,
  };
}

export const getCustomerMessageLastSentiment = (state: IWithChatsEntityState, threadId: string): number | null => {
  const threads = getThreadEvents(state, threadId);

  const customerMessagesWithSentiment = Object.values(threads).filter((thread) => {
    const { authorType, properties } = thread as IMessage;
    const sentimentScore = properties?.[CHAT_SENTIMENT_APP_NAMESPACE]?.sentimentScore;

    return isMessage(thread) && authorType === UserType.Customer && sentimentScore;
  });

  if (!customerMessagesWithSentiment.length) return null;

  const lastMessage = customerMessagesWithSentiment[customerMessagesWithSentiment.length - 1] as IMessage;

  const lastSentimentScore = Number(lastMessage?.properties?.[CHAT_SENTIMENT_APP_NAMESPACE]?.sentimentScore);

  return lastSentimentScore;
};

export function getHasAgentReplied(state: IWithChatsEntityState, threadId: string): boolean {
  const isSupervised = getIsChatSupervised(state, threadId);
  const thread = getThread(state, threadId);

  if (!isActiveThread(thread)) {
    return false;
  }

  const agentId = getThreadAgentId(state, threadId);
  const events = getThreadEvents(state, threadId);

  const eventsWithAuthor = Object.values(events).filter(isEventWithAuthor);

  if (!eventsWithAuthor.length) {
    return false;
  }

  const lastEvent = eventsWithAuthor[eventsWithAuthor.length - 1];
  const isAgentThreadOwner = lastEvent.authorId === agentId;

  if (isSupervised) {
    return isAgentOrSupervisor(lastEvent) && isAgentThreadOwner;
  }

  const isAgentOwnMessage = !isPrivateMessage(lastEvent, agentId) && isAgentThreadOwner;

  return isAgentOwnMessage;
}

export function getLatestCustomerOrAgentEventTimestamp(state: IWithChatsEntityState, threadId: string): number | null {
  const chattingAgentLogin = getThreadAgentId(state, threadId);
  const events = getThreadEvents(state, threadId);

  const eventsWithAuthor = Object.values(events).filter(
    (event) => isEventWithAuthor(event) && isEventNotFromSupervisor(event, chattingAgentLogin),
  );

  if (!eventsWithAuthor.length) {
    return null;
  }

  const latestEvent = eventsWithAuthor[eventsWithAuthor.length - 1];

  return latestEvent.timestampInMs;
}

export function getLatestAgentEventTimestamp(state: IWithChatsEntityState, threadId: string): number | null {
  const chattingAgentLogin = getThreadAgentId(state, threadId);
  const events = getThreadEvents(state, threadId);

  const agentsEvents = Object.values(events).filter(
    (event) => isEventWithAuthor(event) && isEventFromAgent(event, chattingAgentLogin),
  );

  if (!agentsEvents.length) {
    return null;
  }

  const latestEvent = agentsEvents[agentsEvents.length - 1];

  return latestEvent.timestampInMs;
}

export function getLatestCustomerEventTimestamp(state: IWithChatsEntityState, threadId: string): number | null {
  const events = getThreadEvents(state, threadId);

  const customerEvents = Object.values(events).filter((event) => isEventWithAuthor(event) && isCustomer(event));

  if (!customerEvents.length) {
    return null;
  }

  const latestEvent = customerEvents[customerEvents.length - 1];

  return latestEvent.timestampInMs;
}

export const getWasChatDeactivated = (state: IWithChatsEntityState, threadId: string): boolean => {
  const thread = getThread(state, threadId);
  const wasChatDeactivated = thread?.customProperties?.wasChatDeactivated;

  return Boolean(wasChatDeactivated);
};

export const getIsCustomerLastMessage = (state: IWithChatsEntityState, threadId: string, eventId: string): boolean => {
  const threads = getThreadEvents(state, threadId);
  const threadsWithMessage = Object.values(threads).filter(
    (thread: ChatEventEntity) => isEventWithStatus(thread) && thread.authorType === UserType.Customer,
  );
  const lastThread = threadsWithMessage[threadsWithMessage.length - 1];

  return lastThread?.id === eventId;
};

export const getIsAgentLastMessage = (state: IWithChatsEntityState, threadId: string, eventId: string): boolean => {
  const threads = getThreadEvents(state, threadId);
  const threadsWithMessage = Object.values(threads).filter(
    (thread: ChatEventEntity) => isEventWithStatus(thread) && thread.authorType === UserType.Agent,
  );
  const lastThread = threadsWithMessage[threadsWithMessage.length - 1];

  return lastThread?.id === eventId;
};

export const getMessageReactionAuthor = (state: IWithChatsEntityState, threadId: string, eventId: string): string => {
  const thread = getThreadEvent(state, threadId, eventId) as IMessage | IAttachmentMessage;

  return thread?.properties?.[CLIENT_ID]?.messageReactionAuthor as string;
};

export const getIsReactionAllowedThread = (state: IWithChatsEntityState, threadId: string, eventId: string): boolean =>
  Boolean(getThreadEvent(state, threadId, eventId)) &&
  !getIsUnassignedThread(state, threadId) &&
  !getWasChatDeactivated(state, threadId) &&
  !getIsQueueThread(state, threadId) &&
  !getIsChatSupervised(state, threadId);

export const getIsSettingReaction = (state: IWithRequestsState): boolean =>
  createRequestFetchingSelector(['ENTITIES/CHATS/ADD_REACTION'])(state);

export const getThreadEventCustomProperties = (
  state: IWithChatsEntityState,
  threadId: string,
  eventId: string,
): Record<string, unknown> => {
  const thread = getThreadEvent(state, threadId, eventId) as IMessage | IAttachmentMessage;

  return thread?.properties?.[CLIENT_ID];
};

export function getTimestampThreadEnded(state: IWithChatsEntityState, threadId: string): number | null {
  const thread = getThread(state, threadId);

  if (isActiveThread(thread)) {
    return null;
  }

  const events = getThreadEvents(state, threadId);
  const lastEvent = getThreadStoppingEvent(events);

  return lastEvent?.timestampInMs || null;
}

export const getTagSuggestionsRequestId = (state: IWithChatsEntityState, threadId: string): string =>
  state.entities.chats.tags.tagSuggestions.tags[threadId]?.requestId || null;

export function getTagSuggestionModelVersion(state: IWithChatsEntityState): string {
  return state.entities.chats.tags.tagSuggestions.modelVersion;
}

function isValidEvent(eventId: string): boolean {
  const regex = /^[A-Z0-9]+_[A-Z0-9]+$/;

  return regex.test(eventId);
}

export function getIsMessageFormatted(state: IWithChatsEntityState, threadId: string, eventId: string): boolean {
  const historyThreadId = eventId?.split('_')[0];

  if (!isValidEvent(eventId)) {
    return true;
  }

  const isCurrentThread = threadId === historyThreadId;
  let threadEvent: IMessage | null | undefined;

  if (isCurrentThread) {
    threadEvent = getThreadEvent(state, threadId, eventId) as IMessage;
  } else {
    threadEvent = getHistoryThreadEvent(state, threadId, historyThreadId, eventId) as IMessage;
  }

  return Boolean(threadEvent?.properties?.chats?.formatting);
}

export const getEventProperties = (state: IWithChatsEntityState, threadId: string, eventId: string): Properties => {
  const event = getThreadEvent(state, threadId, eventId);

  return (event as IMessage)?.properties || {};
};

export const getIsChatThreadPrioritized = (state: IWithChatsEntityState, threadId?: string): boolean => {
  if (!threadId) {
    return false;
  }
  const thread = getThread(state, threadId);
  const isChatThreadPrioritized =
    thread?.customProperties?.[getConfig().accountsClientId]?.[ThreadCustomPropertyName.PriorityChat];

  return Boolean(isChatThreadPrioritized);
};

export const getChatThreadPriorityAuthorId = (state: IWithChatsEntityState, threadId?: string): string | null => {
  if (!threadId) {
    return null;
  }
  const thread = getThread(state, threadId);

  return thread?.customProperties?.[getConfig().accountsClientId]?.[ThreadCustomPropertyName.PriorityChatAuthorId] as
    | string
    | null;
};

export const getSelectedAICanneds = (state: IWithChatsEntityState, threadId: string): string[] | undefined => {
  const thread = getThread(state, threadId);
  const selectedAICanneds = thread?.selectedAICanneds;

  return selectedAICanneds;
};
