// @ts-strict-ignore
import { useMemo, type FC } from 'react';

import { Tickets } from '@livechat/design-system-icons';
import { Icon, Link, Text, NavigationTopBarAlert } from '@livechat/design-system-react-components';
import { addDays, endOfToday, format, isAfter } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { DateFormat } from 'constants/date';
import { TopBarNotificationType } from 'constants/notifications';
import { Permission } from 'constants/permission';
import { PlanType } from 'constants/plan-type';
import { EventPlace } from 'helpers/analytics';
import { getHelpDeskAppId } from 'helpers/integration';
import { openInNewCard } from 'helpers/routing';
import { useProductData } from 'hooks/api/use-product-data';
import { useAgentCustomProperty } from 'hooks/use-agent-custom-property';
import { useInstallTrustedApplication } from 'hooks/use-install-trusted-application';
import { openChatWidget } from 'services/chat-widget';
import { trackEvent } from 'services/event-tracking';
import * as selectors from 'store/entities/agents/selectors';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';
import { NATIVE_TICKETS_SUNSET_DATE } from 'store/features/notifications-bar/sagas';

import * as styles from './styles';

const PHASE_TWO_DATE = '2024-11-01';
const PHASE_THREE_DATE = '2024-12-01';
const SUNSET_TICKETS_ARTICLE =
  'https://www.livechat.com/helpdesk-integration/?utm_source=my.livechatinc.com&utm_medium=referral&utm_campaign=tickets-sunset-topbar';

type Props = {
  isVisible: boolean;
  onSkip(name: TopBarNotificationType): void;
};

export const NativeTicketsSunsetBar: FC<Props> = ({ onSkip, isVisible }) => {
  const dispatch = useDispatch();
  const { subscriptions } = useProductData();
  const { install, isProcessing } = useInstallTrustedApplication();
  const [, setNewVisibilityTime] = useAgentCustomProperty(
    AgentCustomPropertyName.NativeTicketsSubsetBannerNextVisibilityDate,
  );
  const { permission } = useSelector(selectors.getLoggedInAgent);

  const today = new Date();
  const bannerNextVisibilityDate = addDays(today, 21);
  const { plan, quantity: seats } = subscriptions.livechat ?? {};

  const getTrialDays = (): number => {
    const today = endOfToday();

    if (isAfter(today, PHASE_THREE_DATE)) {
      return 30;
    }

    if (isAfter(today, PHASE_TWO_DATE)) {
      return 60;
    }

    return 90;
  };

  const handleActionClick = (eventName: string): void => {
    setNewVisibilityTime(format(bannerNextVisibilityDate, DateFormat.ShortDate));
    trackEvent(eventName, EventPlace.TopBar, { permission, plan, seats });
    dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.NativeTicketsSunset));
    onSkip(TopBarNotificationType.NativeTicketsSunset);
  };

  const handleLearnMoreButtonClick = (): void => {
    handleActionClick('Sunset Banner learn more clicked');
    openInNewCard(SUNSET_TICKETS_ARTICLE);
  };

  const handleSkip = (): void => {
    handleActionClick('Sunset Banner skip clicked');
  };

  const handleChatWithUsClick = (): void => {
    handleActionClick('Sunset Banner chat with us clicked');
    openChatWidget();
  };

  const handleStartTrialClick = (trialDays: number): void => {
    handleActionClick('Sunset Banner start trial clicked');
    install({ appId: getHelpDeskAppId(), couponCode: `hdtrial${trialDays}` });
  };

  const bannerDetails = useMemo(() => {
    if (isAfter(today, NATIVE_TICKETS_SUNSET_DATE)) {
      return;
    }

    if ([Permission.Owner, Permission.ViceOwner].includes(permission)) {
      if (
        plan === PlanType.EnterprisePlus ||
        (plan === PlanType.Business && seats >= 30) ||
        (plan === PlanType.Team && seats >= 50)
      ) {
        return {
          content: (
            <Text as="span">
              Legacy tickets retire on January 6, 2025. Contact your Account Manager for a special HelpDesk offer.
            </Text>
          ),
          buttonText: 'Chat with us',
          onClick: handleChatWithUsClick,
        };
      }

      if (plan === PlanType.Business || plan === PlanType.Team) {
        const trialDays = getTrialDays();

        return {
          content: (
            <Text as="span">
              Legacy tickets retire on January 6, 2025. Switch to HelpDesk!{' '}
              <Link
                className={styles.ticketsSunsetLink}
                href={SUNSET_TICKETS_ARTICLE}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>{' '}
              and get your {trialDays}-day trial.
            </Text>
          ),
          buttonText: 'Start free trial',
          onClick: () => handleStartTrialClick(trialDays),
        };
      }

      if (plan === PlanType.Starter) {
        const trialDays = 30;

        return {
          content: (
            <Text as="span">
              Legacy tickets retire on January 6, 2025. Switch to HelpDesk!{' '}
              <Link
                className={styles.ticketsSunsetLink}
                href={SUNSET_TICKETS_ARTICLE}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>{' '}
              and get your {trialDays}-day trial.
            </Text>
          ),
          buttonText: 'Start free trial',
          onClick: () => handleStartTrialClick(trialDays),
        };
      }
    }

    return {
      content: (
        <Text as="span">
          Legacy tickets retire on January 6, 2025. Move to HelpDesk! Contact your license owner to switch.
        </Text>
      ),
      buttonText: 'Learn more',
      onClick: handleLearnMoreButtonClick,
    };
  }, [permission, plan, seats, today]);

  return (
    <NavigationTopBarAlert
      kind="info"
      isVisible={isVisible}
      primaryCta={{
        label: bannerDetails.buttonText,
        onClick: bannerDetails.onClick,
        size: 'compact',
        icon: <Icon source={Tickets} />,
        loading: isProcessing,
      }}
      closeButton={{
        onClick: handleSkip,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'aria-label': 'Close the prompt about native tickets sunset',
      }}
    >
      {bannerDetails.content}
    </NavigationTopBarAlert>
  );
};
