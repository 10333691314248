import { type ApiClientResponse, type ApiClientError } from '../http/types';

export enum ConfigurationApiErrorMessage {
  AccountSuspended = 'Your account is suspended',
  AwaitingApproval = 'Your account is awaiting approval',
  UserNotActive = 'User not active',
}

/**
 * Represents an error type.
 *
 * @see {@link https://platform.text.com/docs/management/configuration-api#possible-errors}
 */
export type ConfigurationApiErrorType =
  | 'authentication'
  | 'authorization'
  | 'already_exists'
  | 'group_not_found'
  | 'internal'
  | 'license_expired'
  | 'limit_reached'
  | 'misdirected_request'
  | 'not_found'
  | 'too_many_requests'
  | 'transfer_ownership'
  | 'unsupported_version'
  | 'validation';

/**
 * Represents an error object in the API response.
 *
 * @property {ErrorType} type - The type of the error.
 * @property {string} message - A human-readable message describing the error.
 * @property {Record<string, unknown>} data - Additional data about the error, if available.
 */
interface ConfigurationApiError {
  type: ConfigurationApiErrorType;
  message: string;
  data?: Record<string, unknown>;
}

/**
 * Represents an error response from the API.
 *
 * @property {ApiError} error - The error object.
 *
 * @see {@link https://platform.text.com/docs/management/configuration-api#errors}
 */
export interface ConfigurationApiErrorResponse {
  error: ConfigurationApiError;
}

/**
 * Type representing the response from the Configuration API.
 *
 * @template T The type of the data returned in the response.
 */
export type ConfigurationApiResponse<T> = ApiClientResponse<T, ConfigurationApiErrorResponse>;

/**
 * Represents an error from the Configuration API client.
 */
export type ConfigurationApiClientError = ApiClientError<ConfigurationApiErrorResponse>;

/**
 * Represents a normalized error object.
 */
export type ConfigurationApiNormalizedError = ConfigurationApiErrorResponse['error'] & {
  status: ConfigurationApiClientError['status'];
};

/**
 * Represents an item in a batch response.
 */
export type ApiClientBatchResponseItem<T> = T | ConfigurationApiErrorResponse;

/**
 * Represents a batch response from the Configuration API client.
 */
export type ApiClientBatchResponse<T> = { responses: ApiClientBatchResponseItem<T>[] };
