import { useQuery } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';
import { HALF_MINUTE_IN_MS } from 'constants/reply-suggestions';
import {
  type KnowledgeSourceUrlItem,
  type KnowledgeSourceItem,
  KnowledgeSourceItemStatus,
  KnowledgeSourceType,
  type KnowledgeSourcePdfItem,
  type KnowledgeSourceFileItemType,
  type KnowledgeSourceFileItem,
} from 'interfaces/knowledge-sources';

import { aiEngineClient } from 'services/connectivity/ml-gateway-api/ai-engine/client';
import {
  KnowledgeSourceFileItemResponse,
  KnowledgeSourceUrlItemResponse,
  ListSkillsResponse,
} from 'services/connectivity/ml-gateway-api/ai-engine/types';

const supportedStatuses = Object.values(KnowledgeSourceItemStatus);

function deserializeReplySuggestionsSourcesList(data: ListSkillsResponse): KnowledgeSourceItem[] {
  const list: KnowledgeSourceItem[] = [];

  for (const knowledgeSourceItem of data) {
    const commonFields = {
      id: knowledgeSourceItem.id,
      createdAt: knowledgeSourceItem.created_at,
      customDescription: knowledgeSourceItem.custom_description,
      name: knowledgeSourceItem.name,
      sourceType: knowledgeSourceItem.source_type,
      status: knowledgeSourceItem.status,
      updatedAt: knowledgeSourceItem.updated_at,
      learnedAt: knowledgeSourceItem.learned_at,
      authorId: knowledgeSourceItem.created_by || null,
      nextSyncAt: knowledgeSourceItem.next_sync_at,
      syncInterval: knowledgeSourceItem.sync_interval_days,
      vectorDatabase: knowledgeSourceItem.vector_database,
      aiAgents: knowledgeSourceItem.agent_skills?.map((skill) => ({ agentId: skill.agent_id })),
    };

    switch (knowledgeSourceItem.source_type) {
      case KnowledgeSourceType.URL: {
        const knowledgeSourceUrlItem = knowledgeSourceItem as unknown as KnowledgeSourceUrlItemResponse;
        const urlItem: KnowledgeSourceUrlItem = {
          ...commonFields,
          depth: knowledgeSourceUrlItem.depth,
          pageCount: knowledgeSourceUrlItem.page_count,
          url: knowledgeSourceUrlItem.url,
        };

        list.push(urlItem);
        break;
      }

      case KnowledgeSourceType.File: {
        const knowledgeSourcePdfItem = knowledgeSourceItem as unknown as KnowledgeSourceFileItemResponse;

        if (knowledgeSourcePdfItem.file_type === 'PDF') {
          const pdfItem: KnowledgeSourcePdfItem = {
            ...commonFields,
            fileName: knowledgeSourcePdfItem.file_name,
            fileSize: knowledgeSourcePdfItem.file_size,
            fileType: knowledgeSourcePdfItem.file_type,
          };
          list.push(pdfItem);
        }
        break;
      }

      default: {
        continue;
      }
    }
  }

  return list
    .filter(({ status }) => supportedStatuses.includes(status))
    .sort((a, b) => new Date(b?.learnedAt ?? b.updatedAt).getTime() - new Date(a?.learnedAt ?? a.updatedAt).getTime());
}

const EMPTY_ARRAY = [];

function filterOutSources<T extends KnowledgeSourceItem>(
  sourceType: KnowledgeSourceType,
  data?: T[],
  subType?: KnowledgeSourceFileItemType,
): T[] {
  if (!data) {
    return EMPTY_ARRAY as T[];
  }

  switch (sourceType) {
    case KnowledgeSourceType.URL: {
      return data.filter((item) => item.sourceType === sourceType);
    }

    case KnowledgeSourceType.File: {
      if (subType === 'PDF') {
        return data
          .filter((item) => item.sourceType === sourceType)
          .filter((item) => (item as KnowledgeSourceFileItem).fileType === subType);
      }

      return data.filter((item) => item.sourceType === sourceType);
    }

    default: {
      return data;
    }
  }
}

export async function listSkills(): Promise<KnowledgeSourceItem[]> {
  const { result, error } = await aiEngineClient.listSkills();

  if (error) {
    throw error;
  }

  return deserializeReplySuggestionsSourcesList(result);
}

type Props = {
  withInterval?: boolean;
  refetchOnMount?: boolean;
  sourceType?: KnowledgeSourceType;
  subType?: KnowledgeSourceFileItemType;
};

type UseReplySuggestionsSourcesList<T extends KnowledgeSourceItem> = {
  data?: T[];
  isLoading: boolean;
  isError: boolean;
};

export const useReplySuggestionsSourcesList = <T extends KnowledgeSourceItem>({
  withInterval = false,
  refetchOnMount = false,
  sourceType,
  subType,
}: Props = {}): UseReplySuggestionsSourcesList<T> => {
  const { data, isLoading, isError } = useQuery([QueryKey.KnowledgeSourcesList], listSkills, {
    refetchOnMount,
    refetchOnWindowFocus: false,
    refetchInterval: (data) => {
      if (!withInterval) {
        return false;
      }

      const isProcessingAnyItemOnList = data?.some((item) =>
        [KnowledgeSourceItemStatus.IN_PROGRESS, KnowledgeSourceItemStatus.PENDING].includes(item.status),
      );

      if (isProcessingAnyItemOnList) {
        return HALF_MINUTE_IN_MS;
      }

      return false;
    },
  });

  const dataFromApi = data as T[];

  return {
    data: !sourceType ? dataFromApi : filterOutSources(sourceType, dataFromApi, subType),
    isLoading,
    isError,
  };
};
