import { type FC, useMemo, useRef } from 'react';

import { cx } from '@emotion/css';
import { useSelector } from 'react-redux';

import { useParamSelector } from 'hooks/use-param-selector';
import { getIsAIAgentAvailableByName } from 'store/entities/ai-agents/selectors';
import { getIsOnlyWelcomeMessage, getCopilotGroupedEvents } from 'store/entities/copilot/computed';
import { getIsCopilotError } from 'store/entities/copilot/selectors';
import {
  getIsCommandSuggestionsOpened,
  getIsCopilotModalExpanded,
  getCopilotIsLoading,
} from 'store/views/copilot/selectors';

import { expandChatContentAnimation } from './animations';
import { COPILOT_CHAT_FEED_ID } from './constants';
import { CopilotChatFeedSkeleton } from './CopilotChatFeedSkeleton';
import { CopilotFullscreenEmptyState } from './CopilotFullscreenEmptyState';
import { CopilotFullscreenNoAIAgentsScreen } from './CopilotFullScreenNoAIAgentsScreen';
import { CopilotEventsGroup } from './events/CopilotEventsGroup';
import { createGroupedEvents } from './helpers';
import { useScroll } from './hooks/use-legacy-scroll';
import { UnseenNotificationBadge } from './UnseenNotificationBadge';

import * as styles from './styles';

export const LegacyCopilotChatFeedContent: FC = () => {
  const copilotChatFeedRef = useRef<HTMLDivElement>(null);
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const groupedEvents = useSelector(getCopilotGroupedEvents);
  const isCopilotLoading = useSelector(getCopilotIsLoading);
  const isCopilotError = useSelector(getIsCopilotError);
  const isOnlyWelcomeMessage = useSelector(getIsOnlyWelcomeMessage);
  const isCommandSuggestionsOpened = useSelector(getIsCommandSuggestionsOpened);
  const hasCopilotAIAgent = useParamSelector(getIsAIAgentAvailableByName, 'one');

  const eventGroups = useMemo(
    () =>
      createGroupedEvents({
        groupedEvents,
        isCopilotLoading,
        isCopilotError,
      }),
    [groupedEvents, isCopilotError, isCopilotLoading],
  );

  const hasAdditionalEvents = eventGroups.length !== groupedEvents.length;

  // eslint-disable-next-line react-compiler/react-compiler
  const { handleScroll, handleScrollToBottom } = useScroll({
    copilotChatFeedRef,
    groupedEvents,
    additionalEvents: hasAdditionalEvents,
  });

  if (groupedEvents.flat().length === 0) {
    return <CopilotChatFeedSkeleton />;
  }

  if (isOnlyWelcomeMessage && isExpanded) {
    return <CopilotFullscreenEmptyState />;
  }

  if (!hasCopilotAIAgent && isExpanded) {
    return <CopilotFullscreenNoAIAgentsScreen />;
  }

  return (
    <div
      id={COPILOT_CHAT_FEED_ID}
      onScroll={handleScroll}
      ref={copilotChatFeedRef}
      className={cx(styles.scroller(isExpanded, isCommandSuggestionsOpened), {
        [expandChatContentAnimation]: isExpanded,
      })}
    >
      <div className={styles.chatMessagesWrapper(isExpanded)}>
        {eventGroups.map((eventGroup, index) => (
          <CopilotEventsGroup key={index} eventGroup={eventGroup} />
        ))}
        <UnseenNotificationBadge onScrollToBottom={handleScrollToBottom} />
      </div>
    </div>
  );
};
