import { useEffect, useRef, useState, type FC } from 'react';

import { cx } from '@emotion/css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SPOTLIGHT_MEDIA_QUERY } from 'constants/spotlight';
import { getIsCopilotStreamingEnabled } from 'helpers/copilot-streaming';
import { getVisibleNotifications } from 'helpers/notification-bar';
import { usePrevious } from 'hooks/use-previous';
import { useScreenSize } from 'hooks/use-screen-size';
import { isDarkModeEnabled } from 'store/features/agent-custom-properties/selectors';
import { getNotifications } from 'store/features/notifications-bar/selectors';
import { getCanManageSubscription } from 'store/features/session/selectors';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getIsCopilotModalExpanded, getIsCopilotModalOpened } from 'store/views/copilot/selectors';

import { collapseAnimation, expandAnimation, expandContentAnimation } from './animations';
import { COPILOT_MODAL_TEST_ID } from './constants';
import { CopilotChatFeedContent } from './CopilotChatFeedContent';
import { CopilotFullscreenHeader } from './CopilotFullscreenHeader';
import { CopilotPopoverHeader } from './CopilotPopoverHeader';
import { CopilotDetails } from './details/CopilotDetails';
import { LegacyCopilotChatFeedContent } from './LegacyChatFeedContent';
import { CopilotMessageBox } from './message-box/CopilotMessageBox';

import * as styles from './styles';
import { SECTIONS_WITHOUT_NOTIFICATIONS_BAR } from 'constants/notification-bar';
import { Section } from 'constants/section';

const CopilotModal: FC = () => {
  const dispatch = useDispatch();
  const isCopilotModalOpened = useSelector(getIsCopilotModalOpened);
  const isExpanded = useSelector(getIsCopilotModalExpanded);
  const prevIsExpanded = usePrevious(isExpanded, false);
  const isDarkMode = useSelector(isDarkModeEnabled);
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const [locationChanged, setLocationChanged] = useState(false);
  const isCopilotStreamingEnabled = getIsCopilotStreamingEnabled();

  const notifications = useSelector(getNotifications);
  const canManageSubscription = useSelector(getCanManageSubscription);
  const hasVisibleNotifications = getVisibleNotifications(notifications, canManageSubscription).length > 0;
  const isSectionWithoutNotificationsBar = SECTIONS_WITHOUT_NOTIFICATIONS_BAR.includes(
    location?.pathname.split('/')[1] as Section,
  );

  const { isCustomScreen: isDesktop } = useScreenSize({ minWidth: SPOTLIGHT_MEDIA_QUERY });

  const currentHeight = containerRef.current?.clientHeight || 0;
  const shouldHideGlowAnimation = isExpanded || (prevIsExpanded && !isExpanded);
  const shouldApplyExpandAnimation = isExpanded && !prevIsExpanded && isDesktop;
  const shouldApplyCollapseAnimation = !isExpanded && shouldHideGlowAnimation;
  const shouldApplyGlowAnimation = !shouldHideGlowAnimation && !locationChanged;

  useEffect(() => {
    if (!prevLocation || !isCopilotModalOpened) {
      return;
    }

    const hasLocationChanged = location.key !== prevLocation?.key;

    if (hasLocationChanged && isExpanded) {
      dispatch(CopilotViewActions.toggleExpand());
    }

    setLocationChanged(hasLocationChanged);
  }, [location]);

  useEffect(() => {
    if (isCopilotModalOpened) {
      setLocationChanged(false);
      dispatch(CopilotViewActions.resetCopilotUnreadMessagesCount());
    }
  }, [dispatch, isCopilotModalOpened]);

  useEffect(() => {
    if (isDesktop || !isCopilotModalOpened) {
      return;
    }

    if (isExpanded) {
      dispatch(CopilotViewActions.closeDetails());

      return;
    }

    if (locationChanged) {
      dispatch(CopilotViewActions.hideModal());
      setLocationChanged(false);

      return;
    }

    dispatch(CopilotViewActions.toggleExpand());
  }, [dispatch, isExpanded, isCopilotModalOpened, isDesktop]);

  if (!isCopilotModalOpened) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={cx(
        'lc-dark-theme',
        styles.container(isDarkMode, hasVisibleNotifications && !isSectionWithoutNotificationsBar),
        {
          [styles.containerExpanded(hasVisibleNotifications && !isSectionWithoutNotificationsBar)]: isExpanded,
          [expandAnimation(currentHeight)]: shouldApplyExpandAnimation,
          [collapseAnimation]: shouldApplyCollapseAnimation,
          [styles.copilotAnimation(isDarkMode)]: shouldApplyGlowAnimation,
        },
      )}
      data-testid={COPILOT_MODAL_TEST_ID}
    >
      {!isExpanded && <CopilotPopoverHeader />}
      {isExpanded && <CopilotFullscreenHeader />}

      <div className={cx(styles.chatContainer(isExpanded))}>
        <div className={cx(styles.chatBody, { [expandContentAnimation]: shouldApplyExpandAnimation })}>
          {isCopilotStreamingEnabled ? <CopilotChatFeedContent /> : <LegacyCopilotChatFeedContent />}
          <CopilotMessageBox />
        </div>
        {isExpanded && <CopilotDetails />}
      </div>
    </div>
  );
};

export default CopilotModal;
