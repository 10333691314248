import { type ReactElement, type FC } from 'react';

import { AppFrame, NavigationTopBar, NavigationTopBarTitle } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ApplicationsDataSuspender } from 'components/ApplicationsDataSuspender';
import { MobileNavigation } from 'components/mobile-navigation/MobileNavigation';
import { Navigation } from 'components/navigation/navigation/Navigation';
import { NotificationsBar } from 'components/notifications-bar/NotificationsBar';
import { TopBarWrapper } from 'components/notifications-bar/TopBarWrapper';
import { SuspenseWrapper } from 'components/suspense-wrapper/SuspenseWrapper';
import { Section } from 'constants/section';
import { anyToBoolean, type BooleanNumericFlag } from 'helpers/boolean';
import { useLicenseProperty } from 'hooks/connectivity/configuration-api/properties/use-license-property';
import { usePartnerType } from 'hooks/use-partner-type';
import { AppsContextProvider } from 'routes/apps/AppsContext';
import { AppsSideNavigation } from 'routes/apps/components/apps-side-navigation/AppsSideNavigation';
import { AutomateSideNavigation } from 'routes/automate/components/side-navigation/AutomateSideNavigation';
import EngageSideNavigation from 'routes/engage/side-navigation/EngageSideNavigation';
import { ReportsSideNavigation } from 'routes/reports/components/side-navigation/ReportsSideNavigation';
import { SettingsSideNavigation } from 'routes/settings/components/side-navigation/SettingsSideNavigation';
import { SubscriptionSideNavigation } from 'routes/subscription/components/side-navigation/SideNavigation';
import { CoreProperty, PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { lazyWithRetry } from 'services/lazy-load-components';
import { getCurrentSection } from 'store/features/routing/selectors';
import { getManageDetails } from 'store/features/session/selectors';

import * as styles from './styles';

const FullscreenWidgetsRouter = lazyWithRetry(() => import('routes/fullscreen-widgets/FullscreenWidgetsRouter'));
const MarketplaceRouter = lazyWithRetry(() => import('routes/apps/marketplace/MarketplaceRouter'));

const getSideNavigation = (
  section: Section | undefined,
  pathname: string,
  canManageSubscription: boolean,
  isReseller: boolean,
  isNonProfit: boolean,
  canManageGreetings: boolean,
): ReactElement | null => {
  if (section?.startsWith(Section.Engage) && canManageGreetings) {
    return <EngageSideNavigation />;
  }
  if (section?.startsWith(Section.Automate)) {
    return <AutomateSideNavigation />;
  }
  if (section?.startsWith(Section.Reports)) {
    return <ReportsSideNavigation />;
  }
  if (section?.startsWith(Section.Apps)) {
    return (
      <AppsContextProvider>
        <AppsSideNavigation />
      </AppsContextProvider>
    );
  }

  if (
    section?.startsWith(Section.Subscription) &&
    ![
      '/subscription/upgrade',
      '/subscription/processing',
      '/subscription/thank-you',
      '/subscription/pick-livechat-plan',
      '/subscription/install-code',
    ].includes(pathname) &&
    canManageSubscription &&
    !isReseller &&
    !isNonProfit
  ) {
    return <SubscriptionSideNavigation />;
  }

  if (section?.startsWith(Section.Settings)) {
    return <SettingsSideNavigation />;
  }

  return null;
};

export const MainLayoutLegacy: FC = ({ children }) => {
  const sectionName = useSelector(getCurrentSection);
  const { pathname } = useLocation();
  const { canManageSubscription, canManageGreetings } = useSelector(getManageDetails);
  const { isReseller } = usePartnerType();
  const { data: isNonProfitLicense } = useLicenseProperty<BooleanNumericFlag>(
    PlatformNamespace.CORE,
    CoreProperty.NonProfit,
  );
  const isNonProfit = anyToBoolean(isNonProfitLicense);

  return (
    <AppFrame
      navigation={<Navigation />}
      sideNavigation={getSideNavigation(
        sectionName,
        pathname,
        canManageSubscription,
        isReseller,
        isNonProfit,
        canManageGreetings,
      )}
      topBar={
        <NavigationTopBar
          className={styles.topBar}
          additionalNodes={
            <NavigationTopBarTitle>
              <TopBarWrapper />
            </NavigationTopBarTitle>
          }
        >
          <NotificationsBar />
        </NavigationTopBar>
      }
      mobileNavigation={<MobileNavigation />}
    >
      <SuspenseWrapper>
        {children}
        <ApplicationsDataSuspender />
        <FullscreenWidgetsRouter />
        <MarketplaceRouter />
      </SuspenseWrapper>
    </AppFrame>
  );
};
