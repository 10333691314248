import { Close } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { getConfig } from 'helpers/config';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { NavigationViewActions } from 'store/views/navigation/actions';

import * as styles from './styles';

export const HelpDeskPromoTooltip: React.FC = () => {
  const dispatch = useDispatch();

  const onCancel = (): void => {
    dispatch(NavigationViewActions.hideNavigationItemTooltip({ itemId: getConfig().helpDeskAppWidgetId }));
    dispatch(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.HelpDeskNavPromoSeen]: '1',
      })
    );
  };

  return (
    <div className={styles.wrapper}>
      <div onClick={onCancel} className={styles.icon}>
        <Icon source={Close} size="xsmall" />
      </div>
      <p className={styles.header}>Use the new ticketing system</p>
      <p className={styles.text}>Smoothly create your first ticket from chat and then manage it in HelpDesk.</p>
    </div>
  );
};
