import jstz from 'jstz';

import { WebSocketAction } from 'constants/web-socket-action';
import { wasPageRefreshed } from 'helpers/browser';
import { isDesktopAppDetected } from 'helpers/desktop-app/is-detected';
import { logCodeUsage } from 'helpers/log-code-usage';
import { cyrb53 } from 'helpers/string';
import { type IServer } from 'interfaces/server';
import { CustomerMonitoringLevel, type LoginEvent } from 'interfaces/web-socket-events';
import { getDesktopApp } from 'services/desktop-application/desktop-app';
import { type ILoginResponsePushEvent } from 'services/web-socket/interfaces';

import { RTM_EVENTS_V35 } from './constants';
import { sendEvent } from './send-event';

function getApplicationInfo(accessToken: string): {
  name: string;
  version: string;
} {
  const version = cyrb53(accessToken);
  const desktopApp = getDesktopApp();
  const name = isDesktopAppDetected() ? desktopApp.toString() || '' : 'Web';

  void logCodeUsage('getApplicationInfo', { name }, 0.001);

  return { name, version };
}

/**
 * @see https://platform.text.com/docs/messaging/agent-chat-api/rtm-reference#login
 */
export async function login(
  server: IServer,
  accessToken: string,
  hasLoggedInBefore: boolean,
  isAway: boolean,
): Promise<ILoginResponsePushEvent> {
  const event: LoginEvent = {
    action: WebSocketAction.Login,
    payload: {
      application: getApplicationInfo(accessToken),
      reconnect: hasLoggedInBefore || wasPageRefreshed(),
      away: isAway,
      token: `Bearer ${accessToken}`,
      timezone: jstz.determine().name(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      customer_monitoring_level: CustomerMonitoringLevel.HighestAvailable,
      pushes: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '3.5': RTM_EVENTS_V35,
      },
    },
    version: '3.5',
  };

  const response = await sendEvent<ILoginResponsePushEvent>(server, event);

  if (response.success === false) {
    throw response.payload;
  }

  return response.payload;
}
