import { isSameDay } from 'date-fns';
import isEmpty from 'lodash.isempty';

import type { KeyMap } from 'helpers/interface';
import { isSystemMessage, isSurvey, isEventWithAuthor } from 'store/entities/chats/helpers/common';
import type { ChatEventEntity } from 'store/entities/chats/interfaces';

const THRESHOLD_FOR_NEW_GROUP_IN_MS = 5 * 60 * 1000;
const EMPTY_ARRAY: GroupedEventsIds = [];

export type GroupedEventsIds = string[][];

interface IEventGroupingDecision {
  shouldAdd?: boolean;
  shouldCreateNew?: boolean;
  position?: number;
}

export interface IGetGroupedEventsIdsOptions {
  getSortedEventsIdsFn?: typeof getSortedEventsIds;
  getGroupedEventsIdsForNewEventFn?: typeof getGroupedEventsIdsForNewEvent;
}

export interface IFindEventPositionInGroupOptions {
  events: KeyMap<ChatEventEntity>;
  eventIds: string[];
  index: number;
  shouldCreateNewGroupFn?: typeof shouldCreateNewGroup;
  shouldAddToGroupFn?: typeof shouldAddToGroup;
}

export interface IGetGroupedEventsIdsForNewEventOptions {
  events: KeyMap<ChatEventEntity>;
  groupedEventsIds: GroupedEventsIds;
  currentIndex: number;
  recursion?: boolean;
  findEventPositionInGroupFn?: typeof findEventPositionInGroup;
}

/**
 * Determines whether a new group should be created based on the properties of the last and next chat events.
 *
 * @param {ChatEventEntity} lastEvent - The last chat event in the current group. This event's properties are compared with the next event to decide whether a new group should be created.
 * @param {ChatEventEntity} nextEvent - The next chat event to be processed. If this event's properties meet certain conditions compared to the last event, a new group will be created.
 *
 * @returns {boolean} - Returns `true` if a new group should be created based on the properties of the last and next events, `false` otherwise.
 *
 * @example
 * ```
 * const lastEvent = { id: '1', timestampInMs: Date.now(), authorId: '1', authorType: UserType.Agent };
 * const nextEvent = { id: '2', timestampInMs: Date.now() + 600000, authorId: '2', authorType: UserType.Customer };
 * const result = shouldCreateNewGroup(lastEvent, nextEvent);
 * console.log(result); // Outputs: true
 * ```
 *
 * @remarks
 * This function checks several conditions to decide whether a new group should be created, including whether either event is a system message or a survey, whether the authors are different, whether the events are on different days, and whether the time difference is more than 5 minutes.
 *
 * @see `isEventWithAuthor` - This function is used to check whether an event has an author.
 * @see `isSystemMessage` - This function is used to check whether an event is a system message.
 * @see `isSurvey` - This function is used to check whether an event is a survey.
 */
export function shouldCreateNewGroup(lastEvent: ChatEventEntity, nextEvent: ChatEventEntity): boolean {
  const bothEventsHaveAuthors = isEventWithAuthor(lastEvent) && isEventWithAuthor(nextEvent);
  const isDifferentAuthor =
    bothEventsHaveAuthors &&
    (lastEvent.authorId !== nextEvent.authorId || lastEvent.authorType !== nextEvent.authorType);
  const isDifferentDay = !isSameDay(lastEvent.timestampInMs, nextEvent.timestampInMs);
  const isTimeDifferenceExceedingThreshold =
    Math.abs(nextEvent.timestampInMs - lastEvent.timestampInMs) >= THRESHOLD_FOR_NEW_GROUP_IN_MS;

  return (
    isSystemMessage(lastEvent) ||
    isSystemMessage(nextEvent) ||
    isSurvey(lastEvent) ||
    isSurvey(nextEvent) ||
    isDifferentAuthor ||
    isDifferentDay ||
    isTimeDifferenceExceedingThreshold
  );
}

/**
 * Determines whether a new chat event should be added to the existing group based on the properties of the last event in the group and the new event.
 *
 * @param {ChatEventEntity} lastEvent - The last chat event in the current group. This event's properties are compared with the new event to decide whether the new event should be added to the group.
 * @param {ChatEventEntity} newEvent - The new chat event to be processed. If this event's properties meet certain conditions compared to the last event, it will be added to the current group.
 *
 * @returns {boolean} - Returns `true` if the new event should be added to the current group based on the properties of the last and new events, `false` otherwise.
 *
 * @example
 * ```
 * const lastEvent = { id: '1', timestampInMs: Date.now(), authorId: '1', authorType: UserType.Agent };
 * const newEvent = { id: '2', timestampInMs: Date.now() + 1000, authorId: '1', authorType: UserType.Agent };
 * const result = shouldAddToGroup(lastEvent, newEvent);
 * console.log(result); // Outputs: true
 * ```
 *
 * @remarks
 * This function checks several conditions to decide whether a new event should be added to the current group, including whether both events have authors, whether the authors are the same, and whether the new event is later than the last event.
 *
 * @see `isEventWithAuthor` - This function is used to check whether an event has an author.
 */
export function shouldAddToGroup(lastEvent: ChatEventEntity, newEvent: ChatEventEntity): boolean {
  const bothEventsHaveAuthors = isEventWithAuthor(lastEvent) && isEventWithAuthor(newEvent);
  const isSameAuthor = bothEventsHaveAuthors && lastEvent.authorId === newEvent.authorId;
  const isNewEventLater = newEvent.timestampInMs >= lastEvent.timestampInMs;

  return isSameAuthor && isNewEventLater;
}

/**
 * A utility function that takes a map of chat events and returns an array of event IDs sorted by timestamp.
 * @param {KeyMap<ChatEventEntity>} events - A map of chat events, where the keys are event IDs and the values are `ChatEventEntity` objects.
 * @returns {string[]} - An array of event IDs sorted by their corresponding event's timestamp.
 * @example
 * ```
 * const events = {
 *   '1': { id: '1', timestampInMs: 1000, ... },
 *   '2': { id: '2', timestampInMs: 2000, ... },
 *   '3': { id: '3', timestampInMs: 1500, ... },
 * };
 * const sortedIds = getSortedEventsIds(events);
 * console.log(sortedIds);  // ['1', '3', '2']
 * ```
 * @remarks
 * This function is useful when you want to process or display chat events in chronological order. It doesn't mutate the original `events` object. In the context of react-redux, this function can be used to sort the IDs of chat events stored in the redux state. In the context of @tanstack/query, it can be used to sort the results of a query for chat events.
 * @see `ChatEventEntity` - The type of the chat events in the `events` parameter.
 */
export function getSortedEventsIds(events: KeyMap<ChatEventEntity>): string[] {
  if (!events) {
    return [];
  }

  return Object.values(events)
    .sort((a, b) => a.timestampInMs - b.timestampInMs)
    .map((event) => event.id);
}

/**
 * Groups event IDs from a key map of chat events. This function is typically used when all thread events need to be parsed, such as for an incoming chat thread event. For single new incoming messages, `getGroupedEventsIdsForNewEvent` is used instead.
 * @param {KeyMap<ChatEventEntity>} events - A key map of chat events. Each key is an event ID and the value is the corresponding ChatEventEntity. This data may come from a redux state, a saga side effect, or a data query.
 * @param {IGetGroupedEventsIdsOptions} options - Options for the grouping operation. This includes the `getSortedEventsIdsFn` and `getGroupedEventsIdsForNewEventFn` functions.
 * @returns {GroupedEventsIds} - Returns an array of grouped event IDs. Each group represents a set of related events, such as messages in the same thread.
 * @example
 * ```
 * const events = {
 *   '1': { ...ChatEventEntity },
 *   '2': { ...ChatEventEntity },
 *   // ...
 * };
 * const options = {
 *   getSortedEventsIdsFn: getSortedEventsIds,
 *   getGroupedEventsIdsForNewEventFn: getGroupedEventsIdsForNewEvent,
 * };
 * const groupedEventsIds = getGroupedEventsIds(events, options);
 * ```
 * @remarks
 * This function first sorts the event IDs using the `getSortedEventsIdsFn` function, then groups them using the `getGroupedEventsIdsForNewEventFn` function. If the `events` parameter is null or empty, an empty array is returned. This function does not modify the original `events` object and is therefore safe to use in redux reducers.
 * @see `getGroupedEventsIdsForNewEvent` - This function is used to group event IDs for a new incoming message.
 * @see `getSortedEventsIds` - This function is used to sort the event IDs.
 */
export function getGroupedEventsIds(
  events: KeyMap<ChatEventEntity>,
  options?: IGetGroupedEventsIdsOptions
): GroupedEventsIds {
  const {
    getSortedEventsIdsFn = getSortedEventsIds,
    getGroupedEventsIdsForNewEventFn = getGroupedEventsIdsForNewEvent,
  } = options || {};
  if (isEmpty(events)) {
    return EMPTY_ARRAY;
  }

  const eventsIds = getSortedEventsIdsFn(events);

  return eventsIds.reduce((groupedEventsIds: GroupedEventsIds, eventId: string) => {
    const event = events[eventId];
    const currentIndex = groupedEventsIds.length - 1;

    return getGroupedEventsIdsForNewEventFn(event, {
      events,
      groupedEventsIds,
      currentIndex,
      recursion: false,
    });
  }, []);
}

/**
 * Determines the position of a new event in an existing group of events. This function is used when a new event arrives and needs to be inserted into the correct position in the chat thread. It uses a recursive approach to find the correct position, considering the timestamps of the events and the type of the new event.
 * @param {ChatEventEntity} newEvent - The new event that needs to be inserted into the group. The function considers the type and timestamp of this event to determine its position.
 * @param {IFindEventPositionInGroupOptions} options - An object containing the following properties:
 * - `events`: A key-value map where the key is the event ID and the value is the ChatEventEntity. This data structure is used for efficient access to events.
 * - `eventIds`: An array of event IDs representing the current group of events. The function iterates over this array to find the correct position for the new event.
 * - `index`: The current index in the `eventIds` array that the function is considering. This parameter is used in the recursive calls to move backwards through the array.
 * - `shouldCreateNewGroupFn`: An optional function to determine if a new group should be created for the new event. If not provided, the default `shouldCreateNewGroup` function is used.
 * - `shouldAddToGroupFn`: An optional function to determine if the new event should be added to the current group. If not provided, the default `shouldAddToGroup` function is used.
 * @returns {IEventGroupingDecision} - An object describing what should be done with the new event. It could indicate that the event should be added to the current group, that a new group should be created, or that the event does not match the current group.
 * @example
 * ```
 * const events = { '1': { id: '1', type: 'message', timestamp: 1000 }, '2': { id: '2', type: 'message', timestamp: 2000 } };
 * const eventIds = ['1', '2'];
 * const newEvent = { id: '3', type: 'message', timestamp: 1500 };
 * const decision = findEventPositionInGroup(newEvent, { events, eventIds, index: eventIds.length - 1 });
 * ```
 * @remarks
 * This function uses a recursive approach to find the correct position for the new event. It starts from the end of the `eventIds` array and moves backwards until it finds the correct position. The recursion stops when it reaches the start of the array or when it finds a position for the new event.
 * @see `shouldCreateNewGroup` - This function is used to determine if a new group should be created for the new event.
 * @see `shouldAddToGroup` - This function is used to determine if the new event should be added to the current group.
 */
export function findEventPositionInGroup(
  newEvent: ChatEventEntity,
  options: IFindEventPositionInGroupOptions
): IEventGroupingDecision {
  const {
    events,
    eventIds,
    index,
    shouldCreateNewGroupFn = shouldCreateNewGroup,
    shouldAddToGroupFn = shouldAddToGroup,
  } = options;

  const eventId = eventIds[index];

  if (!eventId) {
    return { shouldCreateNew: false, shouldAdd: false };
  }

  const lastEvent = events[eventId];

  if (shouldCreateNewGroupFn(lastEvent, newEvent)) {
    return { shouldCreateNew: true };
  }

  if (shouldAddToGroupFn(lastEvent, newEvent)) {
    return { shouldAdd: true, position: index + 1 };
  }

  return findEventPositionInGroup(newEvent, { ...options, index: index - 1 });
}

/**
 * Used to group chat events by their IDs. It's a crucial part of the chat functionality in a React application using react-redux for state management, redux-saga for side effects, and @tanstack/query for data fetching.
 * @param {ChatEventEntity} event - The chat event entity that needs to be grouped. This entity is typically fetched from the server using @tanstack/query.
 * @param {IGetGroupedEventsIdsForNewEventOptions} options - Options for the grouping operation. This includes the current list of grouped event IDs, the current index, and optional recursion and custom findEventPositionInGroup function.
 * @returns {GroupedEventsIds} - Returns an array of grouped event IDs. This can be used to update the state in the redux store.
 * @example
 * ```
 * const event = { id: '1', ... };
 * const options = { events: [...], groupedEventsIds: [...], currentIndex: 0 };
 * const newGroupedEventsIds = getGroupedEventsIdsForNewEvent(event, options);
 * dispatch(updateGroupedEventsIds(newGroupedEventsIds));
 * ```
 * @remarks
 * This function uses recursion to find the correct group for the new event. If the recursion option is set to false, it will always create a new group for the event.
 * @accessibility Not applicable for this function as it doesn't directly interact with the UI.
 * @see `findEventPositionInGroup` - Used to find the position of the event in the group.
 * @see `updateGroupedEventsIds` - This redux action is typically used to update the state with the new grouped event IDs.
 */
export function getGroupedEventsIdsForNewEvent(
  event: ChatEventEntity,
  options: IGetGroupedEventsIdsForNewEventOptions
): GroupedEventsIds {
  const {
    events,
    groupedEventsIds,
    currentIndex,
    recursion = true,
    findEventPositionInGroupFn = findEventPositionInGroup,
  } = options;

  if (!groupedEventsIds[currentIndex]) {
    return groupedEventsIds.concat([[event.id]]);
  }

  const result = findEventPositionInGroupFn(event, {
    events,
    eventIds: groupedEventsIds[currentIndex],
    index: groupedEventsIds[currentIndex].length - 1,
  });

  if (result.shouldCreateNew) {
    return groupedEventsIds.concat([[event.id]]);
  }

  if (result.shouldAdd) {
    const newGroup = groupedEventsIds[currentIndex].slice();
    if (result.position !== undefined) {
      newGroup.splice(result.position, 0, event.id);
    } else {
      newGroup.push(event.id);
    }

    return groupedEventsIds.slice(0, currentIndex).concat([newGroup], groupedEventsIds.slice(currentIndex + 1));
  }

  if (!recursion) {
    return groupedEventsIds.concat([[event.id]]);
  }

  return getGroupedEventsIdsForNewEvent(event, {
    events,
    groupedEventsIds,
    currentIndex: currentIndex - 1,
    recursion,
    findEventPositionInGroupFn,
  });
}
