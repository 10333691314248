import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { type IMessageErrorPayload } from 'interfaces/incoming-message';
import { type IServer } from 'interfaces/server';
import { AppStateProvider } from 'services/app-state-provider';
import { isError } from 'services/connectivity/http/type-guards';
import { login as loginToWebSocket } from 'services/web-socket/actions/login';
import { SessionActions } from 'store/features/session/actions';
import { hasLoggedInSuccessfully, isAutoAwaySet } from 'store/features/session/selectors';

import { handleLoginFailure } from './login-failure';
import { handleLoginSuccessful } from './login-successful';

const log = debug(DebugLogsNamespace.AppServerConnection);

export async function login(server: IServer, accessToken: string): Promise<void> {
  try {
    const hasLoggedInBefore = AppStateProvider.selectFromStore(hasLoggedInSuccessfully);
    const isAway = AppStateProvider.selectFromStore(isAutoAwaySet);

    log('Logging in to web socket');
    const response = await loginToWebSocket(server, accessToken, hasLoggedInBefore, isAway);
    log('Logged in to web socket');

    AppStateProvider.dispatch(SessionActions.saveHasLoggedIn(true));
    handleLoginSuccessful(response);
  } catch (e) {
    log('Failed to log in to web socket', e);
    const error = e as IMessageErrorPayload | Error;
    if (!isError(error) && error.error?.type === 'request_timeout') {
      log('Timeout, retrying...');
      await login(server, accessToken);
    } else {
      await handleLoginFailure(error);
    }
  }
}
