import { AgentRole } from 'constants/agent-role';
import type { Scope } from 'constants/scope';
import type { RequestResult } from 'interfaces/api/client';
import { Product } from 'interfaces/products';
import { type ListAccountsResponse } from 'services/connectivity/global-accounts-api/accounts/types';
import type { IAgentRole, RoleType, IAccount, InvitationMode } from 'store/entities/accounts/interfaces';
import type { IInvitationLink } from 'store/features/invitation-link/interfaces';

export interface IInvitationLinkResponse extends IAccountInfoPayload {}

export interface IRolesPayload {
  role_id: string;
  product: string;
  role: string;
  type: string;
  predefined: boolean;
}

export interface ISingleRolePayload extends IRolesPayload {
  scope: string;
}

interface IAccountInfoPayload {
  organization_id: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  expires_in: number;
  access_token: string;
  account_id: string;
}

export interface IUploadAvatarPayload {
  avatar_url: string;
}

interface IProductDTO {
  product: string;
  client_id: string;
  created_at: string;
}

export interface IOrganizationDTO {
  organization_id: string;
  name: string;
  company: { website: string; phone?: string };
  products: IProductDTO[];
  invitation_mode: InvitationMode;
  created_at: string;
  updated_at: string;
}

export function deserializeInvitationLinkPayload(payload: IInvitationLinkResponse): IInvitationLink {
  return {
    accessToken: payload.access_token,
    expiresIn: payload.expires_in,
  };
}

export function deserializeRolesPayload(payload: IRolesPayload[]): string[] {
  return payload.reduce((acc, role) => {
    if (role.product === Product.LiveChat || role.role === AgentRole.Owner) {
      acc.push(role.role_id);
    }

    return acc;
  }, [] as string[]);
}

export function deserializeSingleRolePayload(role: ISingleRolePayload): IAgentRole {
  return {
    roleId: role.role_id,
    predefined: role.predefined,
    roleType: role.type as RoleType,
    roleName: role.role,
    scopes: role.scope.split(',') as Scope[],
  };
}

export function deserializeSingleRolesPayload(payload: RequestResult<ISingleRolePayload>[]): IAgentRole[] {
  return payload.map(({ result }) => deserializeSingleRolePayload(result));
}

export function deserializeAccountsPayload(payload: ListAccountsResponse): IAccount[] {
  return payload.map((item) => ({
    accountId: item.account_id,
    email: item?.email?.toLowerCase(),
    name: item.name,
    roles: deserializeRolesPayload(item.roles || []),
    createdAt: item.created_at,
    mfaEnabled: item.properties?.mfa,
  }));
}
