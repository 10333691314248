// @ts-strict-ignore
import { type IconSource } from '@livechat/design-system-react-components';

import {
  ShortcutToTooltipText,
  suggestedResponsesConfig,
  type SuggestedResponsesConfigItem,
  SuggestedResponsesTriggerIcons,
} from 'constants/suggested-responses';
import { capitalizeFirstLetter } from 'helpers/string';
import type { CannedResponse } from 'interfaces/canned-responses'
import { type ISuggestedCannedResponse, SuggestedResponsesTrigger } from 'interfaces/suggested-responses';

const suggestedResponsesConfigItems = Object.entries(suggestedResponsesConfig) as [
  SuggestedResponsesTrigger,
  SuggestedResponsesConfigItem
][];

const ShortcutToTrigger = suggestedResponsesConfigItems.reduce((acc, [trigger, { shortcut }]) => {
  acc[shortcut] = trigger;

  return acc;
}, {} as Record<string, SuggestedResponsesTrigger>);

export const suggestedResponsesPickerOptions = suggestedResponsesConfigItems.map(
  ([, { shortcut: key, label: name }]) => ({ key, name: capitalizeFirstLetter(name) })
);

export const getTriggerByShortcut = (value: string): SuggestedResponsesTrigger | null =>
  ShortcutToTrigger[value] || null;

export const getShortcutByTrigger = (trigger: SuggestedResponsesTrigger): string | undefined =>
  suggestedResponsesConfig[trigger]?.shortcut;

export const getLabelByTrigger = (trigger: SuggestedResponsesTrigger): string | undefined =>
  suggestedResponsesConfig[trigger]?.label;

export const suggestedResponsesTriggerList = suggestedResponsesConfigItems.map(([, { shortcut }]) => shortcut);

export const getIcon = (trigger: SuggestedResponsesTrigger): IconSource | null => {
  return trigger ? SuggestedResponsesTriggerIcons[trigger] : null;
};

export const getTooltipTextByShortcut = (value: SuggestedResponsesTrigger): string => ShortcutToTooltipText[value];

export const withSuggestedResponses = (tag: string): boolean => suggestedResponsesTriggerList.includes(tag);

export const withoutSuggestedResponses = (tag: string): boolean => !suggestedResponsesTriggerList.includes(tag);

export const prepareSuggestedCannedResponses = (
  cannedResponses: CannedResponse[]
): { [key: string]: ISuggestedCannedResponse[] } => {
  const initialValues = {
    [SuggestedResponsesTrigger.OnStartChat]: [],
    [SuggestedResponsesTrigger.OnTransferChat]: [],
    [SuggestedResponsesTrigger.OnIdle]: [],
    [SuggestedResponsesTrigger.OnGoodRate]: [],
    [SuggestedResponsesTrigger.OnBadRate]: [],
  };

  return cannedResponses.reduce((acc, canned: ISuggestedCannedResponse) => {
    const suggestedResponsesTags = canned.tags.filter(withSuggestedResponses);
    if (!suggestedResponsesTags.length) {
      return acc;
    }

    suggestedResponsesTags.forEach((suggestedResponsesTag: string, idx, triggers: SuggestedResponsesTrigger[]) => {
      const trigger = getTriggerByShortcut(suggestedResponsesTag);

      (acc[trigger] as ISuggestedCannedResponse[]).push({
        ...canned,
        trigger,
        tags: canned.tags.filter(withoutSuggestedResponses),
        triggers,
      });
    });

    return acc;
  }, initialValues);
};
