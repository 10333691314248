// @ts-strict-ignore
import { isAfter, isBefore, startOfDay } from 'date-fns';
import isEmpty from 'lodash.isempty';
import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { LoginStatus } from 'constants/login-status';
import { TopBarNotificationType } from 'constants/notifications';
import { QueryKey } from 'constants/query-key';
import { anyToBoolean } from 'helpers/boolean';
import { isDesktopAppDetected } from 'helpers/desktop-app/is-detected';
import { isDesktopView, isMobileView } from 'helpers/device';
import { fetchSubscriptions } from 'hooks/api/use-product-data';
import { type Subscriptions } from 'interfaces/subscription';
import {
  collapseNotificationBar,
  collapseNotificationBarWithoutAnimation,
  expandNotificationBar,
} from 'services/notifications-bar';
import { getQueryClient } from 'services/query-client/client';
import { RequestAction } from 'store/entities/actions';
import { getLoggedInAgentStatus } from 'store/entities/agents/selectors';
import { selectIsDisableNativeTicketsExperiment } from 'store/entities/experiments/selectors';
import { AGENT_CUSTOM_PROPERTIES } from 'store/features/agent-custom-properties/actions';
import {
  getDesktopAppInstalled,
  getDownloadDesktopAppNextVisibilityDate,
  getHasPendingInvites,
  getNativeTicketsSubsetBannerVisibilityDate,
  hasFetchedAgentCustomProperties,
} from 'store/features/agent-custom-properties/selectors';
import { getHadChatsRecently, getIsCodeInstalled } from 'store/features/code-installation/selectors';

import { NotificationsBarActions, NotificationsBarActionsNames } from './actions';
import { shouldShowBrowserNotificationsBar } from './helpers';
import { hasMobileNotifications, hasNotifications, isNotificationBarVisible } from './selectors';

export const NATIVE_TICKETS_SUNSET_DATE = '2025-01-06';

function* expand(): SagaIterator {
  const isVisible = yield select(isNotificationBarVisible);

  if (!isVisible) {
    return;
  }

  const shouldExpandOnDesktop = yield select(hasNotifications);
  const shouldExpandOnMobile = yield select(hasMobileNotifications);
  const shouldExpand = (isMobileView() && shouldExpandOnMobile) || (isDesktopView() && shouldExpandOnDesktop);

  if (!shouldExpand) {
    return;
  }

  expandNotificationBar();
}

function* handleBrowserNotificationBar(): SagaIterator {
  const showBrowserNotificationsBar = shouldShowBrowserNotificationsBar();
  const isCodeInstalled = yield select(getIsCodeInstalled);

  if (showBrowserNotificationsBar && isCodeInstalled) {
    const barType = showBrowserNotificationsBar as TopBarNotificationType;
    yield put(
      NotificationsBarActions.showNotificationsBar({
        name: TopBarNotificationType.BrowserNotifications,
        props: { type: barType },
      }),
    );
  }
}

function* handleInstallCodeNotificationBar(): SagaIterator {
  const isCodeInstalled = yield select(getIsCodeInstalled);
  const hadChatsRecently = yield select(getHadChatsRecently);

  if (hadChatsRecently) {
    return;
  }

  if (!isCodeInstalled) {
    yield put(
      NotificationsBarActions.showNotificationsBar({
        name: TopBarNotificationType.InstallCode,
      }),
    );
  }
}

function* handleAwayStatusNotificationBar(): SagaIterator {
  const status: LoginStatus = yield select(getLoggedInAgentStatus);

  if (status === LoginStatus.Away) {
    yield put(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.StatusAway }));
  }
}

function* handlePendingInvitesNotificationBar(): SagaIterator {
  const areAgentCustomPropertiesFetched: boolean = yield select(hasFetchedAgentCustomProperties);
  if (!areAgentCustomPropertiesFetched) {
    yield take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]);
  }

  const hasPendingInvites = yield select(getHasPendingInvites);
  if (hasPendingInvites) {
    yield put(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.SendPendingInvites }));
  }
}

export function* handleDownloadDesktopAppNotificationBar(): SagaIterator {
  if (isDesktopAppDetected()) {
    return;
  }

  const areAgentCustomPropertiesFetched: boolean = yield select(hasFetchedAgentCustomProperties);
  if (!areAgentCustomPropertiesFetched) {
    yield take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]);
  }

  const desktopAppInstalled = yield select(getDesktopAppInstalled);
  const nextVisibilityDate = yield select(getDownloadDesktopAppNextVisibilityDate);
  const today = new Date();

  if (!anyToBoolean(desktopAppInstalled) && (!nextVisibilityDate || isAfter(today, startOfDay(nextVisibilityDate)))) {
    yield put(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.DownloadDesktopApp }));
  }
}

export function* handleNativeTicketsSunsetBar(): SagaIterator {
  const queryClient = getQueryClient();
  let subscriptions = queryClient.getQueryData<{ subscriptions: Subscriptions }>([QueryKey.ActivatedSubscriptions]);

  if (isEmpty(subscriptions?.subscriptions)) {
    subscriptions = yield call(fetchSubscriptions);
    queryClient.setQueryData([QueryKey.ActivatedSubscriptions], subscriptions);
  }

  const areNativeTicketsDisabled = yield select(selectIsDisableNativeTicketsExperiment);
  const nextVisibilityDate = yield select(getNativeTicketsSubsetBannerVisibilityDate);
  const today = new Date();

  if (
    !areNativeTicketsDisabled &&
    !['active', 'trial'].includes(subscriptions?.subscriptions?.helpdesk?.status) &&
    isBefore(today, NATIVE_TICKETS_SUNSET_DATE) &&
    (!nextVisibilityDate || isAfter(today, startOfDay(nextVisibilityDate)))
  ) {
    yield put(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.NativeTicketsSunset }));
  }
}

function* initializeSaga(): SagaIterator {
  yield all([
    call(handleBrowserNotificationBar),
    call(handleInstallCodeNotificationBar),
    call(handleAwayStatusNotificationBar),
    call(handlePendingInvitesNotificationBar),
    call(handleDownloadDesktopAppNotificationBar),
    call(handleNativeTicketsSunsetBar),
  ]);

  yield put(NotificationsBarActions.toggleDisplayNotificationsBar());
  yield call(expand);
}

function collapseWithoutAnimation(): void {
  collapseNotificationBarWithoutAnimation();
}

function* hideNotification(): SagaIterator {
  const hasNotificationsOnDesktop = yield select(hasNotifications);
  const hasNotificationsOnMobile = yield select(hasMobileNotifications);
  const shouldCollapse =
    (isMobileView() && !hasNotificationsOnMobile) || (isDesktopView() && !hasNotificationsOnDesktop);

  if (!shouldCollapse) {
    /*
    Trigger expand as fallback to handle hidden topbar (even if we have notifications to display) when browser tab is hidden for some time
    */
    yield call(expand);

    return;
  }

  collapseNotificationBar();
  yield call(expand);
}

export function* notificationsBarSaga(): SagaIterator {
  yield takeLatest('APP_READY', initializeSaga);
  yield takeEvery(NotificationsBarActionsNames.HIDE_NOTIFICATIONS_BAR, hideNotification);
  yield takeEvery(NotificationsBarActionsNames.HIDE_ALL_NOTIFICATIONS_BAR, collapseWithoutAnimation);
  yield takeEvery(
    [NotificationsBarActionsNames.SHOW_NOTIFICATIONS_BAR, NotificationsBarActionsNames.SHOW_ALL_NOTIFICATIONS_BAR],
    expand,
  );
}
