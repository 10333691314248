import { SETTINGS_NAVIGATION_PATHS } from 'constants/copilot';
import { removeDuplicates } from 'helpers/array';
import type { KeyMap, PickRequired } from 'helpers/interface';
import { capitalizeFirstLetter } from 'helpers/string';

import { type CopilotEntitiesState, type CopilotEvent, type SetCopilotSessionIdPayload } from './interfaces';

export function getGroupedIdsForNewCopilotEvent(
  currentEvents: KeyMap<CopilotEvent>,
  currentGroupedEventIds: string[][],
  newEvent: CopilotEvent,
): string[][] {
  if (Object.keys(currentEvents).length === 0) {
    return [[newEvent.eventId]];
  }

  const latestGroupedEventIds = currentGroupedEventIds[currentGroupedEventIds.length - 1];
  const latestEventId = latestGroupedEventIds[latestGroupedEventIds.length - 1];
  const latestEvent = currentEvents[latestEventId];

  if (latestEvent.authorType === newEvent.authorType && latestEvent.type === newEvent.type) {
    return [...currentGroupedEventIds.slice(0, -1), [...latestGroupedEventIds, newEvent.eventId]];
  }

  return [...currentGroupedEventIds, [newEvent.eventId]];
}

export function getStateForAddNewCopilotEvent(
  state: CopilotEntitiesState,
  payload: CopilotEvent,
): CopilotEntitiesState {
  const currentEvents = state.events.byIds;
  const currentGroupedEventIds = state.events.groupedIds;

  const groupedIds = getGroupedIdsForNewCopilotEvent(currentEvents, currentGroupedEventIds, payload);

  return {
    ...state,
    events: {
      ...state.events,
      byIds: {
        ...currentEvents,
        [payload.eventId]: payload,
      },
      groupedIds,
    },
  };
}

export function getStateForAddCopilotError(state: CopilotEntitiesState, payload: boolean): CopilotEntitiesState {
  return {
    ...state,
    isError: payload,
  };
}

export function getStateForClearCopilotError(state: CopilotEntitiesState): CopilotEntitiesState {
  return {
    ...state,
    isError: false,
  };
}

export function buildEventGroups(groupedEventsIds: string[][], events: KeyMap<CopilotEvent>): CopilotEvent[][] {
  return groupedEventsIds.map((ids) => ids.map((id) => events[id]));
}

export function getStateForSetCopilotSessionId(
  state: CopilotEntitiesState,
  payload: SetCopilotSessionIdPayload,
): CopilotEntitiesState {
  const { sessionId } = payload;

  return {
    ...state,
    currentSessionId: sessionId,
  };
}

export function getStateForCanceCopilotRequest(state: CopilotEntitiesState): CopilotEntitiesState {
  return {
    ...state,
    currentSessionId: null,
  };
}

export function getStateForSetCopilotFailedMessageContent(
  state: CopilotEntitiesState,
  payload: string,
): CopilotEntitiesState {
  return {
    ...state,
    failedMessageContent: payload,
  };
}

export function getStateForClearCopilotFailedMessageContent(state: CopilotEntitiesState): CopilotEntitiesState {
  return {
    ...state,
    failedMessageContent: undefined,
  };
}

export function getStateForUpdateCopilotActionButtons(
  state: CopilotEntitiesState,
  payload: PickRequired<CopilotEvent, 'eventId'>,
): CopilotEntitiesState {
  const { eventId, properties } = payload;

  return {
    ...state,
    events: {
      ...state.events,
      byIds: {
        ...state.events.byIds,
        [eventId]: {
          ...state.events.byIds[eventId],
          properties,
        },
      },
    },
  };
}

export function getStateForCopilotMessageFromSpotlight(
  state: CopilotEntitiesState,
  payload: boolean,
): CopilotEntitiesState {
  return {
    ...state,
    isMessageFromSpotlight: payload,
  };
}

export function formatTitle(inputString: string): string {
  const words = inputString.split('-');
  const capitalizedWords = words.map((word) => capitalizeFirstLetter(word));
  const formattedString = capitalizedWords.join(' ');

  return formattedString;
}

export function arrayToContextString(arr: (string | null)[]): string {
  const filteredArr = arr.filter(Boolean) as unknown[] as string[];
  const withLivechatProduct = removeDuplicates(['LiveChat', ...filteredArr]);

  return withLivechatProduct.map((item) => capitalizeFirstLetter(item)).join(' > ');
}

export function getSettingsCategoryName(path: string): string | null {
  for (const [section, paths] of Object.entries(SETTINGS_NAVIGATION_PATHS)) {
    if (paths.includes(path)) {
      return section;
    }
  }

  return null;
}

export function getUserContextString(userSections: (string | null)[]): string {
  return `User is currently in the ${arrayToContextString(userSections)}`;
}

export function getStateForCopilotLatestAgentEventId(
  state: CopilotEntitiesState,
  payload: PickRequired<CopilotEvent, 'eventId'>,
): CopilotEntitiesState {
  const { eventId } = payload;

  return {
    ...state,
    events: {
      ...state.events,
      latestAgentEventId: eventId,
    },
  };
}
