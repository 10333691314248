interface WithMessage {
  message: string;
}

export const errorToString = (error: unknown, message = ''): string => {
  if (typeof error === 'string') {
    return error;
  }

  if (error === null || error === undefined) {
    return message;
  }

  const withMessage = error as WithMessage;
  if (withMessage?.message) {
    return withMessage.message;
  }

  return JSON.stringify(error);
};
