// @ts-strict-ignore
import { Group } from '@livechat/design-system-icons';

import { toKeyMap } from 'helpers/array';
import type { GroupBase } from 'interfaces/groups';

import { type FilterConfig, type FilterLabelValue } from '../interfaces';

export const groupFilterConfigMultiple: FilterConfig = {
  title: 'Group',
  icon: Group,
  mobileViewTitle: 'Select groups',
  multiple: true,
  getFilterLabelValues: (
    filterValues: string[] | null,
    groups: GroupBase[],
    filterCompareValues: string[] | null
  ): FilterLabelValue[] => {
    if (filterValues === null) {
      return null;
    }

    const groupsHash = toKeyMap(groups, 'id');

    if (filterCompareValues) {
      const groupLabels = filterValues.reduce((acc: string[], groupId) => {
        if (groupsHash[groupId]) {
          acc.push(groupsHash[groupId].name);
        }

        return acc;
      }, []);

      const compareGroupLabels = filterCompareValues.reduce((acc: string[], groupId) => {
        if (groupsHash[groupId]) {
          acc.push(groupsHash[groupId].name);
        }

        return acc;
      }, []);

      return [{ key: '0', label: `${groupLabels.join(' OR ')} compare to ${compareGroupLabels.join(' OR ')}` }];
    }

    return filterValues.reduce((acc: FilterLabelValue[], groupId) => {
      if (groupsHash[groupId]) {
        acc.push({ key: groupsHash[groupId].id.toString(), label: groupsHash[groupId].name });
      } else {
        acc.push({
          key: groupId,
          label: `Group ${groupId} (removed)`,
        });
      }

      return acc;
    }, []);
  },
};
