// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { getTime } from 'date-fns';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  delay,
  fork,
  put,
  type PutEffect,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  ChatEventStatus,
  FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_FALLBACK_TIME,
  MESSAGE_STATUS_CHANGE_TO_PENDING_TIME,
  MESSEGE_DELIVERY_WAIT_TIME,
} from 'constants/chat-event-status';
import { ChatEventSubType, ChatEventType } from 'constants/chat-event-type';
import { ChatRecipients } from 'constants/chat-recipients';
import { ChatThreadStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { UNASSIGNED_CHATS_PAGE_SIZE } from 'constants/chats/chat-list';
import { ChatEvent } from 'constants/chats/event';
import { TransferType } from 'constants/chats/transfer';
import { GlobalModal } from 'constants/global-modal';
import { NavigationPath } from 'constants/navigation';
import { QueryKey } from 'constants/query-key';
import { Section } from 'constants/section';
import { SectionName } from 'constants/section-name';
import { SprigEvents } from 'constants/sprig-events';
import { ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { UserType } from 'constants/user-type';
import { ViewActionSource } from 'constants/view-actions-source';
import { EventAdditionalProperty, EventPlace } from 'helpers/analytics';
import { toKeyMap } from 'helpers/array';
import { booleanToNumericString } from 'helpers/boolean';
import { navigate } from 'helpers/routing';
import { uniqueId } from 'helpers/string';
import { ANCHOR_LINK_MARKER } from 'helpers/system-messages';
import { getToastContent } from 'helpers/toast';
import { SourceType } from 'interfaces/entities/ticket';
import type { SendMessagePayload } from 'interfaces/web-socket-events';
import { type ProductData } from 'services/api/accounts/interfaces';
import { DEFAULT_FETCH_ALL_LIMIT } from 'services/api/archive/constants';
import { APIErrorMessage } from 'services/api/chat/interfaces';
import { browserHistory } from 'services/browser-history';
import { chatFollowManager } from 'services/chat-follow-manager';
import { chatsClient } from 'services/connectivity/agent-chat-api/chats/client';
import { type ResumeChatResponse } from 'services/connectivity/agent-chat-api/chats/types/resume-chat';
import { normalizeError } from 'services/connectivity/agent-chat-api/helpers';
import { AgentChatApiErrorType, type AgentChatApiResponse } from 'services/connectivity/agent-chat-api/types';
import { trackEvent } from 'services/event-tracking';
import { getQueryClient } from 'services/query-client/client';
import { removeItem } from 'services/session-storage';
import { handleSendMessage } from 'services/socket-message-handler/chat/send-message';
import { getSprigService } from 'services/sprig';
import { deactivateChat } from 'services/web-socket/actions/deactivate-chat';
import { markEventsAsSeen as markAsSeen } from 'services/web-socket/actions/mark-events-as-seen';
import { sendChatMessage } from 'services/web-socket/actions/send-chat-message';
import { sendMulticast } from 'services/web-socket/actions/send-multicast';
import { sendSupervisorMessage } from 'services/web-socket/actions/send-supervisor-message';
import { sendTypingIndicator } from 'services/web-socket/actions/send-typing-indicator';
import { transferToAgent } from 'services/web-socket/actions/transfer-to-agent';
import { transferToGroup } from 'services/web-socket/actions/transfer-to-group';
import { RequestAction } from 'store/entities/actions';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { getBotsCount } from 'store/entities/bots/selectors';
import { ChatsEntitiesActionNames, ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getUnpinnedUnassignedChatsCount } from 'store/entities/chats/computed';
import {
  isClosedThread,
  isQueuedChat,
  isThreadWithStatus,
  isUnassignedChat,
} from 'store/entities/chats/helpers/common';
import type {
  ChatEventEntity,
  ChatThreadEntity,
  IAssignChatToOtherAgentPayload,
  IChatPickedByOtherAgent,
  IIncomingChatThreadPayload,
  IMessage,
  IMyChat,
  IRemoveChatThreadPayload,
  ISetTagRequestPayload,
  IStartSupervisingFailurePayload,
  IStartSupervisingSuccessPayload,
  IUnsetTagRequestPayload,
} from 'store/entities/chats/interfaces';
import { MAPPED_CHAT_TYPE_TO_COMPARATOR, updateChatsListOrder } from 'store/entities/chats/sagas';
import {
  getChatIdByThreadId,
  getCurrentThreadCustomerId,
  getHasHistory,
  getIsChatSupervised,
  getOngoingActiveThreadInChat,
  getSupervisedIds,
  getThread,
  getThreadEvent,
  getThreadEvents,
  getThreadExists,
  getThreadMessageEvent,
  getThreadSupervisorsIds,
  getThreadType,
  getUnassignedIds,
  getUnassingedChatsNextPageId,
} from 'store/entities/chats/selectors';
import { customerExists } from 'store/entities/customers/selectors';
import { TagActions } from 'store/entities/tags/actions';
import { TICKET, TicketActions } from 'store/entities/tickets/actions';
import type { ICreateTicketFailurePayload } from 'store/entities/tickets/interfaces';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { PersistedChatsSortType } from 'store/features/agent-custom-properties/interfaces';
import { ChatButtonsActions } from 'store/features/chat-buttons/actions';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { RoutingActionsEnum } from 'store/features/routing/actions';
import { getIsOnSection, getIsPreviousSection } from 'store/features/routing/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { TooltipsActions } from 'store/features/tooltips/actions';
import { TooltipType } from 'store/features/tooltips/interfaces';
import { TranscriptFeatureActions } from 'store/features/transcript/actions';
import type { IActionWithPayload } from 'store/helper';
import { createHasFetchedSelector } from 'store/requests/selectors';

import { TrafficActions } from '../traffic/actions';

import { ChatsViewActions, ChatsViewActionsNames } from './actions';
import { createChatPath } from './helpers/navigation';
import {
  addMessageToStorage,
  getRandomString,
  handleCreateTicketRetry,
  handleOpenTicket,
  handleStartSuperviseFailure,
  removeMessageFromStorage,
} from './helpers/sagas';
import type {
  IAssingChatPayload,
  ICloseAndArchiveChatPayload,
  ICloseChatPayload,
  ICreateTicketPayload,
  ICreateTicketSuccessWithThreadPayload,
  IMarkEventsAsReadPayload,
  IMarkEventsAsSeenPayload,
  INavigateToChatPayload,
  INavigateToNextAvailableChatPayload,
  INavigateToSelectedOrFirstAvailableChatPayload,
  IPerformChatActionPayload,
  IPickFromQueuePayload,
  ISelectChatFromRoutePayload,
  ISelectChatPayload,
  ISendMessagePayload,
  ISendTranscriptPayload,
  ISetAgentMessageSneakPeekPayload,
  ISetChatTagRequestPayload,
  ISetChatsSortOrderPayload,
  ISetSelectedIdPayload,
  ISetTypingIndicatorPayload,
  IStartChatPayload,
  IStopSupervisingPayload,
  ISuperviseChatPayload,
  ITakeoverChatPayload,
  ITransferChatPayload,
  ITransferChatWithOptionsPayload,
  IUnsetChatTagRequestPayload,
} from './interfaces';
import { replySuggestionsSaga } from './sagas/reply-suggestions';
import { selectNonExistingChatFromRoute } from './sagas/select-non-existing-chat';
import { textEnhancementsSaga } from './sagas/text-enhancements';
import {
  getChatUnreadAgentEventsIds,
  getChatUnreadCustomerEventsIds,
  getChatUnreadSupervisorEventsIds,
  getChatUnseenAgentEventsIds,
  getChatUnseenCustomerEventsIds,
  getChatUnseenSupervisorEventsIds,
  getChatsLoaded,
  getFirstAvailableChatThreadId,
  getIsCustomerMarkedAsAwaitingNavigation,
  getIsFetchingHistory,
  getIsThreadAlreadyVisited,
  getNextThreadToSelect,
  getPreviousThreadToSelect,
  getPrivateMode,
  getSelectedOrFirstAvailableThreadId,
  getSelectedThreadId,
  getThreadAgentName,
  getThreadIdToSelectAfterThreadClose,
  shouldDisplayTagReminder,
} from './selectors';

const queryClient = getQueryClient();

const CHATBOT_PRODUCT_TAGS = ['chatbot', 'chatbot-transfer'];

function* waitForChatsData(): SagaIterator {
  const isDataLoaded = yield select(getChatsLoaded);

  if (!isDataLoaded) {
    yield take(ChatsEntitiesActionNames.SET_DATA);
  }
}

function* closeAndArchiveChat(action: IActionWithPayload<string, ICloseAndArchiveChatPayload>): SagaIterator {
  const { threadId } = action.payload;
  const thread: IMyChat = yield select(getThread, threadId);

  if (thread) {
    // It would be the best to just detect if ChatBot is enabled as it can apply automated tags.
    // Unfortunately MyProducts are now only fetched if ProductSwitcher is enabled.
    // As a fallback we check what is the bots count, however, not all bots are from ChatBot.
    // This should bring no negative consequences.
    const botsCount: number = yield select(getBotsCount);
    const isChatBotActive = queryClient
      .getQueryData<ProductData[]>([QueryKey.MyProducts])
      ?.some(({ product }) => product === 'ChatBot');

    const shouldIgnoreTags = isChatBotActive || botsCount > 0;
    const tagsToIgnore = shouldIgnoreTags ? CHATBOT_PRODUCT_TAGS : [];
    const shouldShowTagReminder: boolean = yield select(shouldDisplayTagReminder, threadId, tagsToIgnore);

    if (shouldShowTagReminder) {
      if (!isClosedThread(thread) && !isUnassignedChat(thread)) {
        yield put(ChatsViewActions.closeChat({ threadId }));
      }
      yield put(ChatsViewActions.changeTagReminderVisibility({ threadId, visibility: true }));
    } else {
      const isUnassigned = isUnassignedChat(thread);

      if (isUnassigned) {
        yield put(ChatsEntitiesActions.unpinChat({ threadId }));

        return;
      }

      yield call(handleSelectNextAvailableThread, threadId);
      yield put(ChatsEntitiesActions.updateChatThread({ threadId, thread: { status: ChatThreadStatus.Closed } }));
      yield put(ChatsEntitiesActions.removeChatThread({ threadId, omitTags: true }));

      if ((thread as IMyChat).status !== ChatThreadStatus.Closed) {
        yield call(deactivateChat, (thread as IMyChat).chatId, false);
      }
    }
  } else {
    const isOnChats = yield select(getIsOnSection, Section.Chats, false);

    if (isOnChats) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.NO_CHAT_FOUND_ERROR),
          kind: ToastVariant.Error,
        }),
      );
    }
  }
}

function* closeChat(action: IActionWithPayload<string, ICloseChatPayload>): SagaIterator {
  const { threadId } = action.payload;
  const thread: ChatThreadEntity | null = yield select(getThread, threadId);

  if (thread) {
    yield put(ChatsEntitiesActions.updateChatThread({ threadId, thread: { status: ChatThreadStatus.Closed } }));
    yield call(deactivateChat, thread.chatId, false);

    // remove saved, not delivered thread messages
    yield put(ChatsEntitiesActions.removeNotDeliveredMessages({ threadId }));
    yield call(removeItem, `${threadId}^2`);

    // init sprig event
    void getSprigService().initSprigEvent(SprigEvents.ChatClosed);
  } else {
    const isOnChats = yield select(getIsOnSection, Section.Chats, false);

    if (isOnChats) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.NO_CHAT_FOUND_ERROR),
          kind: ToastVariant.Error,
        }),
      );
    }
  }
}

// TODO: After fully switching to LC3 it might be a part of `detectAgentChatAction`.
function* detectSuperviseNavigation(action: IActionWithPayload<string, IStartSupervisingSuccessPayload>): SagaIterator {
  const {
    thread: { customerId, threadId },
  } = action.payload;

  const isAwaitingNavigation = yield select(getIsCustomerMarkedAsAwaitingNavigation, customerId);

  if (isAwaitingNavigation) {
    yield put(ChatsViewActions.clearAwaitingNavigation());
    const chatId = yield select(getChatIdByThreadId, threadId);
    navigate(createChatPath(chatId, threadId));
  }
}

function* detectAgentChatAction(action: IActionWithPayload<string, IIncomingChatThreadPayload>): SagaIterator {
  const {
    convertedFrom,
    previousThreadId,
    thread: { threadId, customerId, type: threadType },
  } = action.payload;

  yield put(TrafficActions.customerThreadStatusChange());

  /**
   * Conversion from selected Unassigned Chat to Unassigned Chat
   * is removing previous one and adding new thread
   * if it was selected we need trigger navigation
   */
  if (convertedFrom === ChatType.Unassigned) {
    const convertedTo = threadType;
    const selectedThreadId = yield select(getSelectedThreadId);

    const hasThreadBeenAssignedToCurrentAgent =
      (convertedTo === ChatType.Unassigned || convertedTo === ChatType.My) && previousThreadId === selectedThreadId;

    if (hasThreadBeenAssignedToCurrentAgent) {
      const isOnChats = yield select(getIsOnSection, Section.Chats, false);

      if (isOnChats) {
        yield put(ChatsViewActions.navigateToChat({ threadId, shouldReplace: true }));
      }
    }

    return;
  }

  /**
   * Detect an ongoing navigation awaiting to be resolved based on incoming thread.
   */
  const isAwaitingNavigation = yield select(getIsCustomerMarkedAsAwaitingNavigation, customerId);

  if (isAwaitingNavigation) {
    yield put(ChatsViewActions.clearAwaitingNavigation());
    const chatId = yield select(getChatIdByThreadId, threadId);
    navigate(createChatPath(chatId, threadId));
  }
}

function* navigateToSelectedOrFirstAvailableChat(
  action: IActionWithPayload<string, INavigateToSelectedOrFirstAvailableChatPayload>,
): SagaIterator {
  const threadId = yield select(getSelectedOrFirstAvailableThreadId);
  const chatId = yield select(getChatIdByThreadId, threadId);

  if (chatId && threadId) {
    browserHistory.replaceUrl(createChatPath(chatId, threadId));

    return;
  }

  if (action.payload.withFallbackToBaseUrl) {
    browserHistory.replaceUrl(NavigationPath.Chats);
  }
}

function* navigateToNextAvailableChat(
  action: IActionWithPayload<string, INavigateToNextAvailableChatPayload>,
): SagaIterator {
  const nextAvailableThreadId = yield select(getThreadIdToSelectAfterThreadClose, action.payload.threadId);

  yield put(ChatsViewActions.setSelectedId({ threadId: nextAvailableThreadId || '' }));
  const chatId = yield select(getChatIdByThreadId, nextAvailableThreadId);
  navigate(createChatPath(chatId, nextAvailableThreadId));
}

function* fetchTags(): SagaIterator {
  const wasTagsFetched = yield select(createHasFetchedSelector(['FETCH_COLLECTION_TAG']));

  if (!wasTagsFetched) {
    yield put(TagActions.fetchCollection({}));
  }
}

function* setTag(
  action: IActionWithPayload<string, ISetTagRequestPayload>,
): IterableIterator<PutEffect<ReturnType<typeof ChatsEntitiesActions.setTagRequest>>> {
  yield put(ChatsEntitiesActions.setTagRequest(action.payload));
}

function* unsetTag(
  action: IActionWithPayload<string, IUnsetTagRequestPayload>,
): IterableIterator<PutEffect<ReturnType<typeof ChatsEntitiesActions.unsetTagRequest>>> {
  yield put(ChatsEntitiesActions.unsetTagRequest(action.payload));
}

function* setChatTagSuccess(
  action: IActionWithPayload<string, ISetChatTagRequestPayload>,
): IterableIterator<PutEffect<ReturnType<typeof ChatsViewActions.setChatTagSuccess>>> {
  const { payload } = action;
  yield put(ChatsViewActions.setChatTagSuccess({ threadId: payload.threadId, tag: payload.tag }));
}

function* unsetChatTagSuccess(
  action: IActionWithPayload<string, IUnsetChatTagRequestPayload>,
): IterableIterator<PutEffect<ReturnType<typeof ChatsViewActions.unsetChatTagSuccess>>> {
  const { payload } = action;
  yield put(ChatsViewActions.unsetChatTagSuccess({ threadId: payload.threadId, tag: payload.tag }));
}

function* pickFromQueue(action: IActionWithPayload<string, IPickFromQueuePayload>): SagaIterator {
  const { threadId } = action.payload;
  const thread: ChatThreadEntity | null = yield select(getThread, threadId);

  if (thread) {
    const customerIdPayload = { customerId: thread.customerId };
    yield put(ChatsViewActions.markCustomerAsAwaitingNavigation(customerIdPayload));
    yield put(ChatsEntitiesActions.pickFromQueue(customerIdPayload));

    const selectedThreadId = yield select(getSelectedThreadId);
    if (selectedThreadId !== threadId) {
      const chatId = yield select(getChatIdByThreadId, threadId);
      browserHistory.push(createChatPath(chatId, threadId));
    }
  } else {
    const isOnChats = yield select(getIsOnSection, Section.Chats, false);

    if (isOnChats) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.NO_CHAT_FOUND_ERROR),
          kind: ToastVariant.Error,
        }),
      );
    }
  }
}

function* assignChat(action: IActionWithPayload<string, IAssingChatPayload>): SagaIterator {
  const { threadId } = action.payload;
  const threadExists: ChatThreadEntity = yield select(getThreadExists, threadId);

  if (threadExists) {
    yield put(ChatsEntitiesActions.assignChat({ threadId }));
  } else {
    const isOnChats = yield select(getIsOnSection, Section.Chats, false);

    if (isOnChats) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.NO_CHAT_FOUND_ERROR),
          kind: ToastVariant.Error,
        }),
      );
    }
  }
}

function* selectChat(action: IActionWithPayload<string, ISelectChatPayload>): SagaIterator {
  const { chatId, threadId } = action.payload;

  yield put(ChatsViewActions.selectChatFromRoute({ chatId, threadId }));
}

function* setSelectedThreadId(action: IActionWithPayload<string, ISetSelectedIdPayload>): SagaIterator {
  yield call(waitForChatsData);

  const { threadId } = action.payload;
  const thread: ChatThreadEntity = yield select(getThread, threadId);

  if (!thread) {
    return;
  }

  /**
   * 2. Fetch chat history for Unassigned chats or chats that were Unassigned.
   */
  const hasHistoryLoaded = yield select(getHasHistory, threadId);
  const isLoadingHistory = yield select(getIsFetchingHistory, threadId);
  const wasUnassigned = thread.customProperties && thread.customProperties.wasUnassigned;
  const threadEvents = yield select(getThreadEvents, threadId);
  const shouldFetchHistory =
    !hasHistoryLoaded &&
    !isLoadingHistory &&
    (isUnassignedChat(thread) || wasUnassigned || !Object.values(threadEvents).length);
  if (shouldFetchHistory) {
    yield put(ChatsEntitiesActions.fetchChatHistory({ threadId }));
  }
}

function* navigateToChat(action: IActionWithPayload<string, INavigateToChatPayload>): SagaIterator {
  const { threadId, shouldReplace } = action.payload;
  const chatId = yield select(getChatIdByThreadId, threadId);

  const canNavigate: boolean = yield select(getThreadExists, threadId) && chatId;

  if (canNavigate) {
    navigate(createChatPath(chatId, threadId), { replace: shouldReplace });
  }
}

function* selectNextChat(): SagaIterator {
  const threadId = yield select(getNextThreadToSelect);
  const chatId = yield select(getChatIdByThreadId, threadId);

  if (chatId && threadId) {
    browserHistory.push(createChatPath(chatId, threadId));
  }
}

function* selectPreviousChat(): SagaIterator {
  const threadId = yield select(getPreviousThreadToSelect);
  const chatId = yield select(getChatIdByThreadId, threadId);

  if (chatId && threadId) {
    browserHistory.push(createChatPath(chatId, threadId));

    return;
  }
}

export function* selectChatFromRoute(action: IActionWithPayload<string, ISelectChatFromRoutePayload>): SagaIterator {
  const { chatId, threadId } = action.payload;
  const thread: ChatThreadEntity = yield select(getThread, threadId);

  if (thread) {
    const isThreadAlreadyVisited = yield select(getIsThreadAlreadyVisited, threadId);

    // Data about queued chat comes from WS
    if (!isThreadAlreadyVisited && thread.type !== ChatType.Queued) {
      yield put(ChatsEntitiesActions.fetchChatData({ threadId }));
    }

    yield put(ChatsViewActions.setSelectedId({ threadId }));

    return;
  }

  let shouldNavigateToDefaultChat = false;

  if (threadId) {
    shouldNavigateToDefaultChat = yield call(selectNonExistingChatFromRoute, chatId, threadId);
  }
  if (shouldNavigateToDefaultChat) {
    yield put(ChatsViewActions.navigateToSelectedOrFirstAvailableChat({ withFallbackToBaseUrl: true }));
  }
}

function* handleSelectNextAvailableThread(removedThreadId: string): SagaIterator {
  const selectedThreadId = yield select(getSelectedThreadId);
  const nextAvailableThreadId = yield select(getThreadIdToSelectAfterThreadClose, removedThreadId);

  const isOnChats = yield select(getIsOnSection, Section.Chats, false);

  if (removedThreadId === selectedThreadId) {
    if (isOnChats) {
      const chatId = yield select(getChatIdByThreadId, nextAvailableThreadId);

      browserHistory.replaceUrl(createChatPath(chatId, nextAvailableThreadId));
    }
  }
}

function* stopSupervising(action: IActionWithPayload<string, IStopSupervisingPayload>): SagaIterator {
  const { threadId, shouldMoveToOtherChats } = action.payload;
  const threadExists: ChatThreadEntity = yield select(getThreadExists, threadId);

  if (threadExists) {
    yield put(ChatsEntitiesActions.updateChatThread({ threadId, thread: { status: ChatThreadStatus.Closed } }));
    yield put(ChatsEntitiesActions.stopSupervising({ threadId, shouldMoveToOtherChats }));
    yield call(handleSelectNextAvailableThread, threadId);
  } else {
    const isOnChats = yield select(getIsOnSection, Section.Chats, false);

    if (isOnChats) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.NO_CHAT_FOUND_ERROR),
          kind: ToastVariant.Error,
        }),
      );
    }
  }
}

function* handleChatPickedByOtherAgent(action: IActionWithPayload<string, IChatPickedByOtherAgent>): SagaIterator {
  const { threadId } = action.payload;
  const agentName = yield select(getThreadAgentName, threadId);

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CHAT_ALREADY_PICKED, { agentName }),
      kind: ToastVariant.Warning,
    }),
  );
}

function* startSupervisingFailure(action: IActionWithPayload<string, IStartSupervisingFailurePayload>): SagaIterator {
  const { chatId, currentAgentId } = action.payload;

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.START_SUPERVISE_FAILURE),
      kind: ToastVariant.Error,
      action: {
        label: 'Retry',
        onClick: handleStartSuperviseFailure(chatId, currentAgentId),
        closeOnClick: true,
      },
    }),
  );
}

function* performChatAction(action: IActionWithPayload<string, IPerformChatActionPayload>): SagaIterator {
  const { integrationAppId, integrationButtonId, threadId, source } = action.payload;

  yield put(ChatButtonsActions.performAction({ integrationAppId, integrationButtonId, threadId, source }));
}

function* transferChat(action: IActionWithPayload<string, ITransferChatPayload>): SagaIterator {
  const { threadId, agentLogin, groupId, groupStatus } = action.payload;
  const thread: ChatThreadEntity = yield select(getThread, threadId);
  let transferHandler: (chatId: string, targetId: string) => Promise<unknown> = null;
  let transferTargetId: string = null;

  if (agentLogin) {
    transferHandler = transferToAgent;
    transferTargetId = agentLogin;
  } else if (groupId) {
    transferHandler = transferToGroup;
    transferTargetId = groupId;
  }

  if (transferHandler && transferTargetId) {
    try {
      yield call(transferHandler, thread.chatId, transferTargetId);
      yield put(ChatsViewActions.transferChatSuccessful());

      /**
       * To prevent situation that chat blink after takeover the chat
       */
      const loggedInAgentLogin = yield select(getLoggedInAgentLogin);

      if (transferTargetId !== loggedInAgentLogin) {
        yield call(handleSelectNextAvailableThread, threadId);
      }

      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.TRANSFER_SUCCESS),
          kind: ToastVariant.Success,
        }),
      );

      trackEvent(ChatEvent.ChatTransferred, EventPlace.Chats, {
        property: EventAdditionalProperty.Target,
        value: agentLogin ? TransferType.Agent : TransferType.Group,
        toMe: booleanToNumericString(agentLogin === loggedInAgentLogin),
        ...(groupStatus && { groupStatus }),
      });
    } catch (error) {
      yield put(ChatsViewActions.transferChatFailure());

      const errorMessage = error?.message;
      const toastContent = getToastContent(ToastContent.TRANSFER_ERROR);
      const toastMessage = errorMessage ? `${toastContent} ${errorMessage}` : toastContent;
      yield put(
        ToastsActions.createToast({
          content: toastMessage,
          kind: ToastVariant.Error,
        }),
      );

      trackEvent(ChatEvent.ChatTransferredError, EventPlace.Chats, {
        transferType: agentLogin ? TransferType.Agent : TransferType.Group,
      });
    }
  }
}

function* createTicket(action: IActionWithPayload<string, ICreateTicketPayload>): SagaIterator {
  const { threadId } = action.payload;

  /**
   * API require chatId as param but it's old convection name, it's threadId for chats-beta
   */
  yield put(
    TicketActions.createTicket({
      ...action.payload,
      chatId: threadId,
      sourceType: SourceType.Chat,
      sourceView: ViewActionSource.Chats,
    }),
  );
}

function* createTicketSuccess(action: IActionWithPayload<string, ICreateTicketSuccessWithThreadPayload>): SagaIterator {
  const { sourceType, sourceView, ticketId, threadId } = action.payload;

  if (sourceType !== SourceType.Chat || sourceView !== ViewActionSource.Chats) {
    return;
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CREATE_TICKET_SUCCESS),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Success,
      action: {
        label: 'Open ticket',
        onClick: handleOpenTicket(ticketId),
        closeOnClick: true,
      },
    }),
  );

  yield put(
    ChatsEntitiesActions.newSystemMessage({
      systemMessage: {
        id: uniqueId(),
        subType: ChatEventSubType.TicketCreated,
        text: `You have created a ${ANCHOR_LINK_MARKER}`,
        textVariables: {
          linkUrl: `tickets/${ticketId}`,
          linkLabel: 'ticket',
        },
        timestampInMs: getTime(new Date()),
        type: ChatEventType.Event,
      },
      threadId,
    }),
  );

  trackEvent(ChatEvent.TicketCreated, EventPlace.Chats);
}

function* createTicketFailure(action: IActionWithPayload<string, ICreateTicketFailurePayload>): SagaIterator {
  const { payload } = action;

  if (payload.sourceType !== SourceType.Chat || payload.sourceView !== ViewActionSource.Chats) {
    return;
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CREATE_TICKET_ERROR, { error: payload.error }),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Error,
      action: {
        label: 'Retry',
        onClick: handleCreateTicketRetry(payload),
        closeOnClick: true,
      },
    }),
  );
}

function* setTypingIndicator({ payload }: IActionWithPayload<string, ISetTypingIndicatorPayload>): SagaIterator {
  const { threadId, isTyping } = payload;

  const thread: ChatThreadEntity | null = yield select(getThread, threadId);

  if (
    thread &&
    isThreadWithStatus(thread) &&
    [ChatThreadStatus.Active, ChatThreadStatus.Idle].includes(thread.status)
  ) {
    const { chatId } = thread;

    const isPrivateModeOn = yield select(getPrivateMode, threadId);
    const isChatSupervised = yield select(getIsChatSupervised, threadId);
    const isPrivateMessage = isPrivateModeOn || isChatSupervised;
    const recipients = isPrivateMessage ? ChatRecipients.Agents : ChatRecipients.All;

    try {
      yield call(sendTypingIndicator, chatId, isTyping, recipients);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(
        `Could not set typing indicator for chat ${chatId}, thread: ${threadId}, reason: ${(error as Error).message}`,
        payload,
      );
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn('Not sending typing indicator, thread is not active', threadId, thread);
  }
}

function* setAgentMessageSneakPeek({
  payload,
}: IActionWithPayload<string, ISetAgentMessageSneakPeekPayload>): SagaIterator {
  const { isTyping, threadId, message } = payload;

  const supervisorsIds: string[] = yield select(getThreadSupervisorsIds, threadId);

  if (!supervisorsIds.length) {
    return;
  }

  try {
    yield call(
      sendMulticast,
      {
        agents: {
          ids: supervisorsIds,
        },
      },
      {
        agent_message_sneak_peek: true,
        is_typing: isTyping,
        thread_id: threadId,
        message,
      },
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Could not set agent message sneak peek for thread: ${threadId}, reason: ${(error as Error).message}`,
      payload,
    );
  }
}

/**
 * Generator used to track message delivery status
 * When the message was not delivered in the given `waitTime` it will update its status (`nextStatus`)
 * @param threadId tracking message thread id
 * @param messageId message id
 * @param waitTime maximum delivery timeout
 * @param nextStatus new message status if it's not delivered in time
 * @returns Bolean which informs is message was updated with the new status
 */
function* activeMessageListener(
  threadId: string,
  messageId: string,
  waitTime: number,
  nextStatus: ChatEventStatus,
): SagaIterator {
  const currentMessage: IMessage | null = yield select(getThreadEvent, threadId, messageId);

  // Race between message update (sign that message was delivered) and given timeout
  const { shouldChangeStatus }: { shouldChangeStatus: boolean } = yield race({
    shouldChangeStatus: delay(waitTime),
    shouldIgnore: take(({ payload, type }): boolean => {
      // Pattern to take only message update actions with proper status
      const acceptedStatuses = [ChatEventStatus.Delivered, ChatEventStatus.NotDelivered, ChatEventStatus.Read];
      if (type === ChatsEntitiesActionNames.UPDATE_MESSAGE) {
        return payload.messageId === messageId && acceptedStatuses.includes(payload.message.status);
      }

      if (type === ChatsEntitiesActionNames.UPDATE_EVENTS) {
        return Boolean(payload.events[messageId]) && acceptedStatuses.includes(payload.events[messageId].status);
      }

      return false;
    }),
  });

  // Update message with the new status cause it was not delived in time
  if (shouldChangeStatus && currentMessage) {
    yield put(
      ChatsEntitiesActions.updateMessage({
        threadId,
        messageId,
        message: { ...currentMessage, status: nextStatus },
      }),
    );

    return true;
  }

  return false;
}

/**
 * Generator used to track message status
 * @param threadId tracking message thread id
 * @param messageId message id
 */
function* runMessageStatusListeners(threadId: string, messageId: string): SagaIterator {
  const message = (yield select(getThreadEvent, threadId, messageId)) as IMessage;

  if (!message) {
    return;
  }

  const currentMessageTimestamp = message.timestampInMs;

  const hasChangedStatusToPending = yield call(
    activeMessageListener,
    threadId,
    messageId,
    MESSAGE_STATUS_CHANGE_TO_PENDING_TIME,
    ChatEventStatus.Pending,
  );

  if (hasChangedStatusToPending) {
    // Message was not delived in `MESSAGE_STATUS_CHANGE_TO_PENDING_TIME` - the next message delivery listener starts
    const hasChangedStatusToNotDelivered = yield call(
      activeMessageListener,
      threadId,
      messageId,
      MESSEGE_DELIVERY_WAIT_TIME - MESSAGE_STATUS_CHANGE_TO_PENDING_TIME,
      ChatEventStatus.NotDelivered,
    );

    if (hasChangedStatusToNotDelivered) {
      // Message not delivered in time is stored in the session storage
      addMessageToStorage(threadId, message);
    } else {
      // removing message from the store cause delivery was successful
      removeMessageFromStorage(threadId, currentMessageTimestamp);
    }
  } else {
    // removing message from the store cause delivery was successful
    removeMessageFromStorage(threadId, currentMessageTimestamp);
  }
}

function* sendMessage({ payload }: IActionWithPayload<string, ISendMessagePayload>): SagaIterator {
  const { message, retriedMessageId, threadId, richMessageJSON, isPrivateMessage, properties } = payload;

  const messageId = retriedMessageId || getRandomString();
  const isRetry = Boolean(retriedMessageId);
  const isRichMessage = !!richMessageJSON;
  const thread: ChatThreadEntity | null = yield select(getThread, threadId);
  const { chatId } = thread;
  const supervisedIds: string[] = yield select(getSupervisedIds);

  const isSupervisor = supervisedIds.includes(threadId);

  // not connected socket can throw an error

  const messagePayload: SendMessagePayload = {
    messageId,
    messageText: message,
    chatId,
    whisper: Boolean(isPrivateMessage),
    isRichMessage,
    json: richMessageJSON,
    properties,
  };

  try {
    if (isSupervisor) {
      const { whisper, ...supervisorMessage } = messagePayload;
      void sendSupervisorMessage(supervisorMessage);
    } else {
      void sendChatMessage(messagePayload);
    }

    if (isPrivateMessage) {
      trackEvent(ChatEvent.WhisperMessageSent, EventPlace.Chats);
    }
  } catch (e) {
    // TODO: Replace console error with logging to Sentry
    // eslint-disable-next-line no-console
    console.error("Socket error, message couldn't be send", e);
  }

  if (!isRetry) {
    yield call(handleSendMessage, threadId, messageId, message, null, isPrivateMessage, properties);
  } else {
    const currentMessage: IMessage | null = yield select(getThreadEvent, threadId, retriedMessageId);

    if (currentMessage) {
      removeMessageFromStorage(threadId, currentMessage.timestampInMs);

      yield put(
        ChatsEntitiesActions.updateMessage({
          threadId,
          messageId,
          message: {
            ...currentMessage,
            status: ChatEventStatus.Pending,
            wasSeen: true,
            timestampInMs: getTime(new Date()),
            properties,
          },
        }),
      );
    }
  }

  yield call(runMessageStatusListeners, threadId, messageId);

  return messageId;
}

function* transferChatWithOptions(action: IActionWithPayload<string, ITransferChatWithOptionsPayload>): SagaIterator {
  const { threadId, agentLogin, groupId, groupStatus, privateNote } = action.payload;

  const messageId = yield call(
    sendMessage,
    ChatsViewActions.sendMessage({
      threadId,
      message: privateNote,
      isPrivateMessage: true,
    }),
  );

  const message = yield select(getThreadMessageEvent, threadId, messageId);

  if (message) {
    yield put(
      ChatsViewActions.transferChat({
        threadId,
        agentLogin,
        groupId,
        groupStatus,
      }),
    );
  } else {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.TRANSFER_ERROR),
        autoHideDelayTime: ToastAutoHideDelay.VeryLong,
        kind: ToastVariant.Error,
      }),
    );
  }
}

/**
 * It will mark all events in store as READ for given type of author Customer / Supervisor / Agent ...
 * ... and additionaly send event thru WS that messages was seen.
 *
 * Additional info:
 * Difference between event SEEN and READ is that READ should be only marked if other persons has been read message.
 * So it's updated mainly on WS event `events_marked_as_seen`.
 * SEEN is a more local value that tells us that the message has been read by a logged in user
 *
 * @param payload - payload of function
 * @return saga iterartor
 */
function* markEventsAsSeen({ payload }: IActionWithPayload<string, IMarkEventsAsSeenPayload>): SagaIterator {
  const { threadId, typesOfAuthorEventsToMarkAsSeen, sendEvent = false } = payload;
  const thread: ChatThreadEntity | null = yield select(getThread, threadId);

  if (!thread || isUnassignedChat(thread) || isQueuedChat(thread)) {
    return;
  }

  let unseenEventsIds = [];

  if (typesOfAuthorEventsToMarkAsSeen.includes(UserType.Customer)) {
    const unreadCustomerEventsIs = yield select(getChatUnseenCustomerEventsIds, threadId);
    unseenEventsIds = unseenEventsIds.concat(unreadCustomerEventsIs);
  }

  if (typesOfAuthorEventsToMarkAsSeen.includes(UserType.Supervisor)) {
    const unreadSupervisorEventsIs = yield select(getChatUnseenSupervisorEventsIds, threadId);
    unseenEventsIds = unseenEventsIds.concat(unreadSupervisorEventsIs);
  }

  if (typesOfAuthorEventsToMarkAsSeen.includes(UserType.Agent)) {
    const unreadAgentEventsIds = yield select(getChatUnseenAgentEventsIds, threadId);
    unseenEventsIds = unseenEventsIds.concat(unreadAgentEventsIds);
  }

  const unseenEvents: ChatEventEntity[] = yield all(
    unseenEventsIds.map((eventId) => select(getThreadEvent, threadId, eventId)),
  );

  if (sendEvent && unseenEvents.length > 0) {
    const { chatId } = thread;

    const [latestUnreadEvent] = unseenEvents;

    if (latestUnreadEvent) {
      const timestamp = Math.ceil(latestUnreadEvent.timestampInMs / 1000);

      yield call(markAsSeen, chatId, timestamp);
    }
  }

  const eventsMarkedAsSeen = unseenEvents.map((event) => ({ ...event, wasSeen: true }));

  yield put(ChatsEntitiesActions.updateEvents({ events: toKeyMap(eventsMarkedAsSeen, 'id'), threadId }));
}

/**
 * It will mark all events in store as READ for given type of author Customer / Supervisor / Agent
 *
 * Additional info:
 * Difference between event SEEN and READ is that READ should be only marked if other persons has been read message.
 * So it's updated mainly on WS event `events_marked_as_seen`.
 * SEEN is a more local value that tells us that the message has been read by a logged in user
 *
 * @param payload - payload of function
 * @return saga iterartor
 */
function* markEventsAsRead({ payload }: IActionWithPayload<string, IMarkEventsAsReadPayload>): SagaIterator {
  const { threadId, typesOfAuthorEventsToMarkAsRead, upToTimestampInMs } = payload;
  const thread: ChatThreadEntity | null = yield select(getThread, threadId);

  if (!thread || isUnassignedChat(thread) || isQueuedChat(thread)) {
    return;
  }

  let unreadEventsIds = [];

  if (typesOfAuthorEventsToMarkAsRead.includes(UserType.Customer)) {
    const unreadCustomerEventsIds = yield select(getChatUnreadCustomerEventsIds, threadId);
    unreadEventsIds = unreadEventsIds.concat(unreadCustomerEventsIds);
  }

  if (typesOfAuthorEventsToMarkAsRead.includes(UserType.Supervisor)) {
    const unreadSupervisorEventsIds = yield select(getChatUnreadSupervisorEventsIds, threadId);
    unreadEventsIds = unreadEventsIds.concat(unreadSupervisorEventsIds);
  }

  if (typesOfAuthorEventsToMarkAsRead.includes(UserType.Agent)) {
    const unreadAgentEventsIds = yield select(getChatUnreadAgentEventsIds, threadId);
    unreadEventsIds = unreadEventsIds.concat(unreadAgentEventsIds);
  }

  let unreadEvents: ChatEventEntity[] = yield all(
    unreadEventsIds.map((eventId) => select(getThreadEvent, threadId, eventId)),
  );

  if (upToTimestampInMs) {
    unreadEvents = unreadEvents.filter((e) => e.timestampInMs <= upToTimestampInMs);
  }

  if (unreadEvents.length > 0) {
    const eventMarkedAsSeen = unreadEvents.map((event) => ({ ...event, status: ChatEventStatus.Read }));

    yield put(ChatsEntitiesActions.updateEvents({ events: toKeyMap(eventMarkedAsSeen, 'id'), threadId }));
  }
}

function* assignChatToOtherAgent(action: IActionWithPayload<string, IAssignChatToOtherAgentPayload>): SagaIterator {
  const { threadId } = action.payload;

  const isOnChats = yield select(getIsOnSection, Section.Chats, false);
  const selectedThreadId = yield select(getSelectedThreadId);
  if (isOnChats && selectedThreadId === threadId) {
    yield put(ChatsViewActions.navigateToSelectedOrFirstAvailableChat({}));

    const nextThreadId = yield select(getFirstAvailableChatThreadId);

    if (nextThreadId) {
      yield put(ChatsViewActions.navigateToChat({ threadId: nextThreadId, shouldReplace: true }));
    }
  }

  yield put(ChatsViewActions.unmarkChatThreadIdsAsNew({ threadId }));
}

function* handleSectionChange(action: IActionWithPayload<string, { route: Section }>): SagaIterator {
  const { route } = action.payload;

  const wasOnChats = yield select(getIsPreviousSection, Section.Chats, false);

  if (route !== Section.Chats && wasOnChats) {
    // hide the tooltip when leaving the Chats section
    yield put(TooltipsActions.hideTooltip({ tooltip: TooltipType.ChatCannedResponses }));
  }
}

function* removeChatThread(action: IActionWithPayload<string, IRemoveChatThreadPayload>): SagaIterator {
  const { threadId: removedThreadId } = action.payload;
  const threadType = yield select(getThreadType, removedThreadId);

  yield put(ChatsViewActions.removeDraftMessage({ threadId: removedThreadId }));
  yield put(ChatsViewActions.removePrivateMode({ threadId: removedThreadId }));
  yield put(ChatsViewActions.unmarkChatThreadIdsAsNew({ threadId: removedThreadId }));
  yield put(ChatsViewActions.clearScrollPosition({ threadId: removedThreadId }));

  // unfollow every removed thread
  const chatId: string = yield select(getChatIdByThreadId, removedThreadId);
  if (chatId) {
    chatFollowManager.unfollowChat(chatId);
  }

  yield put(ChatsEntitiesActions.removeChatThreadSuccess({ threadId: removedThreadId }));
  yield put(TrafficActions.customerThreadStatusChange());

  /**
   * If removed thread is unassigned, check if we need to fetch for more unassigned chats
   */
  if (threadType === ChatType.Unassigned) {
    const unassignedChatsNextPageId = yield select(getUnassingedChatsNextPageId);
    const unnasignedChatsIds: string[] = yield select(getUnassignedIds);
    const shouldFetchAdditionalUnassignedChat =
      unassignedChatsNextPageId && unnasignedChatsIds.length < UNASSIGNED_CHATS_PAGE_SIZE;

    if (shouldFetchAdditionalUnassignedChat) {
      yield put(ChatsEntitiesActions.fetchAdditionalUnassignedChatsSummary({ limit: UNASSIGNED_CHATS_PAGE_SIZE }));
    }
  }
}

/**
 * Starts (or navigates to) a chat from Archives perspective based on archived thread id.
 * Following scenarios are handled:
 * 1. Related thread is active. Navigate to it.
 * 2. Chat requires activation
 * 2a. Activation successful, navigate to new thread.
 * 2b. Activation unsuccessful, chat already active. Show `supervise` modal.
 * 2c. Activation unsuccessful, chat is in disallowed group.
 * Similar functionality of `startChat` is also used in `views/archives/sagas.ts`
 */
function* startChat(action: IActionWithPayload<string, IStartChatPayload>): SagaIterator {
  const { threadId } = action.payload;

  const closedThread: ChatThreadEntity = yield select(getThread, threadId);
  const relatedOngoingChat: ChatThreadEntity = yield select(getOngoingActiveThreadInChat, threadId);
  const customerId = yield select(getCurrentThreadCustomerId, threadId);
  const customerIsAwaitingNavigation = yield select(getIsCustomerMarkedAsAwaitingNavigation, customerId);

  if (customerIsAwaitingNavigation) {
    return;
  }

  // 1. Related new thread is ongoing. Navigate to it.
  if (relatedOngoingChat) {
    trackEvent(ChatEvent.NewChatThreadViewed, EventPlace.Chats);
    navigate(createChatPath(relatedOngoingChat.chatId, relatedOngoingChat.threadId));

    return;
  }

  yield put(ChatsViewActions.markCustomerAsAwaitingNavigation({ customerId }));

  // 2. Chat requires activation.
  const { result, error }: AgentChatApiResponse<ResumeChatResponse> = yield call(chatsClient.resumeChat, {
    chat: {
      id: closedThread.chatId,
      access: {
        group_ids: closedThread.groupIds.map(Number),
      },
    },
    continuous: true,
  });

  // 2a. Activation successful, navigate to new thread.
  if (result) {
    const newThreadId = result.thread_id;

    navigate(createChatPath(closedThread.chatId, newThreadId));

    yield put(ChatsEntitiesActions.fetchChatHistory({ threadId: newThreadId }));
    trackEvent(ChatEvent.ChatReopened, EventPlace.Chats);

    yield put(ChatsEntitiesActions.removeChatThread({ threadId, omitTags: true }));
  }

  if (error) {
    const normalizedError = normalizeError(error);
    const { type, message } = normalizedError;

    // 2b. Activation unsuccessful, chat already active. Show `supervise` modal.
    if (type === AgentChatApiErrorType.VALIDATION && message?.includes(APIErrorMessage.ChatAlreadyActive)) {
      yield put(
        GlobalModalActions.showModal(GlobalModal.SuperviseChat, {
          source: SectionName.Chats,
          threadId,
          shouldRemoveThread: true,
        }),
      );
    }

    // 2c. Activation unsuccessful, chat is in disallowed group.
    if (type === AgentChatApiErrorType.MISSING_ACCESS) {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.CANT_START_CHAT_INACCESSIBLE),
          kind: ToastVariant.Warning,
        }),
      );
    }
  }

  yield put(ChatsViewActions.clearAwaitingNavigation());
}

function* superviseChat({ payload }: IActionWithPayload<string, ISuperviseChatPayload>): SagaIterator {
  const { threadId } = payload;
  const thread: ChatThreadEntity = yield select(getThread, threadId);
  if (thread) {
    const isExistingCustomer = yield select(customerExists, thread.customerId);
    if (isExistingCustomer) {
      yield put(ChatsViewActions.markCustomerAsAwaitingNavigation({ customerId: thread.customerId }));
      yield put(ChatsEntitiesActions.supervise({ chatId: thread.chatId }));
    }
  }
}

function* takeoverChat({ payload }: IActionWithPayload<string, ITakeoverChatPayload>): SagaIterator {
  const { threadId, agentLogin } = payload;

  yield put(ChatsViewActions.transferChat({ threadId, agentLogin, groupId: null, groupStatus: null }));
}

function* sendTranscript({ payload }: IActionWithPayload<string, ISendTranscriptPayload>): SagaIterator {
  const { chatId, threadId, customerEmail, shouldIncludeAllDetails } = payload;

  yield put(
    TranscriptFeatureActions.sendTranscript(chatId, threadId, customerEmail, shouldIncludeAllDetails, EventPlace.Chats),
  );
}

function* handleSortUnassignedChats(selectedThreadId: string): SagaIterator {
  const unpinnedUnassignedChatsCount = yield select(getUnpinnedUnassignedChatsCount);
  const limit = Math.min(DEFAULT_FETCH_ALL_LIMIT, unpinnedUnassignedChatsCount || UNASSIGNED_CHATS_PAGE_SIZE);

  yield put(ChatsEntitiesActions.fetchAdditionalUnassignedChatsSummary({ shouldReplace: true, limit }));
  yield race({
    chatsSummarySuccess: take(ChatsEntitiesActionNames.FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_SUCCESS),
    fallback: delay(FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_FALLBACK_TIME),
  });

  const thread: ChatThreadEntity = yield select(getThread, selectedThreadId);

  if (!thread) {
    yield put(ChatsViewActions.navigateToSelectedOrFirstAvailableChat({}));
  }
}

function* setChatsSortOrder({ payload }: IActionWithPayload<string, ISetChatsSortOrderPayload>): SagaIterator {
  const { chatType, sortType } = payload;

  const selectedThreadId: string = yield select(getSelectedThreadId);
  switch (chatType) {
    case ChatType.Unassigned: {
      yield fork(handleSortUnassignedChats, selectedThreadId);
      break;
    }
    case ChatType.My:
    case ChatType.Queued:
    case ChatType.Supervised: {
      yield fork(updateChatsListOrder, chatType, MAPPED_CHAT_TYPE_TO_COMPARATOR[chatType]);
      break;
    }
    default:
      break;
  }

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [PersistedChatsSortType[chatType]]: sortType,
    }),
  );
}

export function* chatsViewSaga(): SagaIterator {
  yield takeEvery(ChatsViewActionsNames.SELECT_CHAT_FROM_ROUTE, selectChatFromRoute);
  yield takeEvery(ChatsViewActionsNames.NAVIGATE_TO_CHAT, navigateToChat);
  yield takeEvery(
    ChatsViewActionsNames.NAVIGATE_TO_SELECTED_OR_FIRST_AVAILABLE_CHAT,
    navigateToSelectedOrFirstAvailableChat,
  );
  yield takeEvery(ChatsViewActionsNames.NAVIGATE_TO_NEXT_AVAILABLE_CHAT, navigateToNextAvailableChat);
  yield takeEvery(ChatsViewActionsNames.FETCH_TAGS, fetchTags);
  yield takeEvery(ChatsViewActionsNames.SET_CHAT_TAG_REQUEST, setTag);
  yield takeEvery(ChatsViewActionsNames.UNSET_CHAT_TAG_REQUEST, unsetTag);
  yield takeEvery(ChatsEntitiesActionNames.SET_TAG_SUCCESS, setChatTagSuccess);
  yield takeEvery(ChatsEntitiesActionNames.UNSET_TAG_SUCCESS, unsetChatTagSuccess);
  yield takeEvery(ChatsViewActionsNames.CLOSE_AND_ARCHIVE_CHAT, closeAndArchiveChat);
  yield takeEvery(ChatsViewActionsNames.CLOSE_CHAT, closeChat);
  yield takeEvery(ChatsEntitiesActionNames.INCOMING_CHAT_THREAD, detectAgentChatAction);
  yield takeEvery(ChatsEntitiesActionNames.START_SUPERVISING_SUCCESS, detectSuperviseNavigation);
  yield takeEvery(ChatsViewActionsNames.ASSIGN_CHAT, assignChat);
  yield takeEvery(ChatsViewActionsNames.PICK_FROM_QUEUE, pickFromQueue);
  yield takeEvery(ChatsViewActionsNames.STOP_SUPERVISING, stopSupervising);
  yield takeEvery(ChatsEntitiesActionNames.CHAT_PICKED_BY_OTHER_AGENT, handleChatPickedByOtherAgent);
  yield takeEvery(ChatsViewActionsNames.SELECT_CHAT, selectChat);
  yield takeEvery(ChatsViewActionsNames.SET_SELECTED_ID, setSelectedThreadId);
  yield takeEvery(ChatsViewActionsNames.SELECT_NEXT_CHAT, selectNextChat);
  yield takeEvery(ChatsViewActionsNames.SELECT_PREVIOUS_CHAT, selectPreviousChat);
  yield takeEvery(ChatsEntitiesActionNames.START_SUPERVISING_FAILURE, startSupervisingFailure);
  yield takeEvery(ChatsViewActionsNames.PERFORM_CHAT_ACTION, performChatAction);
  yield takeEvery(ChatsViewActionsNames.CREATE_TICKET, createTicket);
  yield takeEvery(ChatsViewActionsNames.TRANSFER_CHAT, transferChat);
  yield takeEvery(ChatsViewActionsNames.TRANSFER_CHAT_WITH_OPTIONS, transferChatWithOptions);
  yield takeEvery(TICKET.CREATE[RequestAction.SUCCESS], createTicketSuccess);
  yield takeEvery(TICKET.CREATE[RequestAction.FAILURE], createTicketFailure);
  yield takeEvery(ChatsViewActionsNames.SEND_MESSAGE, sendMessage);
  yield takeEvery(ChatsViewActionsNames.SET_TYPING_INDICATOR, setTypingIndicator);
  yield takeEvery(ChatsViewActionsNames.SET_AGENT_MESSAGE_SNEAK_PEEK, setAgentMessageSneakPeek);
  yield takeEvery(ChatsViewActionsNames.MARK_EVENTS_AS_SEEN, markEventsAsSeen);
  yield takeEvery(ChatsViewActionsNames.MARK_EVENTS_AS_READ, markEventsAsRead);
  yield takeEvery(ChatsEntitiesActionNames.ASSIGN_CHAT_TO_OTHER_AGENT, assignChatToOtherAgent);
  yield takeEvery(RoutingActionsEnum.SET_CURRENT_SECTION, handleSectionChange);
  yield takeEvery(ChatsEntitiesActionNames.REMOVE_CHAT_THREAD, removeChatThread);
  yield takeEvery(ChatsViewActionsNames.START_CHAT, startChat);
  yield takeEvery(ChatsViewActionsNames.SUPERVISE_CHAT, superviseChat);
  yield takeEvery(ChatsViewActionsNames.TAKEOVER_CHAT, takeoverChat);
  yield takeEvery(ChatsViewActionsNames.SEND_TRANSCRIPT, sendTranscript);
  yield takeLatest(ChatsViewActionsNames.SET_CHATS_SORT_ORDER, setChatsSortOrder);
  yield fork(replySuggestionsSaga);
  yield fork(textEnhancementsSaga);
}
