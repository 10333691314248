import { type StartupData } from 'entries/base/interfaces/startup-data';
import { anyToBoolean } from 'helpers/boolean';
import { getConfig } from 'helpers/config';
import { getFirstChatDate } from 'services/activity/website-last-activity';
import { updateFirstChatDate } from 'services/chat-widget';

import { initializeExternalApi } from './initialize-external-api';
import { initializeQueryClient } from './initialize-query-client';
import { initializeServer } from './initialize-server';
import { initializeStore } from './initialize-store';

export function initializeApp(startupData: StartupData): void {
  initializeServer({
    compressWebsocket: anyToBoolean(startupData.licenseProperties[getConfig().accountsClientId]?.compress_websocket),
  });
  initializeQueryClient(startupData.licenseProperties);
  initializeStore(startupData);
  initializeExternalApi();

  updateFirstChatDate(getFirstChatDate());
}
