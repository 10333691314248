/**
 * @deprecated Use `RoutingStatus` type instead.
 */
export enum RoutingStatus {
  AcceptingChats = 'accepting_chats',
  NotAcceptingChats = 'not_accepting_chats',
  Offline = 'offline',
}

interface IWithAgendId {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  agent_id: string;
}

export interface IRoutingStatusSetPushEvent extends IWithAgendId {
  status: RoutingStatus;
}

export enum AgentDisconnectedReason {
  AccessTokenExpired = 'access_token_expired',
  AccessTokenRevoked = 'access_token_revoked',
  AgentDeleted = 'agent_deleted',
  AgentDisconnectedByServer = 'agent_disconnected_by_server',
  AgentLoggedOutRemotely = 'agent_logged_out_remotely',
  ConnectionEvicted = 'connection_evicted',
  ConnectionTimeout = 'connection_timeout',
  InternalError = 'internal_error',
  LicenseExpired = 'license_expired',
  LicenseNotFound = 'license_not_found',
  LicenseVersionChanged = 'license_version_changed',
  LoggedOutRemotely = 'logged_out_remotely',
  MisdirectedConnection = 'misdirected_connection',
  MisdirectedRequest = 'misdirected_request',
  PingTimeout = 'ping_timeout',
  ServiceTemporarilyUnavailable = 'service_temporarily_unavailable',
  TooManyConnections = 'too_many_connections',
  TooManyUnauthorizedConnections = 'too_many_unauthorized_connections',
  UnsupportedVersion = 'unsupported_version',
  RolePermissionChanged = 'role_permissions_changed',
}

type IAgentDisconnectedPushEventPayload<
  Reason = Exclude<
    AgentDisconnectedReason,
    AgentDisconnectedReason.MisdirectedConnection | AgentDisconnectedReason.MisdirectedRequest
  >,
  Payload = unknown
> = {
  reason: Reason;
} & Payload;

export type IAgentDisconnectedMisdirectedConnectionReasonPushEvent = IAgentDisconnectedPushEventPayload<
  AgentDisconnectedReason.MisdirectedConnection,
  { data: { region: string } }
>;

type IAgentDisconnectedMisdirectedRequestReasonPushEvent = IAgentDisconnectedPushEventPayload<
  AgentDisconnectedReason.MisdirectedRequest,
  { data: { region: string } }
>;

export type IAgentDisconnectedPushEvent =
  | IAgentDisconnectedPushEventPayload
  | IAgentDisconnectedMisdirectedConnectionReasonPushEvent
  | IAgentDisconnectedMisdirectedRequestReasonPushEvent;
