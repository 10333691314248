import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { getServer } from 'helpers/get-server';
import { type IServer } from 'interfaces/server';
import { tryToLogUserSession } from 'services/socket-activity-logger';

import { type IServerConnectOptions } from './interfaces';
import { login } from './login';

const log = debug(DebugLogsNamespace.AppServerConnection);

function connectToServer(server: IServer, options: IServerConnectOptions): Promise<void> {
  return new Promise((resolve, reject) => {
    const connectHandler = (): void => {
      log('Connected to server');
      server.off('connect', connectHandler);
      resolve();
    };

    if (!options.isGhost) {
      server.on('connect', connectHandler);
    }

    try {
      // must connect even in ghost mode because it sets the flag sever.isGhost()
      server.connect(options);
    } catch (error) {
      reject(error);
    }

    if (options.isGhost) {
      resolve();
    }
  });
}

export async function connectAndLogin(options: IServerConnectOptions): Promise<void> {
  const { isGhost, accessToken } = options;
  const server: IServer = getServer();

  log('Connecting to server');
  await connectToServer(server, options);

  if (isGhost) {
    log('Logging in as ghost');
    tryToLogUserSession();

    return;
  }

  log('Logging in');
  await login(server, accessToken);
}
