import { useDispatch, useSelector } from 'react-redux';

import { ExternallyResolvablePromise } from 'helpers/externally-resolvable-promise';
import { ApplicationsActions } from 'store/entities/applications/actions';
import {
  applicationsSelector,
  getApplicationsRequestError,
  getIsFetchingApplications,
  hasFailedApplications,
  hasFetchedApplications,
  hasInitializedApplications,
  type IAppsMap,
} from 'store/entities/applications/selectors';

import { useFetchFeatures } from './api/feature-control/use-fetch-features';

const dataPromise = new ExternallyResolvablePromise<IAppsMap>();

interface UseSuspendableApplicationsFetch {
  applications: IAppsMap;
  hasInitialized: boolean;
  areFetched: boolean;
  error: string | null;
  hasFetchFailed: boolean;
  promise: ExternallyResolvablePromise<IAppsMap>;

  fetch: () => IAppsMap;
}

/**
 * @description A hook that fetches applications data and suspends the rendering of its child components until the necessary data is fully loaded. This component leverages the React Suspense mechanism to ensure that data is available before rendering.
 */
export const useSuspendableApplicationsFetch = (): UseSuspendableApplicationsFetch => {
  const dispatch = useDispatch();
  const hasInitialized = useSelector(hasInitializedApplications);
  const areFetched = useSelector(hasFetchedApplications);
  const isFetching = useSelector(getIsFetchingApplications);
  const hasFetchFailed = useSelector(hasFailedApplications);
  const error = useSelector(getApplicationsRequestError);
  const applications = useSelector(applicationsSelector);
  const { isFetched: featuresAreFetched } = useFetchFeatures();

  const fetch = (): IAppsMap => {
    if (isFetching || !featuresAreFetched) {
      throw dataPromise.promise;
    }
    if (areFetched) {
      return applications;
    }
    if (hasFetchFailed) {
      throw error;
    }
    if (!areFetched) {
      dispatch(ApplicationsActions.fetchCollection({}));
      throw dataPromise.promise;
    }

    return applications;
  };

  return {
    applications,
    hasInitialized,
    areFetched,
    error,
    hasFetchFailed,
    promise: dataPromise,
    fetch,
  };
};
