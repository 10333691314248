/* eslint-disable @typescript-eslint/naming-convention */
import { fetchEventSource } from '@microsoft/fetch-event-source';

import { getConfig } from 'helpers/config';
import { getAccessToken, getRegion } from 'services/auth/auth-storage-manager';

import { type CopilotStreamEvent, type StreamMessagePayload } from './types';

const getHeaders = (): Record<string, string> => {
  const headers: Record<string, string> = {
    Accept: 'text/event-stream',
    'Content-Type': 'application/json',
  };

  const credentials = getAccessToken();
  if (credentials) {
    headers['Authorization'] = `Bearer ${credentials}`;
  }

  // TODO: probably we can remove this
  const region = getRegion();
  if (region) {
    headers['X-Region'] = region;
  }

  return headers;
};

const createCopilotStreamClient = () => {
  const baseUrl = getConfig().aiAgentsApiUrl;

  return {
    sendMessage: ({ handlers: { onMessage, onClose, onOpen, onError }, payload, signal }: StreamMessagePayload) =>
      fetchEventSource(`${baseUrl}/chat/stream-response`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
        signal,

        // eslint-disable-next-line @typescript-eslint/require-await
        onopen: async (res): Promise<void> => {
          if (res.ok && res.status === 200) {
            onOpen?.();
          } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
            onError?.(new Error(`Client side error during opening connection. Status: ${res.status}.`));
          }
        },
        onmessage(event) {
          const data = event?.data;

          if (!data) {
            return;
          }

          try {
            const parsedData = JSON.parse(data) as CopilotStreamEvent;

            if (!parsedData?.type) {
              return;
            }

            onMessage?.(parsedData);
          } catch (error) {
            onError?.('Failed to parse message');
          }
        },
        onclose() {
          onClose?.();
        },
        onerror(err: unknown) {
          onError?.(err);
          throw err;
        },
      }),
  };
};

export const streamCopilotClient = createCopilotStreamClient();
