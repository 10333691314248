import { App } from 'config/setup';
import { type StartupDataResultFailure } from 'entries/base/interfaces/startup-data';
import { AppStateProvider } from 'services/app-state-provider';
import { handleStartupError } from 'services/server/handle-startup-error';
import { initializeQueryClient } from 'services/startup/initialize/initialize-query-client';
import { initializeStore } from 'services/startup/initialize/initialize-store';
import { SessionActions } from 'store/features/session/actions';

import { initializeBase } from './initialize-base';

export async function initializeWithError(errorResult: StartupDataResultFailure): Promise<void> {
  const { failReason: error, startupData } = errorResult;

  initializeBase();
  App.preInit?.();

  if (startupData) {
    initializeQueryClient(startupData.licenseProperties);
    initializeStore(startupData);
  }

  AppStateProvider.dispatch(SessionActions.updateRights());

  await handleStartupError(error);
}
