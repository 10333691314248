import { FeatureNames } from 'services/api/feature-control/interfaces';

import { getIsFeatureEnabled } from './feature-flags';
import { isStagingEnvironment } from './feature-toggle';

export const getIsCopilotStreamingEnabled = (): boolean => {
  if (isStagingEnvironment()) {
    return true;
  }

  return getIsFeatureEnabled(FeatureNames.CopilotStreaming);
};
