import { createElement } from 'react';

import { render } from 'react-dom';

import { GlobalModals } from 'components/global-modals/GlobalModals';

export function renderGlobalModals(): void {
  const div = document.createElement('div');

  document.body.appendChild(div);

  render(createElement(GlobalModals), div);
}
