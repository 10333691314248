import { type Breadcrumb, type AppsViewState } from './interfaces';

export interface WithAppsViewState {
  views: {
    apps: AppsViewState;
  };
}

export const getBreadcrumbs = (state: WithAppsViewState): Breadcrumb[] => state.views.apps.breadcrumbs;

export const getCurrentMarketplaceRoute = (state: WithAppsViewState): string | null =>
  state.views.apps.currentMarketplaceRoute;
