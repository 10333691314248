// @ts-strict-ignore
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { type ComponentType, Component, type MouseEvent } from 'react';

import { ControlledFilter } from 'components/filter/ControlledFilter';
import { FilterButton } from 'components/filter/FilterButton';
import { type DateRangeFilter } from 'constants/filters/date-range-filter';
import { Filter, FilterOperator } from 'constants/filters/filter';
import { type EventPlace } from 'helpers/analytics';
import type { FilterValues } from 'interfaces/filter';

import type { FilterConfig, FilterLabelValue } from './interfaces';
import { SelectedFilterLabel } from './SelectedFilterLabel';

function isFilterValuesEmpty(filter: Filter, filterValues: FilterValues): boolean {
  switch (filter) {
    case Filter.DateRange:
    case Filter.CustomerSince:
    case Filter.LastContacted: {
      const dateRangeFilterValues = filterValues as DateRangeFilter;

      return !dateRangeFilterValues?.from || !dateRangeFilterValues?.to;
    }
  }

  return filterValues === null;
}

function getActionbuttonText(isEmpty: boolean, manualUpdate: boolean): string {
  if (!manualUpdate) {
    return isEmpty ? 'Close' : 'Done';
  }

  return isEmpty ? 'Close' : 'Apply';
}

export interface IFilterBaseProps {
  key: string;
  filterConfig: FilterConfig;
  isOpen: boolean;
  eventPlace?: EventPlace;
  filterName: Filter;
  filterValues: FilterValues;
  filterOperator?: FilterOperator;
  filterCompareValues?: FilterValues;
  filteringTypes?: string[];
  filterLabelValues: FilterLabelValue[] | null;
  onClose(): void;
  onChange(filter: { name: string; value: FilterValues }): void;
  onRemove(filterName: Filter): void;
  onOpen(filterName: Filter): void;
  onOperatorChange?(filterName: Filter, operator: FilterOperator): void;
}

export function withFilterBase<P>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WrappedComponent: ComponentType<P>
): ComponentType<P & IFilterBaseProps> {
  return class extends Component<P & IFilterBaseProps, unknown> {
    handleRemove = () => {
      this.props.onRemove(this.props.filterName);
      if (this.props.filterOperator) {
        this.handleOperatorChange(null);
      }
    };

    handleOpen = () => {
      this.props.onOpen(this.props.filterName);
    };

    handleOperatorChange = (operator: FilterOperator | null) => {
      if (this.props.onOperatorChange) {
        this.props.onOperatorChange(this.props.filterName, operator);
      }
    };

    handleFilterRemoveClick = (event: MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
      this.handleRemove();
    };

    handleOnActionButtonClick = () => {
      const { filterName, filterValues, onClose, onChange } = this.props;
      const { manualFilterUpdate } = this.props.filterConfig;
      const canTriggerChange = manualFilterUpdate && !isFilterValuesEmpty(filterName, filterValues);

      if (canTriggerChange) {
        onChange({ name: filterName, value: filterValues });
      }

      return onClose();
    };

    render() {
      const {
        title,
        innerTitle,
        icon,
        getFilterBoxClassName,
        getFilterButtonClassName,
        turnOffFilterKeyboardEventsOnShow,
        manualFilterUpdate,
        removable = true,
        withOperator,
        preview,
      } = this.props.filterConfig;

      const { filterName, filterValues, filterCompareValues, filterOperator, filterLabelValues } = this.props;

      const valueIsEmpty = isFilterValuesEmpty(filterName, filterValues);

      return (
        <div>
          <ControlledFilter
            title={title}
            innerTitle={innerTitle}
            icon={icon}
            isOpen={this.props.isOpen}
            isValid
            withOperator={withOperator}
            filterOperator={filterOperator}
            filterName={filterName}
            filterValues={filterValues}
            preview={preview}
            onOperatorChange={this.handleOperatorChange}
            boxClassName={getFilterBoxClassName && getFilterBoxClassName(filterValues, filterCompareValues)}
            buttonClassName={getFilterButtonClassName && getFilterButtonClassName(filterValues, filterCompareValues)}
            actionButtonText={getActionbuttonText(valueIsEmpty, manualFilterUpdate)}
            onActionButtonClick={this.handleOnActionButtonClick}
            onClose={this.props.onClose}
            onOpen={this.handleOpen}
            turnOffKeyboardEventsOnShow={turnOffFilterKeyboardEventsOnShow}
            renderContent={({ onToggleFilterKeyboardEvents }) =>
              this.props.isOpen && (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <WrappedComponent onToggleFilterKeyboardEvents={onToggleFilterKeyboardEvents} {...this.props} />
              )
            }
          >
            <FilterButton
              title={title}
              icon={icon}
              removable={removable}
              selectedValuesLabel={
                filterLabelValues && (
                  <SelectedFilterLabel
                    value={filterLabelValues}
                    operator={withOperator ? filterOperator : FilterOperator.Or}
                  />
                )
              }
              onRemove={this.handleFilterRemoveClick}
            />
          </ControlledFilter>
        </div>
      );
    }
  };
}
