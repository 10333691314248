import { createSelector } from 'reselect';

// eslint-disable-next-line import/no-restricted-paths
import { templatesToReplace } from 'routes/settings/engagement/greetings/single-greeting/templates-list/constants';
import { getWidgetsWithPlacement, type IWithApplicationsState } from 'store/entities/applications/selectors';
import {
  createHasFetchedSelector,
  createRequestFetchingSelector,
  createRequestStartedSelector,
} from 'store/requests/selectors';

import { type IApplicationWidget } from '../applications/interfaces';

import { ONE_TIME_CAMPAIGNS_LIMIT } from './constants';
import { doesTemplateHaveAtLeastOneOptionalDefinitionField } from './helpers/selectors';
import { type IGreeting, type IGreetingsEntityState, type ITemplate } from './interfaces';

export interface IWithGreetingsState {
  entities: {
    greetings: IGreetingsEntityState;
  };
}

export const getIsSavingData = (state: IWithGreetingsState): boolean => {
  return state.entities.greetings.isSavingData;
};

export function greetingsSelector(state: IWithGreetingsState): { [key: string]: IGreeting } {
  return state.entities.greetings.greetings;
}

export const getGreetings: (state: IWithGreetingsState) => IGreeting[] = createSelector(
  greetingsSelector,
  (greetings): IGreeting[] => Object.keys(greetings).map((id): IGreeting => greetings[id])
);

export const getActiveGreetingsCount = createSelector(
  getGreetings,
  (greetings) => greetings.filter((greeting) => greeting.isActive).length
);

export const getGreeting = (state: IWithGreetingsState, greetingId: string): IGreeting => {
  const greetings = greetingsSelector(state);

  return greetings[greetingId] || null;
};

const requestsList = ['FETCH_COLLECTION_GREETING'];

export const areGreetingsLoaded = createHasFetchedSelector(requestsList);

export function areGreetingsLoading(state): boolean {
  const greetingsInitialFetchHasStarted = createRequestStartedSelector(requestsList)(state);
  const greetingsAreBeingFetched = createRequestFetchingSelector(requestsList)(state);

  if (!greetingsInitialFetchHasStarted) {
    return true;
  }

  return greetingsAreBeingFetched;
}

export const getTemplatesFromWidgets = createSelector(
  (state: IWithApplicationsState) => getWidgetsWithPlacement(state, 'targeted-message'),
  (widgets: IApplicationWidget[]) =>
    widgets
      .map(({ shortcut, id, appId }) => {
        const initialState = shortcut?.initialState;

        if (!initialState) {
          return [];
        }

        const templates = JSON.parse(decodeURIComponent(initialState)) as ITemplate[];

        return templates
          .map((template) => {
            const templateId = btoa(`${appId}.${id}`);

            return {
              ...template,
              id: templateId,
              defaultTemplateIdToRemove: templatesToReplace[templateId],
            };
          })
          .filter(doesTemplateHaveAtLeastOneOptionalDefinitionField);
      })
      .flat()
);

export const getOneTimeCampaings = createSelector(getGreetings, (greetings) =>
  greetings.filter(({ subtype }) => subtype === 'announcement')
);

export const getHasReachedOneTimeCampaignsLimit = createSelector(
  getOneTimeCampaings,
  (oneTimeCampaigns) => oneTimeCampaigns.length >= ONE_TIME_CAMPAIGNS_LIMIT
);
