import { type SentimentFilter } from 'constants/filters/sentiment-filter';
import { type SurveyType } from 'constants/filters/survey-type-filter';
import { type Last7DaysMode } from 'constants/reports/last-7-days-mode';
import { type IReportViewDetails, ReportType, reportViewsDetails } from 'constants/reports/report-type';
import { type UpgradeSource } from 'constants/reports/upgrade-source';
import { SortOrder } from 'constants/sort-order';
import { capitalizeFirstLetter } from 'helpers/string';
import {
  SHOW_SENTIMENT_QUERY_PARAM_NAME,
  SHOW_SENTIMENT_QUERY_PARAM_VALUE,
} from 'routes/reports/chats/ChatTopics/constants';
import { type AmplitudeEventProperties } from 'services/amplitude';
import { browserHistory } from 'services/browser-history';
import { trackEvent } from 'services/event-tracking';

import { EventPlace } from './analytics';
import { booleanToNumericString } from './boolean';

const enum ReportsEvent {
  AgentProfileClicked = 'Agent profile clicked',
  AgentsPerformanceAgentProfileClicked = 'Agents Performance agent profile clicked',
  AgentsPerformanceCellValueClicked = 'Agents Performance cell value clicked',
  AgentsPerformanceSummaryClicked = 'Agents Performance summary clicked',
  BenchmarkSwitchChanged = 'Benchmark switch changed',
  BetaTooltipOpened = 'Beta tooltip opened',
  BreakdownColumnSorted = 'Breakdown column sorted',
  ChartLegendClicked = 'Chart legend clicked',
  ChatSurveyAnswerClicked = 'Chat survey answer clicked',
  ChatSurveyReportEdit = 'Chat survey report edit',
  ChatSurveyReportToggled = 'Chat survey report toggled',
  ChatTopicsBreakdownLinkClicked = 'Chat topics breakdown link clicked',
  ChatTopicsShowSentimentToggled = 'Chat topics show sentiment toggled',
  ChatWithUsClicked = 'Chat with us clicked',
  DashboardOpened = 'Dashboard opened',
  DataPointClicked = 'Data point clicked',
  EmptyStateClicked = 'Empty state clicked',
  EmptyStateViewed = 'Empty state viewed',
  GreetingsBreakdownChatsCountClicked = 'Campaigns Breakdown chats count clicked',
  GreetingsBreakdownCheckboxClicked = 'Campaigns Breakdown checkbox clicked',
  GreetingsBreakdownColumnSorted = 'Campaigns Breakdown column sorted',
  GreetingsBreakdownEditButtonClicked = 'Campaigns Breakdown edit button clicked',
  GreetingsBreakdownRedButtonLinkClicked = 'Campaigns Breakdown red button link clicked',
  HideInactiveAgentsToggled = 'Hide inactive agents toggled',
  HoveredOnHeatmap = 'Hovered on heatmap',
  InappOpened = 'Inapp opened',
  InfoButtonHoveredOver = 'Info button hovered over',
  Last7DaysEmptyStateClicked = 'Last 7 days empty state clicked',
  Last7DaysSummaryReportClicked = 'Last 7 days summary report clicked',
  MessageFollowupFailedToSend = 'Message followup failed to send',
  MessageFollowupSent = 'Message followup sent',
  MessageModalOpened = 'Message modal opened',
  MoreInfoOpened = 'More info opened',
  RatingRankingClicked = 'Rating ranking clicked',
  ReportCategoryOpened = 'Report category opened',
  ReportExported = 'Report exported',
  ReportFiltered = 'Report filtered',
  ReportSavedAsCSV = 'Report saved as CSV',
  ScheduleReportCTAButtonClicked = 'Schedule report CTA button clicked',
  ScheduledReportButtonClicked = 'Scheduled report button clicked',
  SwitchedBetweenDays = 'Switched between days',
  TagsUsageBreakdownCheckboxClicked = 'Tags usage Breakdown checkbox clicked',
  TagsUsageBreakdownColumnSorted = 'Tags usage Breakdown column sorted',
  TagsUsageBreakdownCountClicked = 'Tags usage Breakdown count clicked',
  TooltipCTAClicked = 'Tooltip CTA clicked',
  TwentyFourHourDistributionToggled = '24-hour distribution toggled',
  UpgradeButtonClicked = 'Upgrade button clicked',
  UpsellingViewed = 'Up-selling viewed',
}

export function trackDistributionChange(wasDisabled: boolean, reportName: string | ReportType): void {
  trackEvent(ReportsEvent.TwentyFourHourDistributionToggled, EventPlace.Reports, {
    state: wasDisabled ? 'enabled' : 'disabled',
    report: reportName,
  });
}

export function trackEmptyStateClicked(emptyStateType: string): void {
  trackEvent(ReportsEvent.EmptyStateClicked, EventPlace.Reports, {
    'empty state': emptyStateType,
  });
}

export function trackDatapointClicked(chartName: string): void {
  trackEvent(ReportsEvent.DataPointClicked, EventPlace.Reports, {
    type: chartName,
  });
}

export function trackSaveAsCSV(chartName: string): void {
  trackEvent(ReportsEvent.ReportSavedAsCSV, EventPlace.Reports, {
    report: chartName,
  });
}

export function trackReportExported(dataSource: string, type: string, dateRange: string, format: string): void {
  trackEvent(ReportsEvent.ReportExported, EventPlace.Reports, {
    'data source': dataSource,
    type,
    'date-range': dateRange,
    format,
  });
}

export function trackReportAccessed(
  categoryName: string,
  reportName: string,
  benchmarkState: boolean | string,
  source: string | null = null,
  additionalProps?: AmplitudeEventProperties
): void {
  trackEvent(`${capitalizeFirstLetter(categoryName)} report accessed`, EventPlace.Reports, {
    report: reportName,
    category: categoryName,
    benchmarkState,
    ...(source && { source }),
    ...additionalProps,
  });
}

export function trackLast7DaysSummaryReportClicked(reportName: string | ReportType, mode?: Last7DaysMode): void {
  trackEvent(ReportsEvent.Last7DaysSummaryReportClicked, EventPlace.Reports, {
    report: reportName,
    presentedData: mode,
  });
}

export function trackLast7DaysEmptyStateClicked(reportName: string | ReportType): void {
  trackEvent(ReportsEvent.Last7DaysEmptyStateClicked, EventPlace.Reports, {
    report: reportName,
  });
}

export function trackUpgradeButtonClicked(reportName: string | ReportType, source: UpgradeSource): void {
  trackEvent(ReportsEvent.UpgradeButtonClicked, EventPlace.Reports, {
    report: reportName,
    source,
  });
}

export function trackSurveyAnswerClicked(surveyType: SurveyType): void {
  trackEvent(ReportsEvent.ChatSurveyAnswerClicked, EventPlace.Reports, {
    'survey type': surveyType,
  });
}

export function trackAgentProfileClicked(reportName: string | ReportType): void {
  trackEvent(ReportsEvent.AgentProfileClicked, EventPlace.Reports, {
    report: reportName,
  });
}

export function trackScheduleReportClick(reportName: string | ReportType): void {
  trackEvent(ReportsEvent.ScheduleReportCTAButtonClicked, EventPlace.Reports, {
    report: reportName,
  });
}

export function trackScheduledReportAction(actionName: string): void {
  trackEvent(ReportsEvent.ScheduledReportButtonClicked, EventPlace.Reports, { action: actionName });
}

export function trackRatingRankingClicked(reportName: string | ReportType, rate: string | ReportType): void {
  trackEvent(ReportsEvent.RatingRankingClicked, EventPlace.Reports, {
    report: reportName,
    rate,
  });
}

export function trackDashboardOpened(): void {
  trackEvent(ReportsEvent.DashboardOpened, EventPlace.Reports);
}

export function trackFiltersChange(
  reportName: string,
  filterType: string,
  concurrent: number,
  comparison?: string,
  filterParam: { [key: string]: string } = {}
): void {
  trackEvent(ReportsEvent.ReportFiltered, EventPlace.Reports, {
    report: reportName,
    'filter type': filterType,
    ...filterParam,
    'concurrent filters': concurrent,
    ...(comparison
      ? {
          comparison: 'true',
          'comparison type': comparison,
        }
      : {
          comparison: 'false',
        }),
  });
}

export function trackChartLegendClick(chartName: string, legend: string): void {
  trackEvent(ReportsEvent.ChartLegendClicked, EventPlace.Reports, {
    'chart name': chartName,
    legend,
  });
}

export function getCurrentReportTrackingDetails(reportViewId: string): IReportViewDetails[string] | false {
  return reportViewsDetails[reportViewId] || false;
}

export function trackUpsellViewDisplayed(report: string): void {
  trackEvent(ReportsEvent.UpsellingViewed, EventPlace.Reports, {
    report,
  });
}

function getTargetByPathname(href: string): string {
  if (href.startsWith('https://www.livechat.com/help')) {
    return 'KB';
  }

  if (href.startsWith('https://www.livechat.com/app')) {
    return 'Applications page';
  }

  if (href.startsWith('https://www.livechat.com/marketplace')) {
    return 'Marketplace';
  }

  if (href.startsWith('https://www.livechat.com/resources')) {
    return 'Resources';
  }

  if (/\/tickets\/add/.test(href)) {
    return 'New ticket';
  }

  if (/\/settings\//.test(href)) {
    return 'Settings';
  }

  return href;
}

export function trackTooltipCTAClicked(report: string, href: string): void {
  trackEvent(ReportsEvent.TooltipCTAClicked, EventPlace.Reports, {
    report,
    target: getTargetByPathname(href),
  });
}

export function trackChatSurveyEditClicked(): void {
  trackEvent(ReportsEvent.ChatSurveyReportEdit, EventPlace.Reports);
}

export function trackChatSurveyToggled(): void {
  trackEvent(ReportsEvent.ChatSurveyReportToggled, EventPlace.Reports);
}

export function trackInactiveAgentsToggled(enabled: boolean): void {
  trackEvent(ReportsEvent.HideInactiveAgentsToggled, EventPlace.Reports, {
    'target state': enabled ? 'enabled' : 'disabled',
  });
}

export function trackDaySwitchClicked(direction: string): void {
  trackEvent(ReportsEvent.SwitchedBetweenDays, EventPlace.Reports, {
    direction,
  });
}

export function trackInfoButtonHovered(chartName: string, iconName?: string): void {
  trackEvent(ReportsEvent.InfoButtonHoveredOver, EventPlace.Reports, {
    report: chartName,
    icon: iconName || null,
  });
}

export function trackHoveredHeatmap(heatmapName: string): void {
  trackEvent(ReportsEvent.HoveredOnHeatmap, EventPlace.Reports, {
    report: capitalizeFirstLetter(heatmapName),
  });
}

export function moreInfoOpened(report: string): void {
  trackEvent(ReportsEvent.MoreInfoOpened, EventPlace.Reports, {
    report: capitalizeFirstLetter(report),
  });
}

export function messageModalOpened(report: string): void {
  trackEvent(ReportsEvent.MessageModalOpened, EventPlace.Reports, {
    report: capitalizeFirstLetter(report),
  });
}

export function trackInappOpened(report: string): void {
  trackEvent(ReportsEvent.InappOpened, EventPlace.Reports, {
    report,
  });
}

export function messageModalEdited(report: string, field: string): void {
  trackEvent(`${field} edited`, EventPlace.Reports, {
    report: capitalizeFirstLetter(report),
  });
}

export function messageFollowupSentSuccess(report: string): void {
  trackEvent(ReportsEvent.MessageFollowupSent, EventPlace.Reports, {
    report: capitalizeFirstLetter(report),
  });
}

export function messageFollowupSentFailure(report: string): void {
  trackEvent(ReportsEvent.MessageFollowupFailedToSend, EventPlace.Reports, {
    report: capitalizeFirstLetter(report),
  });
}

export function benchmarkTogglerClicked(benchmarkState: boolean, reportType: ReportType): void {
  trackEvent(ReportsEvent.BenchmarkSwitchChanged, EventPlace.Reports, {
    benchmarkState,
    reportType,
  });
}

export function trackGreetingsBreakdownChecked(checked: boolean, concurrent: number): void {
  trackEvent(ReportsEvent.GreetingsBreakdownCheckboxClicked, EventPlace.Reports, {
    checked: booleanToNumericString(checked),
    concurrent,
  });
}

export function trackGreetingsBreakdownChatsCountClicked(): void {
  trackEvent(ReportsEvent.GreetingsBreakdownChatsCountClicked, EventPlace.Reports);
}

export function trackGreetingsBreakdownEditButtonClicked(hasAlert: boolean): void {
  trackEvent(ReportsEvent.GreetingsBreakdownEditButtonClicked, EventPlace.Reports, {
    hasAlert: booleanToNumericString(hasAlert),
  });
}

export function trackGreetingsBreakdownRedPointLinkClicked(): void {
  trackEvent(ReportsEvent.GreetingsBreakdownRedButtonLinkClicked, EventPlace.Reports);
}

export function trackGreetingsBreakdownSorted(columnName: string, desc: boolean): void {
  trackEvent(ReportsEvent.GreetingsBreakdownColumnSorted, EventPlace.Reports, {
    'column name': columnName,
    sort: desc ? SortOrder.Desc : SortOrder.Asc,
  });
}

export function trackTagsUsageBreakdownChecked(checked: boolean, concurrent: number): void {
  trackEvent(ReportsEvent.TagsUsageBreakdownCheckboxClicked, EventPlace.Reports, {
    checked: booleanToNumericString(checked),
    concurrent,
  });
}

export function trackTagsUsageBreakdownSorted(columnName: string, desc: boolean): void {
  trackEvent(ReportsEvent.TagsUsageBreakdownColumnSorted, EventPlace.Reports, {
    'column name': columnName,
    sort: desc ? SortOrder.Desc : SortOrder.Asc,
  });
}

export function trackTagsUsageBreakdownCountClicked(): void {
  trackEvent(ReportsEvent.TagsUsageBreakdownCountClicked, EventPlace.Reports);
}

export function trackReportBreakdownSorted(columnName: string, desc: boolean, reportType: ReportType): void {
  trackEvent(ReportsEvent.BreakdownColumnSorted, EventPlace.Reports, {
    reportType,
    'column name': columnName,
    sort: desc ? SortOrder.Desc : SortOrder.Asc,
  });
}

export function trackAgentsPerformanceAgentProfileClick(): void {
  trackEvent(ReportsEvent.AgentsPerformanceAgentProfileClicked, EventPlace.Reports);
}

export function trackAgentsPerformanceCellValueClicked(columnName: string, link?: string): void {
  trackEvent(ReportsEvent.AgentsPerformanceCellValueClicked, EventPlace.Reports, {
    'column name': columnName,
    ...(link && { link }),
  });
}

export function trackAgentsPerformanceSummaryClicked(metric: string): void {
  trackEvent(ReportsEvent.AgentsPerformanceSummaryClicked, EventPlace.Reports, {
    'metric name': metric,
  });
}

export function trackChatWithUsClicked(reportName: string): void {
  trackEvent(ReportsEvent.ChatWithUsClicked, EventPlace.Reports, { report: reportName });
}

export function trackBetaTooltipOpened(reportName: string): void {
  trackEvent(ReportsEvent.BetaTooltipOpened, EventPlace.Reports, { report: reportName });
}

export function getAdditionalReportTrackingProps(reportId: string): AmplitudeEventProperties | undefined {
  if (reportId === ReportType.ChatTopics) {
    const showSentimentParamValue = browserHistory.queryParams[SHOW_SENTIMENT_QUERY_PARAM_NAME];

    return {
      showSentiment: showSentimentParamValue === SHOW_SENTIMENT_QUERY_PARAM_VALUE ? 'on' : 'off',
    };
  }
}

export function trackChatTopicsBreakdownLinkClicked(topic: string, sentiment: SentimentFilter | null): void {
  trackEvent(ReportsEvent.ChatTopicsBreakdownLinkClicked, EventPlace.Reports, {
    topic,
    ...(sentiment && { sentiment }),
  });
}

export function trackShowSentimentToggled(isSentimentShown: boolean): void {
  trackEvent(ReportsEvent.ChatTopicsShowSentimentToggled, EventPlace.Reports, {
    state: isSentimentShown ? 'enabled' : 'disabled',
  });
}

export function trackEmptyStateViewed(reportType: ReportType): void {
  trackEvent(ReportsEvent.EmptyStateViewed, EventPlace.Reports, {
    report: reportType,
  });
}
