// @ts-strict-ignore
import type { ITooltipProps } from '@livechat/design-system-react-components';

import type { NavigationTooltipType } from 'constants/navigation';

import type { INavigationItem, INavigationItemBadge, INavigationItemTootip, INavigationViewState } from './interfaces';

export interface IWithNavigationViewState {
  views: {
    navigation: INavigationViewState;
  };
}

export interface INavigationTooltipConfig {
  type: NavigationTooltipType;
  isVisible: boolean;
  kind: ITooltipProps['kind'];
}

function getNavigationItem(state: IWithNavigationViewState, itemId: string): INavigationItem {
  return state.views.navigation.items[itemId];
}

export function getNavigationItemTooltip(state: IWithNavigationViewState, itemId: string): INavigationItemTootip {
  const navigationItem = getNavigationItem(state, itemId);

  return navigationItem?.tooltip ? navigationItem.tooltip : null;
}

export function isNavigationItemTooltipShown(
  state: IWithNavigationViewState,
  itemId: string,
  type: NavigationTooltipType | null = null,
): boolean {
  const tooltip = getNavigationItemTooltip(state, itemId);

  if (!tooltip?.show) {
    return false;
  } else if (type !== null) {
    return type === tooltip.type;
  }

  return true;
}

export function getNavigationItemBadge(state: IWithNavigationViewState, itemId: string): INavigationItemBadge {
  const item = getNavigationItem(state, itemId);

  return (item && item.badge) || null;
}

export function getHasVisitedMarketplace(state: IWithNavigationViewState): boolean {
  return state.views.navigation.marketplaceVisited;
}
