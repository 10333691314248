import { type FC } from 'react';

import { SuspenseWrapper } from 'components/suspense-wrapper/SuspenseWrapper';
import { useEffectOnce } from 'hooks/use-effect-once';

import { RouteView } from './RouteView';

export interface IRouteView {
  title?: string;
}

export const NoNavigationRouteView: FC<IRouteView> = ({ title, children }) => {
  useEffectOnce(() => {
    document.body.classList.add('hide-nav');

    return () => {
      document.body.classList.remove('hide-nav');
    };
  });

  return (
    <SuspenseWrapper>
      <RouteView title={title}>{children}</RouteView>
    </SuspenseWrapper>
  );
};
