// @ts-strict-ignore

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { countries } from 'constants/recurly-countries';
import { RecurlyCoupon } from 'constants/recurly-coupons';
import { type IRecurlyError, RecurlyErrorType, RecurlyFieldType, type IMappedRecurlyError } from 'interfaces/recurly';

const covidCoupons: string[] = [RecurlyCoupon.CovidBusiness];
const blackFridayCoupons: string[] = [
  RecurlyCoupon.BlackFridayLC2021,
  RecurlyCoupon.BlackFridayPPLC2021,
  RecurlyCoupon.BlackFridayPPLCALL2021,
  RecurlyCoupon.BlackFridayLC2022,
];

const requiredErrors = {
  [RecurlyFieldType.CreditCard]: 'Please enter valid card details',
  [RecurlyFieldType.CreditCardNumber]: 'Please enter a valid card number',
  [RecurlyFieldType.CVV]: 'Please enter a valid security code',
  [RecurlyFieldType.ExpirationDate]: 'Please enter a valid expiration date',
  [RecurlyFieldType.FirstName]: 'First name is required',
  [RecurlyFieldType.LastName]: 'Last name is required',
  [RecurlyFieldType.PostalCode]: 'Zip / postal code is required',
  [RecurlyFieldType.Country]: 'Country is required',
};

const apiErrorsFieldsNames = {
  [RecurlyFieldType.CreditCard]: 'Credit card',
  [RecurlyFieldType.CreditCardNumber]: 'Number',
  [RecurlyFieldType.CVV]: 'Cvv',
  [RecurlyFieldType.Month]: 'Month',
  [RecurlyFieldType.Year]: 'Year',
  [RecurlyFieldType.FirstName]: 'First name',
  [RecurlyFieldType.LastName]: 'Last name',
  [RecurlyFieldType.PostalCode]: 'Postal code',
  [RecurlyFieldType.Company]: 'Company',
  [RecurlyFieldType.VatNumber]: 'VAT number',
  [RecurlyFieldType.Address]: 'Address1',
  [RecurlyFieldType.City]: 'City',
  [RecurlyFieldType.State]: 'State',
};

function mapStringifiedRecurlyError(error: string): IMappedRecurlyError {
  if (!error) return null;

  const parsedError = JSON.parse(error);
  const preparedError = {};

  if (parsedError[RecurlyErrorType.Recurly]) {
    preparedError[RecurlyErrorType.Recurly] = parsedError[RecurlyErrorType.Recurly]
      .split('/')
      .slice(-1)[0]
      .slice(0, -1);

    return preparedError;
  }

  preparedError[RecurlyErrorType.Recurly] = [];

  return preparedError;
}

function mapRegularRecurlyError(error: IRecurlyError): IMappedRecurlyError {
  if (!error) return null;

  const preparedError = {};

  // validation errors
  if (error.code === RecurlyErrorType.Validation) {
    error.fields.forEach((field) => {
      if ([RecurlyFieldType.Month, RecurlyFieldType.Year].includes(field)) {
        preparedError[RecurlyFieldType.ExpirationDate] = requiredErrors[RecurlyFieldType.ExpirationDate];
      } else if (requiredErrors[field]) {
        preparedError[field] = requiredErrors[field];
      }
    });
  }

  // api errors
  if ([RecurlyErrorType.ApiError, RecurlyErrorType.InvalidParameter].includes(error.code)) {
    const errorMessages = error.message.split(/, and |, | and /);
    Object.values(RecurlyFieldType).forEach((recurlyFieldType) => {
      errorMessages.forEach((errorMessage) => {
        if (errorMessage.startsWith(apiErrorsFieldsNames[recurlyFieldType])) {
          preparedError[recurlyFieldType] = errorMessage;
        }
      });
    });

    // merge all errors for date fields
    [RecurlyFieldType.CreditCardNumber, RecurlyFieldType.Month, RecurlyFieldType.Year, RecurlyFieldType.CVV].forEach(
      (field) => {
        if (preparedError[field]) {
          preparedError[RecurlyFieldType.CreditCard] = preparedError[RecurlyFieldType.CreditCard]
            ? `${preparedError[RecurlyFieldType.CreditCard]} and ${preparedError[field]}`
            : preparedError[field];

          delete preparedError[field];
        }
      }
    );
  }

  return preparedError;
}

export function mapRecurlyError(error: any): IMappedRecurlyError {
  return typeof error === 'string' ? mapStringifiedRecurlyError(error) : mapRegularRecurlyError(error);
}

export const getCountryCode = (country: string): string => {
  const foundCountry = countries.find((countryElement) => countryElement.name === country);

  return foundCountry ? foundCountry.countryCode : '';
};

export const isCovidCoupon = (couponName: string): boolean => {
  return !!couponName && covidCoupons.includes(couponName);
};

export const isBlackFridayCoupon = (couponName: string): boolean => {
  return !!couponName && blackFridayCoupons.includes(couponName);
};
