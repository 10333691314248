// @ts-strict-ignore
import { EnvironmentType } from 'constants/environment';
import { type IArchive } from 'interfaces/entities/archive';

import { getEnvironment } from './feature-toggle';

export enum ChatChannel {
  AgentApp = 'agent-app',
  ChatWidget = 'chat-widget',
  LiveChatLite = 'livechat-lite',
  LiveChatMobileLegacy = 'livechat-mobile-legacy',
  FacebookMessenger = 'messenger',
  WhatsAppBusiness = 'whatsapp-business',
  Instagram = 'instagram',
  Telegram = 'telegram',
  Line = 'line',
  Viber = 'viber',
}

type GetEnvironmentReturnType = ReturnType<typeof getEnvironment>;

export type ChatChannelClientId = keyof typeof channelIdsPerEnvironment[GetEnvironmentReturnType];

export const AGENT_CHAT_CHANNELS = [ChatChannel.AgentApp, ChatChannel.LiveChatLite, ChatChannel.LiveChatMobileLegacy];

/* eslint-disable @typescript-eslint/naming-convention */
const channelIdsPerEnvironment: Record<EnvironmentType, Record<string, ChatChannel>> = {
  [EnvironmentType.Labs]: {
    '58737b5829e65621a45d598aa6f2ed8e': ChatChannel.AgentApp,
    c6e4f62e2a2dab12531235b12c5a2a6b: ChatChannel.ChatWidget,
    '3b7d77130a861d66018cb1e30f4c801b': ChatChannel.LiveChatLite,
    '2d9b50cfc32bf95d90fb9b8c8e3c67e0': ChatChannel.LiveChatMobileLegacy,
    '057ec9764ffa8852fa9270e1f0abc48d': ChatChannel.FacebookMessenger,
    ee631a7ff555f8b6e354705201860c81: ChatChannel.WhatsAppBusiness,
  },
  [EnvironmentType.Staging]: {
    bb9e5b2f1ab480e4a715977b7b1b4279: ChatChannel.AgentApp,
    c5e4f61e1a6c3b1521b541bc5c5a2ac5: ChatChannel.ChatWidget,
    c85439c4e0c1927e69c317d300c610aa: ChatChannel.LiveChatLite,
    fee5ed9c3f11459cbd5768c7f98ecb18: ChatChannel.LiveChatMobileLegacy,
    '0e655920289dbafb0376a0f4491372d9': ChatChannel.FacebookMessenger,
    ee631a7ff555f8b6e354705201860c81: ChatChannel.WhatsAppBusiness,
  },
  [EnvironmentType.Production]: {
    bb9e5b2f1ab480e4a715977b7b1b4279: ChatChannel.AgentApp,
    c5e4f61e1a6c3b1521b541bc5c5a2ac5: ChatChannel.ChatWidget,
    c85439c4e0c1927e69c317d300c610aa: ChatChannel.LiveChatLite,
    fee5ed9c3f11459cbd5768c7f98ecb18: ChatChannel.LiveChatMobileLegacy,
    '0e655920289dbafb0376a0f4491372d9': ChatChannel.FacebookMessenger,
    ee631a7ff555f8b6e354705201860c81: ChatChannel.WhatsAppBusiness,
    e598b0031d95aeb7180fce9d7090eddc: ChatChannel.Instagram,
    '618cd4034d7f68c21c505db150712465': ChatChannel.Telegram,
    '6643decd0609fe067517c2faf2f06f80': ChatChannel.Line,
    fc99c0e3ca698b352563804377779821: ChatChannel.Viber,
  },
};
/* eslint-enable @typescript-eslint/naming-convention */

export function getChatChannelByClientId(clientId: string): ChatChannel {
  const ignoreEnvironmentOverride = true;
  const environment = getEnvironment(ignoreEnvironmentOverride);

  return channelIdsPerEnvironment[environment][clientId];
}

export function getClientIdByChatChannel(channel: ChatChannel): string {
  const ignoreEnvironmentOverride = true;
  const environment = getEnvironment(ignoreEnvironmentOverride);

  return Object.keys(channelIdsPerEnvironment[environment]).find(
    (clientId) => channelIdsPerEnvironment[environment][clientId] === channel
  );
}

// @TODO: Changes are expected in the api, in the case of changes, update methods or even remove - BE may will return channel itself
export const getChatChannelForArchive = (archive: IArchive): ChatChannel => {
  if (!archive.customVariables || archive.customVariables.length === 0) {
    return ChatChannel.ChatWidget;
  }

  const isAnyFacebookVariable = archive.customVariables
    .map((customVariable) => customVariable.key.toLowerCase())
    .some((customVariable) => customVariable.startsWith('facebook'));

  return isAnyFacebookVariable ? ChatChannel.FacebookMessenger : ChatChannel.ChatWidget;
};

export const getChatChannel = (archive: IArchive): ChatChannel => {
  if (archive && archive.integrations) {
    return ChatChannel.FacebookMessenger;
  }

  return ChatChannel.ChatWidget;
};
