// @ts-strict-ignore
import { type SyntheticEvent } from 'react';

import { Cookie } from 'constants/cookies';
import { EventNames } from 'constants/event-bus-events';
import { GlobalModal } from 'constants/global-modal';
import { getConfig } from 'helpers/config';
import { removeCookie } from 'helpers/cookies';
import { redirectTo } from 'helpers/redirect-to';
import { redirectToAccounts } from 'helpers/redirect-to-accounts';
import { getRootDomain } from 'helpers/url';
import { IdentitySource, type IApiSession, type ISession } from 'interfaces/session';
import { ApiManager } from 'services/api/api-manager';
import { hasAnyActiveChat } from 'store/entities/chats/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';

import { AppStateProvider } from './app-state-provider';
import { clearCredentials } from './auth/auth-storage-manager';
import { EventBus } from './event-bus';

function removeSessionCookies(): void {
  clearCredentials();
  removeCookie('region');
}

/*
  Remove cookies set to root domain, because they are not removed by removeSessionCookies.
  This remove the side effects made by https://github.com/livechat/my.livechatinc.com/pull/18701
*/
function removeSessionCookiesSetToRootDomain(): void {
  const domain = getRootDomain();
  removeCookie(Cookie.AccessToken, domain);
  removeCookie(Cookie.Scopes, domain);
}

function deserializeIdentitySource(identitySource: string): IdentitySource {
  switch (identitySource) {
    case 'explicit':
      return IdentitySource.Explicit;
    case 'google':
      return IdentitySource.GoogleOauth;
    case 'token':
      return IdentitySource.DeprecatedLC2Token;
    default:
      return IdentitySource.Credentials;
  }
}

export function deserializeSession(session: IApiSession): ISession {
  return {
    sessionId: session.session_id,
    isCurrent: session.current,
    identitySource: deserializeIdentitySource(session.identity_source),
    userAgent: session.user_agent,
    ip: session.ip,
  };
}

async function getSessions(): Promise<ISession[]> {
  const api = ApiManager.sessionApi;
  const { result } = await api.fetchAll();

  return (result || []).map(deserializeSession);
}

export async function removeSession(sessionId: string): Promise<void> {
  const api = ApiManager.sessionApi;
  await api.deleteSingle(sessionId);
}

export async function removeAllSessions(): Promise<void> {
  const { sessionApi } = ApiManager;
  await sessionApi.deleteAll();
}

async function removeCurrentSession(): Promise<void> {
  const sessions = await getSessions();
  const currentSession = sessions.find((session) => session.isCurrent);
  if (currentSession) {
    await removeSession(currentSession.sessionId);
  }
}

export async function removeAllSessionsAndRedirectOut(): Promise<void> {
  await removeAllSessions();
  redirectToAccounts();
}

export async function removeSessionAndRedirectOut(): Promise<void> {
  await removeCurrentSession();
  redirectToAccounts();
}

export async function removeSessionAndRedirectToAccounts(): Promise<void> {
  await removeCurrentSession();
  redirectTo(`${getConfig().accounts}`);
}

export function endSession(): void {
  removeSessionCookies();
  removeSessionCookiesSetToRootDomain();
}

export async function logout(e?: SyntheticEvent): Promise<void> {
  if (e) {
    e.preventDefault();
  }

  const sessions = await getSessions();
  const otherSessionsList = sessions.filter(
    (session) => !session.isCurrent && session.identitySource !== IdentitySource.Explicit
  );

  if (!otherSessionsList.length) {
    // user is not logged in on other web app instances
    if (AppStateProvider.selectFromStore(hasAnyActiveChat)) {
      if (!window.confirm('You still have some pending chats.\n\nLog out anyway?')) {
        return;
      }
    }

    EventBus.emit(EventNames.UserLoggedOut, { manual: true });

    return;
  }

  AppStateProvider.dispatch(GlobalModalActions.showModal(GlobalModal.Logout));
}
