import { css } from '@emotion/css';
import { RadiusToken } from '@livechat/design-system-react-components';

export const fullscreenAppIconStyles = css`
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;

export const navigationItemStyles = css`
  position: relative;
  list-style: none;
`;

export const badgeContainerStyles = css`
  position: absolute;
  top: 0;
  right: -1px;
  z-index: 10;
`;

export const contentWrapper = (active: boolean): string => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: var(${RadiusToken['3']});

  span {
    opacity: 0.7;
  }

  span,
  div {
    opacity: ${active ? '1' : ''};
  }
`;

export const homeIconWrapper = css`
  padding: 8px 12px;

  svg {
    width: 28px;
    height: 28px;
  }
`;

export const imageWrapper = css`
  height: 24px;
`;
