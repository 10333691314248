// @ts-strict-ignore
import { BusinessModel } from 'constants/business-model';
import { type StartupData } from 'entries/base/interfaces/startup-data';
import { calculateHasWebsiteLastActivity } from 'services/activity/helpers/calculate-has-website-last-activity';
import { calculateLastActivityInDays } from 'services/activity/helpers/calculate-last-activity-in-days';
import { AppStateProvider } from 'services/app-state-provider';
import {
  BillingProperty,
  CoreProperty,
  PlatformNamespace,
  StatisticsProperty,
} from 'services/connectivity/configuration-api/properties/constants';
import { AccountsActions } from 'store/entities/accounts/actions';
import { AgentActions } from 'store/entities/agents/actions';
import { AgentBotActions } from 'store/entities/bots/actions';
import { GroupActions } from 'store/entities/groups/actions';
import { IntegrationLicensePropertiesActions } from 'store/entities/integration-license-properties/actions';
import { type IFetchLicensePropertiesSuccessPayload } from 'store/entities/integration-license-properties/interfaces';
import { CodeInstallationActions } from 'store/features/code-installation/actions';
import { SessionActions } from 'store/features/session/actions';

import { deserializeCriticalData } from './deserialize-critical-data';

export function initializeStore(startupData: StartupData): void {
  const {
    licenseProperties,
    organizationId,
    groups,
    agents,
    bots,
    createdAt,
    expiresAt,
    licenseType,
    licenseId,
    email,
  } = deserializeCriticalData(startupData);

  AppStateProvider.dispatch(
    IntegrationLicensePropertiesActions.fetchSuccess(
      licenseProperties as never as IFetchLicensePropertiesSuccessPayload,
    ),
  );
  AppStateProvider.dispatch(AccountsActions.setCurrentOrganizationId(organizationId));

  AppStateProvider.dispatch(AgentActions.agentsFetched(agents));
  AppStateProvider.dispatch(AgentBotActions.botsFetched(bots));
  AppStateProvider.dispatch(GroupActions.groupsReset(groups));

  AppStateProvider.dispatch(
    SessionActions.saveAuthenticationCredentials({
      login: email,
    }),
  );

  const websiteLastActivity = licenseProperties[PlatformNamespace.STATISTICS][StatisticsProperty.WebsiteLastActivity];
  const hasActivity = calculateHasWebsiteLastActivity(websiteLastActivity);
  const daysSinceLastActivity = hasActivity ? calculateLastActivityInDays(websiteLastActivity) : undefined;

  AppStateProvider.dispatch(
    CodeInstallationActions.parseLicenseInfo({
      daysSinceLastActivity,
    }),
  );

  const partnerType = licenseProperties[PlatformNamespace.CORE][CoreProperty.PartnerType];
  const plan = licenseProperties[PlatformNamespace.BILLING][BillingProperty.SalesPlan];
  const businessModel =
    licenseProperties[PlatformNamespace.BILLING][BillingProperty.PaymentBusinessModel] || BusinessModel.PayPerSeat;
  const type = licenseType;

  AppStateProvider.dispatch(
    SessionActions.saveCurrentLicense({
      createdAt,
      expiresAt,
      licenseId: parseInt(licenseId, 10), // todo: pass string instead of number
      type,
      plan,
      partnerType,
      businessModel,
    }),
  );
}
