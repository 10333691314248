import { DesignToken } from '@livechat/design-system-react-components';
import Highcharts from 'highcharts';

import { anyToBoolean } from 'helpers/boolean';
import { getItem, LocalStorageKey } from 'services/local-storage';

Highcharts.theme = {
  chart: {
    backgroundColor: `var(${DesignToken.Background02})`,
  },
  subtitle: {
    style: {
      color: `var(${DesignToken.ContentBasicSecondary})`,
    },
  },
  xAxis: {
    gridLineColor: `var(${DesignToken.BorderBasicSecondary})`,
    lineColor: `var(${DesignToken.BorderBasicSecondary})`,
    tickColor: `var(${DesignToken.BorderBasicSecondary})`,
    labels: {
      style: {
        color: `var(${DesignToken.ContentBasicSecondary})`,
      },
    },
    title: {
      style: {
        color: `var(${DesignToken.ContentBasicSecondary})`,
      },
    },
  },
  yAxis: {
    gridLineColor: `var(${DesignToken.BorderBasicSecondary})`,
    lineColor: `var(${DesignToken.BorderBasicSecondary})`,
    tickColor: `var(${DesignToken.BorderBasicSecondary})`,
    labels: {
      style: {
        color: `var(${DesignToken.ContentBasicSecondary})`,
      },
    },
    title: {
      style: {
        color: `var(${DesignToken.ContentBasicSecondary})`,
      },
    },
  },
  plotOptions: {
    area: {
      marker: {
        lineColor: `var(${DesignToken.ContentBasicPrimary})`,
      },
    },
  },
};

export function setHighchartTheme(): void {
  Highcharts.setOptions(Highcharts.theme);
}

function isCurrentThemeForcedOnAllSections(): boolean {
  return anyToBoolean(getItem(LocalStorageKey.DarkModeOnAllSections));
}

export function shouldApplySelectedTheme(pathname: string): boolean {
  const darkModeSections = ['/chats', '/home', '/traffic', '/engage/traffic', '/engage/goals'];

  return (
    darkModeSections.filter((darkModeSection) => pathname.startsWith(darkModeSection)).length > 0 ||
    isCurrentThemeForcedOnAllSections()
  );
}
