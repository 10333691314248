import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';

import { SUGGESTION_WIDTH } from './constants';

export const suggestionsWrapper = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${SUGGESTION_WIDTH}px, 1fr));
  grid-template-rows: repeat(auto-fill, 1fr);
  gap: var(${SpacingToken.Spacing1});
  justify-items: center;
  max-width: 820px;
  margin: 0 auto;

  @media (max-width: 1320px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const suggestion = (isOneDetailsOpened: boolean): string => css`
  width: ${SUGGESTION_WIDTH}px;
  padding: var(${SpacingToken.Spacing4}) var(${SpacingToken.Spacing5});
  border-radius: var(${RadiusToken.Radius4});
  background-color: var(${DesignToken.SurfaceSecondaryDefault});
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 1320px) {
    width: ${isOneDetailsOpened ? '90%' : `${SUGGESTION_WIDTH}px`};
  }

  @media (max-width: 445px) {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    background-color: var(${DesignToken.SurfaceSecondaryHover});
  }

  span {
    width: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: var(${DesignToken.ContentBasicPrimary});
    margin-bottom: var(${SpacingToken.Spacing05});
    background: var(${DesignToken.ContentBasicGradient01}, linear-gradient(254deg, #b99aff -0.38%, #68afff 94.44%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(${DesignToken.ContentBasicSecondary});

    @media (max-width: 445px) {
      display: none;
    }
  }
`;
