import { type IOutboxMessage, type INormalizedOutboxMessage } from './outbox-message';

export function createOutboxMessage<T extends IOutboxMessage = IOutboxMessage>(
  title: string,
  pluginId: string,
  data: any = null
): T {
  return { title, data, pluginId } as T;
}

export function normalizeOutboxMessage(message: IOutboxMessage): INormalizedOutboxMessage {
  return {
    event_name: message.title,
    event_data: message.data,
  };
}
