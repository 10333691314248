// @ts-strict-ignore
import type { FC, MouseEvent, ReactChild, ReactElement } from 'react';

import { cx } from '@emotion/css';
import { type ITooltipProps, Icon, Tooltip, type IconSource } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { Badge, type BadgeType } from 'components/badge/Badge';
import { type Section } from 'constants/section';
import type { NavigationItemBadgeComponent } from 'interfaces/components/navigation/navigation-item-badge';
import { getCurrentSection } from 'store/features/routing/selectors';
import { capitalizeFirstLetter } from 'helpers/string';

import * as newNavStyles from '../new-navigation/items/navigation-item/styles';

import { NavigationItemBadge } from './NavigationItemBadge';
import * as styles from './styles/navigation-item';

interface IProps {
  badgeContent?: ReactChild;
  badgeType?: BadgeType;
  badgeComponent?: NavigationItemBadgeComponent;
  independentBadge?: boolean;
  className?: string;
  icon?: IconSource;
  activeIcon?: IconSource;
  customIconUrl?: string;
  id?: string;
  testId?: string;
  name?: string;
  path?: string;
  trackEngagementTitle?: string;
  onItemClick(path: string, id: string, trackEngagementTitle?: string): void;
  getIsActive?(section: Section): boolean;
}

const getNavigationIcon = ({
  icon,
  activeIcon,
  active,
  customIconUrl,
  name,
  id,
}: Pick<IProps, 'icon' | 'activeIcon' | 'customIconUrl' | 'name' | 'id'> & { active: boolean }): ReactElement => {
  if (icon) {
    const isHomeIcon = id === 'home';

    return (
      <span className={cx({ [styles.homeIconWrapper]: isHomeIcon })}>
        <Icon source={active && activeIcon ? activeIcon : icon} size="large" />
      </span>
    );
  }

  if (customIconUrl) {
    return (
      <span className={styles.imageWrapper}>
        <img src={customIconUrl} alt={name} className={styles.fullscreenAppIconStyles} />
      </span>
    );
  }

  return null;
};

const tooltipHooksConfig: Pick<ITooltipProps, 'useClickHookProps' | 'useDismissHookProps'> = {
  useClickHookProps: {
    ignoreMouse: true,
  },
  useDismissHookProps: {
    referencePress: true,
    referencePressEvent: 'click',
  },
};

export const NavigationItem: FC<IProps> = (props) => {
  const {
    icon,
    activeIcon,
    badgeContent,
    badgeType,
    className,
    name,
    testId,
    path,
    id,
    badgeComponent = Badge,
    independentBadge = false,
    customIconUrl,
    onItemClick,
    trackEngagementTitle,
    getIsActive,
  } = props;
  const handleItemClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    onItemClick(path, id, trackEngagementTitle);
  };
  const section = useSelector(getCurrentSection);
  const active = getIsActive ? getIsActive(section) : id.startsWith(section);
  const badge = (
    <NavigationItemBadge
      id={id}
      independent={independentBadge}
      component={badgeComponent}
      type={badgeType}
      content={badgeContent}
      active={active}
    />
  );

  const item = (
    <a
      className={cx(newNavStyles.link, { [newNavStyles.active]: active })}
      aria-label={capitalizeFirstLetter(name || id)}
      href={path}
      onClick={handleItemClick}
      data-testid={testId}
    >
      <div className={styles.contentWrapper(active)}>
        {badge && <div className={styles.badgeContainerStyles}>{badge}</div>}
        {getNavigationIcon({ icon, activeIcon, active, customIconUrl, name, id })}
      </div>
    </a>
  );

  return (
    <li className={cx(styles.navigationItemStyles, className)}>
      {name ? (
        <Tooltip
          offsetMainAxis={12}
          hoverOnDelay={400}
          className={newNavStyles.itemTooltip}
          kind="invert"
          floatingStrategy="fixed"
          placement="right"
          triggerRenderer={item}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tooltipHooksConfig}
        >
          {name}
        </Tooltip>
      ) : (
        item
      )}
    </li>
  );
};
