import type { RequestResult } from 'interfaces/api/client';

import { BaseApi, PassVersionMode } from '../base-api';

import {
  type IInvitationLinkResponse,
  type ISingleRolePayload,
  type IRolesPayload,
  type IUploadAvatarPayload,
  type IOrganizationDTO,
} from './deserializer';
import {
  type ResendInvitationEmailPayload,
  type UploadAvatarRequest,
  type LoginMethods,
  type ProductData,
  type ListAuthorizationsResponse,
} from './interfaces';

export class AccountsApi extends BaseApi {
  protected readonly prefix = 'v2';
  protected readonly passVersionMode: PassVersionMode = PassVersionMode.None;

  /**
   * Re-send welcome email to the user once again, when he didn't manage to login into the app.
   * It's the same email sent when adding an account to the license but triggered manually.
   */
  resendInvitation = ({ accountId, ...requestBody }: ResendInvitationEmailPayload): Promise<RequestResult<unknown>> =>
    this.post(`accounts/${encodeURIComponent(accountId)}/send_welcome_email`, requestBody);

  resetPassword = (accountId: string, password: string): Promise<RequestResult<unknown>> =>
    this.post(`accounts/${encodeURIComponent(accountId)}/password/reset`, { password });

  remindPassword = (email: string): Promise<RequestResult<unknown>> => this.post('accounts/password/remind', { email });

  checkIfResetablePassword = (accountId: string): Promise<RequestResult<unknown>> =>
    this.get(`accounts/${encodeURIComponent(accountId)}/password/resetable`);

  getInviteToken = (): Promise<RequestResult<IInvitationLinkResponse>> => this.get('accounts/me/invitation_token');

  refreshInviteToken = (): Promise<RequestResult<IInvitationLinkResponse>> => this.post('accounts/me/invitation_token');

  getOrganizationInfo = (organizationId: string): Promise<RequestResult<IOrganizationDTO>> =>
    this.get(`organizations/${organizationId}`);

  getMyProducts = (): Promise<RequestResult<ProductData[]>> => this.get('organizations/my/products');

  getOrganizationRoles = (): Promise<RequestResult<IRolesPayload[]>> => this.get('roles');
  getOrganizationRole = (roleId: string): Promise<RequestResult<ISingleRolePayload>> => this.get(`roles/${roleId}`);

  getLoginMethods = (): Promise<RequestResult<LoginMethods>> => this.get('organizations/my/login_methods');

  uploadAvatar = ({
    accountId,
    organizationId,
    avatar,
    avatarUrl,
  }: UploadAvatarRequest): Promise<RequestResult<IUploadAvatarPayload>> =>
    this.patch(
      `accounts/${encodeURIComponent(accountId)}/organization_settings/${encodeURIComponent(organizationId)}`,
      avatarUrl ? { avatar_url: avatarUrl } : avatar,
    );

  getAppsAuthorizations = (): Promise<RequestResult<ListAuthorizationsResponse>> =>
    this.get('accounts/me/authorizations');
}
