import type { FC } from 'react';

import { cx } from '@emotion/css';
import { RefreshFilled } from '@livechat/design-system-icons';
import { Button, Icon, Text } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { getIsCopilotStreamingEnabled } from 'helpers/copilot-streaming';
import { trackEvent } from 'services/event-tracking';
import { CopilotEntitiesActions } from 'store/entities/copilot/actions';
import { getFailedCopilotMessage } from 'store/entities/copilot/selectors';

import { useCopilotMessageContext } from '../CopilotMessageContext';

import * as styles from './styles';

interface IProps {
  eventId: string;
}

export const CopilotErrorEvent: FC<IProps> = ({ eventId }) => {
  const dispatch = useDispatch();
  const { handleSendMessage } = useCopilotMessageContext();
  const failedCopilotMessage = useSelector(getFailedCopilotMessage);
  const isCopilotStreamingEnabled = getIsCopilotStreamingEnabled();

  const handleRetry = (): void => {
    if (isCopilotStreamingEnabled) {
      handleSendMessage(failedCopilotMessage);
    } else {
      dispatch(CopilotEntitiesActions.retryFailedCopilotMessage());
    }
    trackEvent(CopilotTrackEvent.ActionPerformed, EventPlace.One, { action: 'retry' });
  };

  return (
    <div className="bubble-wrapper" key={eventId}>
      <div className={styles.eventWrapper(false)}>
        <div className={cx('bubble', styles.bubble)}>
          <div className={styles.errorWrapper}>
            <Text>Sorry, I couldn&apos;t answer your question.</Text>
            <Button onClick={handleRetry} kind="link" icon={<Icon source={RefreshFilled} />} iconPosition="right">
              Retry
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
