import { useCallback } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { useDispatch } from 'react-redux';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { getIsCopilotStreamingEnabled } from 'helpers/copilot-streaming';
import { useListSuggestions } from 'hooks/use-list-suggestions';
import { trackEvent } from 'services/event-tracking';
import { CopilotEntitiesActions } from 'store/entities/copilot/actions';

import { useCopilotMessageContext } from '../CopilotMessageContext';
import { deserializeSuggestionWithShortcuts } from '../helpers';
import { tryMatchSuggestion } from '../message-box/custom-plugins/helpers';

type UseSendCopilotMessage = (eventProperties?: Record<string, unknown>) => void;

export const useSendCopilotMessage = (): UseSendCopilotMessage => {
  const [editor] = useLexicalComposerContext();
  const { data } = useListSuggestions();
  const dispatch = useDispatch();
  const isCopilotStreamingEnabled = getIsCopilotStreamingEnabled();

  const { handleSendMessage } = useCopilotMessageContext();

  const suggestions = deserializeSuggestionWithShortcuts(data);

  const onSend = useCallback(
    (eventProperties: Record<string, unknown> = {}) => {
      const currentMessageValue = editor.getRootElement()?.innerText;
      const currentMessageTrimmedValue = currentMessageValue?.trim();

      if (!currentMessageTrimmedValue) {
        return;
      }

      const matchedSuggestion = tryMatchSuggestion(suggestions, currentMessageValue);
      const messageToSend = matchedSuggestion ? matchedSuggestion : currentMessageTrimmedValue;

      if (matchedSuggestion) {
        eventProperties.commandPopover = true;
      }

      if (isCopilotStreamingEnabled) {
        handleSendMessage(messageToSend);
      } else {
        dispatch(CopilotEntitiesActions.sendCopilotMessage(messageToSend));
      }

      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      trackEvent(CopilotTrackEvent.MessageSent, EventPlace.One, eventProperties);
    },
    [editor, suggestions, handleSendMessage, isCopilotStreamingEnabled, dispatch],
  );

  return onSend;
};
