import { type FC } from 'react';

import { MainLayoutLegacy } from './main-layout/MainLayoutLegacy';
import { RouteView } from './RouteView';

export interface IRouteView {
  title?: string;
}

export const WithNavigationRouteView: FC<IRouteView> = ({ title, children }) => {
  return (
    <RouteView title={title}>
      <MainLayoutLegacy>{children}</MainLayoutLegacy>
    </RouteView>
  );
};
