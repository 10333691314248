import * as Sentry from '@sentry/browser';
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { ServerError } from 'constants/server-error';
import { redirectToAccountsSignout } from 'helpers/redirect-to-accounts';
import {
  isLicenseBlockedInCRM,
  isLicenseExpired,
  isRequestSuspended,
  isRequestUnauthorized,
  isRequestWaitingForApproval,
  type ApiError,
} from 'helpers/request-decoder';
import { type IMessageErrorPayload } from 'interfaces/incoming-message';
import { ApiErrorMessagePart } from 'services/api/constants';
import {
  ConfigurationApiErrorMessage,
  type ConfigurationApiNormalizedError,
} from 'services/connectivity/configuration-api/types';
import { type GlobalAccountsApiNormalizedError } from 'services/connectivity/global-accounts-api/types';

import { handleServerError } from './handle-server-error';

const log = debug(DebugLogsNamespace.AppServerConnection);

function mapConfigurationApiErrorToServerError(error: ConfigurationApiNormalizedError): ServerError | null {
  switch (error.message as ConfigurationApiErrorMessage) {
    case ConfigurationApiErrorMessage.AwaitingApproval:
      return ServerError.WaitingForApproval;
    case ConfigurationApiErrorMessage.UserNotActive:
      return ServerError.LicenseBlockedInCRM;
    case ConfigurationApiErrorMessage.AccountSuspended:
      return ServerError.AccountSuspended;
    default:
      break;
  }

  switch (error.type) {
    case 'too_many_requests':
      return ServerError.TooManyRequests;
    case 'authentication':
    case 'authorization':
      return ServerError.InvalidSession;
    case 'license_expired':
      return ServerError.LicenseExpired;
  }

  return null;
}

function mapAccountsApiErrorToServerError(error: GlobalAccountsApiNormalizedError): ServerError | null {
  switch (error.error) {
    case 'unauthorized_client':
    case 'unauthorized':
      return ServerError.InvalidSession;
  }

  return null;
}

function mapApiErrorToServerError(error: ApiError): ServerError | null {
  if (isLicenseBlockedInCRM(error)) {
    return ServerError.LicenseBlockedInCRM;
  }

  if (isLicenseExpired(error)) {
    return ServerError.LicenseExpired;
  }

  if (isRequestUnauthorized(error)) {
    return ServerError.InvalidSession;
  }

  if (isRequestSuspended(error)) {
    return ServerError.AccountSuspended;
  }

  if (isRequestWaitingForApproval(error)) {
    return ServerError.WaitingForApproval;
  }

  return null;
}

export async function handleConfigurationApiError(error: ConfigurationApiNormalizedError): Promise<void> {
  const serverError = mapConfigurationApiErrorToServerError(error) || ServerError.UnexpectedError;
  log('Received configuration api error', serverError);

  await handleServerError(serverError);
}

export async function handleAccountsApiError(error: GlobalAccountsApiNormalizedError): Promise<void> {
  const serverError = mapAccountsApiErrorToServerError(error) || ServerError.UnexpectedError;
  log('Received global accounts api error', serverError);

  await handleServerError(serverError);
}

export async function handleApiError(error: ApiError): Promise<void> {
  const serverError = mapApiErrorToServerError(error) || ServerError.UnexpectedError;
  log('Received api error', serverError);

  await handleServerError(serverError);
}

export async function handleLoginFailure(error: IMessageErrorPayload | Error): Promise<void> {
  log('Login failure', error);
  const payload = error as IMessageErrorPayload;

  switch (payload.error?.type) {
    case 'license_expired':
      await handleServerError(ServerError.LicenseExpired);

      return;

    case 'seats_limit_exceeded':
      await handleServerError(ServerError.TooManyAgents);

      return;

    case 'requester_awaiting_approval':
      await handleServerError(ServerError.WaitingForApproval);

      return;

    case 'requester_suspended':
      await handleServerError(ServerError.AccountSuspended);

      return;

    case 'authentication':
    case 'authorization':
      if (payload.error.message === (ApiErrorMessagePart.IpNotAllowed as string)) {
        await handleServerError(ServerError.IpNotAllowed);
      } else {
        redirectToAccountsSignout();
      }

      return;

    case 'too_many_requests':
      await handleServerError(ServerError.TooManyRequests);

      return;
  }

  Sentry.captureException(new Error(`lc3: login unknown error (payload=${JSON.stringify(error)})`));
  redirectToAccountsSignout();
}
