import { type FC } from 'react';

import { SideNavigationGroup, SideNavigation } from '@livechat/design-system-react-components';
import memoizeOne from 'memoize-one';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SettingsNavigationItemId } from 'constants/settings/navigation-item-id';
import { NavigationItems } from 'constants/settings/navigation-items';
import {
  updateCodeInstallation,
  displayOnOffBadge,
  displayTrackingCodeBadge,
  filterItemsByAutomateSectionAvailability,
  filterItemsByCustomCheck,
  filterItemsByEnvironment,
  filterItemsByExperiment,
  filterItemsByIntegration,
  filterItemsByRights,
} from 'helpers/side-navigation';
import { type ISideNavigationItem } from 'interfaces/side-navigation-item';
import { SettingsSideNavigationItem } from 'routes/settings/components/side-navigation/SettingsSideNavigationItem';
import { useFetchEmailsMapping } from 'routes/settings/hooks/use-fetch-emails-mapping';
import { isABCInstalled, isFacebookMessengerInstalled } from 'store/entities/applications/selectors';
import { getIsHelpDeskChannelOn } from 'store/entities/license-custom-properties/selectors';
import { getChannelsActivity, getIsCodeInstalled } from 'store/features/code-installation/selectors';
import { getCreationTimestamp } from 'store/features/session/selectors';
import { getEmailsMapping } from 'store/views/tickets/selectors';

interface IProps {
  items?: ISideNavigationItem[];
}

export const SettingsSideNavigation: FC<IProps> = () => {
  const { pathname } = useLocation();
  const isCodeInstalled = useSelector(getIsCodeInstalled);
  const isWebsiteCodeActive = useSelector(getChannelsActivity).website.isActive;
  const isFacebookIntegrated = useSelector(isFacebookMessengerInstalled);
  const isEmailConfigured = useSelector(getEmailsMapping).length > 0;
  const isABCConfigured = useSelector(isABCInstalled);
  const isHelpDeskChannelOn = useSelector(getIsHelpDeskChannelOn);
  const licenseCreationDate = useSelector(getCreationTimestamp);

  const calculateNavigationItems = memoizeOne(
    (
      isCodeInstalled,
      isWebsiteCodeActive,
      isFacebookIntegrated,
      isEmailConfigured,
      isABCConfigured,
      isHelpDeskChannelOn,
    ) => {
      // GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
      let items = filterItemsByRights(NavigationItems);

      items = filterItemsByEnvironment(items);
      items = filterItemsByExperiment(items);
      items = filterItemsByIntegration(items);
      items = filterItemsByAutomateSectionAvailability(items, licenseCreationDate || 0);

      items = displayOnOffBadge(items, SettingsNavigationItemId.Code, isWebsiteCodeActive);
      items = displayOnOffBadge(items, SettingsNavigationItemId.FacebookMessenger, isFacebookIntegrated);
      items = displayOnOffBadge(items, SettingsNavigationItemId.FacebookMessengerLC3, isFacebookIntegrated);
      items = displayOnOffBadge(items, SettingsNavigationItemId.Email, isEmailConfigured);
      items = displayOnOffBadge(items, SettingsNavigationItemId.AppleBusinessChat, isABCConfigured);
      items = displayOnOffBadge(items, SettingsNavigationItemId.HelpDesk, isHelpDeskChannelOn);
      items = updateCodeInstallation(items, isCodeInstalled);

      if (!isCodeInstalled) {
        items = displayTrackingCodeBadge(items);
      }

      return items;
    },
  );

  const items = filterItemsByCustomCheck(
    calculateNavigationItems(
      isCodeInstalled,
      isWebsiteCodeActive,
      isFacebookIntegrated,
      isEmailConfigured,
      isABCConfigured,
      isHelpDeskChannelOn,
    ),
  );
  useFetchEmailsMapping();

  return (
    <SideNavigation title="Settings" noGaps>
      {items
        .filter(({ hidden, children, path }) => !hidden && !(children && children.length === 0 && !path))
        .map((item) => {
          if (item.children && item.children.length > 0) {
            return (
              <SideNavigationGroup key={item.id} label={item.name} isCollapsible onItemHover={item.onItemHover}>
                {item.children.map((subItem) => (
                  <SettingsSideNavigationItem
                    key={subItem.id}
                    item={subItem}
                    isActive={!!subItem.path && pathname.startsWith(subItem.path)}
                  />
                ))}
              </SideNavigationGroup>
            );
          }

          return <SettingsSideNavigationItem key={item.id} item={item} isMainEntry />;
        })}
    </SideNavigation>
  );
};
