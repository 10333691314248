// @ts-strict-ignore
import { isAfter } from 'date-fns';
import isEmpty from 'lodash.isempty';
import createCachedSelector, { LruObjectCache } from 're-reselect';
import { createSelector } from 'reselect';

import { type ArchiveModal } from 'constants/archives/modal';
import { ChatEventType } from 'constants/chat-event-type';
import { type Filter, FilterOperator, type Filters, type FiltersOperators } from 'constants/filters/filter';
import { type SortOrder } from 'constants/sort-order';
import { ChatChannel, getChatChannelForArchive } from 'helpers/chat-channels';
import { getConfig } from 'helpers/config';
import { type KeyMap } from 'helpers/interface';
import { type IButtonTarget, type ISelectedWidget } from 'interfaces/customer-details';
import { ArchiveType, type IArchive, type IArchiveEvent } from 'interfaces/entities/archive';
import type { Properties } from 'interfaces/property';
import { type IArchiveTicket } from 'store/entities/archives/interfaces';
import { isClosedThread, isMyChat, isQueuedChat, isSupervisedChat } from 'store/entities/chats/helpers/common';
import { type ChatThreadEntity } from 'store/entities/chats/interfaces';
import { type IWithChatsEntityState } from 'store/entities/chats/selectors';
import {
  type IWithIntegrationLicensePropertiesState,
  getCustomerHistoryEnabled,
} from 'store/entities/integration-license-properties/selectors';
import { AgentCustomPropertyName, type ArchiveListMode } from 'store/features/agent-custom-properties/interfaces';
import {
  type IWithAgentCustomPropertiesState,
  getAgentCustomProperties,
} from 'store/features/agent-custom-properties/selectors';
import { type IWithRequestsState, createRequestFetchingSelector } from 'store/requests/selectors';

import { ViewActionSource } from '../../../_constants/view-actions-source';

import { ArchivesViewMode } from './constants';
import { getThreadStoppingEvent } from './helpers/get-thread-stopping-event';
import { type IWithArchivesViewState } from './interfaces';

const CLIENT_ID = getConfig().accountsClientId;
const SENTIMENT_CLIENT_ID = getConfig().chatSentimentNamespace;

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export function getCurrentArchive(state: IWithArchivesViewState): IArchive | null {
  return state.views.archivesView.current;
}

export function getCurrentArchiveId(state: IWithArchivesViewState): string {
  return getCurrentArchive(state)?.id;
}

export function getCurrentArchiveType(state: IWithArchivesViewState): ArchiveType {
  return getCurrentArchive(state)?.type;
}

export function getIsFetchingNew(state: IWithArchivesViewState & IWithRequestsState): boolean {
  return createRequestFetchingSelector([`FETCH_COLLECTION_ARCHIVE_${ViewActionSource.Archives}_NEW`])(state);
}

export function getIsFetchingMore(state: IWithArchivesViewState & IWithRequestsState): boolean {
  return createRequestFetchingSelector([`FETCH_COLLECTION_ARCHIVE_${ViewActionSource.Archives}_MORE`])(state);
}

export function getIsFetchingArchiveTickets(
  state: IWithArchivesViewState & IWithRequestsState,
  threadId: string,
): boolean {
  return createRequestFetchingSelector([`ENTITIES/ARCHIVE/FETCH_TICKETS_${threadId}`])(state);
}

export function archivesSelector(state: IWithArchivesViewState): { [key: string]: IArchive } {
  return state.views.archivesView.data;
}

export function getArchive(state: IWithArchivesViewState, threadId: string): IArchive {
  return state.views.archivesView.data ? state.views.archivesView.data[threadId] : null;
}

export function getArchiveThread(state: IWithArchivesViewState, threadId: string): IArchive {
  const currentArchive = getCurrentArchive(state);
  let archivedThread = getArchive(state, threadId);

  if (currentArchive?.id === threadId && !archivedThread) {
    archivedThread = currentArchive;
  }

  return archivedThread || null;
}

function archiveTicketsSelector(state: IWithArchivesViewState, threadId: string): KeyMap<IArchiveTicket> {
  return state.views.archivesView.tickets[threadId];
}

export const getArchiveTickets = createCachedSelector(archiveTicketsSelector, (archiveTickets) => {
  if (isEmpty(archiveTickets)) {
    return EMPTY_ARRAY as IArchiveTicket[];
  }

  return Object.keys(archiveTickets).map((key) => archiveTickets[key]);
})((state, threadId) => threadId, {
  cacheObject: new LruObjectCache({ cacheSize: 50 }),
});

function sortedArchivesIdsSelector(state: IWithArchivesViewState): string[] {
  return state.views.archivesView.sortedIds;
}

export const getArchives = createSelector(archivesSelector, (archives) => Object.values(archives || EMPTY_OBJECT));

const getArchivesLength = createSelector(getArchives, (archives) => archives.length);

const getSortedArchivesIds = createSelector(sortedArchivesIdsSelector, (sortedIds) => sortedIds);

export const getArchivesBySortedIds = createSelector(
  archivesSelector,
  getSortedArchivesIds,
  (archives, sortedArchivesIds) => sortedArchivesIds.map((id) => archives[id]),
);

export function getArchivesShownModal(state: IWithArchivesViewState): ArchiveModal {
  return state.views.archivesView.shownModal;
}

export function getCurrentRequestPage(state: IWithArchivesViewState): number {
  return state.views.archivesView.page;
}

export function getNextPage(state: IWithArchivesViewState): string | number {
  return state.views.archivesView.nextPage;
}

export function getTotalCount(state: IWithArchivesViewState): number {
  return state.views.archivesView.totalCount;
}

export function getNewArchivesCount(state: IWithArchivesViewState): number {
  return state.views.archivesView.newArchivesCount;
}

export function getSortOrder(state: IWithArchivesViewState): SortOrder {
  return state.views.archivesView.sortOrder;
}

export function getRecentListScrollTop(state: IWithArchivesViewState): number {
  return state.views.archivesView.recentListScrollTop;
}

export function getTotalPages(state: IWithArchivesViewState): number {
  return state.views.archivesView.totalPages;
}

export function getSearchPhrase(state: IWithArchivesViewState): string {
  return state.views.archivesView.searchPhrase;
}

export function getFilters(state: IWithArchivesViewState): Filters {
  return state.views.archivesView.filters;
}

export const getFiltersSelector = createSelector(getFilters, (filters: Filters) => filters);

export function getFiltersOrder(state: IWithArchivesViewState): string[] {
  return state.views.archivesView.filtersOrder;
}

export function getFiltersOperators(state: IWithArchivesViewState): FiltersOperators {
  return state.views.archivesView.filtersOperators;
}

export function getCurrentArchiveTags(state: IWithArchivesViewState): string[] {
  return state.views.archivesView.current?.tags || (EMPTY_ARRAY as string[]);
}

export function getCurrentProcessingTags(state: IWithArchivesViewState): string[] {
  return state.views.archivesView.current?.tagsProcessing || (EMPTY_ARRAY as string[]);
}

export function getCurrentFailureTagsAdditions(state: IWithArchivesViewState): string[] {
  return state.views.archivesView.current?.tagsFailureAdditions || (EMPTY_ARRAY as string[]);
}

export function getCurrentFailureTagsDeletions(state: IWithArchivesViewState): string[] {
  return state.views.archivesView.current?.tagsFailureDeletions || (EMPTY_ARRAY as string[]);
}

export function getSelectedWidget(state: IWithArchivesViewState): ISelectedWidget {
  return state.views.archivesView.selectedWidget;
}

export function getButtonTarget(state: IWithArchivesViewState): IButtonTarget {
  return state.views.archivesView.buttonTarget;
}

export const getAreAnySearchParamsApplied = createSelector(
  getFiltersSelector,
  getSearchPhrase,
  (filters, searchPhrase) => searchPhrase !== '' || Object.keys(filters).some((filterName) => !!filters[filterName]),
);

export const getArchivesViewMode = createSelector(
  getIsFetchingNew,
  getIsFetchingMore,
  getArchivesLength,
  getAreAnySearchParamsApplied,
  (isFetchingNew, isFetchingMore, archivesLength, areAnySearchParamsApplied) => {
    if (isFetchingNew) {
      return ArchivesViewMode.Searching;
    }

    if (archivesLength === 0 && !isFetchingNew && !isFetchingMore) {
      if (areAnySearchParamsApplied) {
        return ArchivesViewMode.NoResults;
      }

      return ArchivesViewMode.Empty;
    }

    return ArchivesViewMode.Data;
  },
);

/**
 * Returns `true` if thread supports replying from archives. Scenarios:
 * 1. Chat Widget source requires chat history enabled.
 * 2. Facebook chat has no requirements.
 * @param state Redux state.
 * @param threadId Id of thread to verify.
 */
export function getThreadSupportsReplyFromArchives(
  state: IWithArchivesViewState & IWithIntegrationLicensePropertiesState,
  threadId: string,
): boolean {
  const archivedThread = getArchiveThread(state, threadId);

  if (!archivedThread || archivedThread.type === ArchiveType.Missed) {
    return false;
  }

  const chatChannel = getChatChannelForArchive(archivedThread);

  if (chatChannel === ChatChannel.FacebookMessenger) {
    // TODO: uncomment when 24h + 1 policy will be supported by API.
    // return true;
    return false;
  }

  if (chatChannel === ChatChannel.ChatWidget) {
    return getCustomerHistoryEnabled(state);
  }

  return false;
}

function getLatestThreadBy(
  state: IWithChatsEntityState,
  predicate: (thread: ChatThreadEntity) => boolean,
): ChatThreadEntity {
  const threads = Object.values(state.entities.chats.threads).filter(predicate);
  threads.sort((threadA, threadB) => threadB.startedTimestamp - threadA.startedTimestamp);

  return threads[0];
}

export function getLatestThreadByChatId(state: IWithChatsEntityState, chatId: string): ChatThreadEntity {
  return getLatestThreadBy(state, (thread: ChatThreadEntity) => thread.chatId === chatId);
}

/**
 * Returns true if there is any active chat related to an archived thread.
 * @param state  Redux state using `IWithArchivesViewState` and `IWithChatsEntityState` structure.
 * @param threadId Id of thread to verify.
 */
export function getIsArchivedThreadAnActiveChat(
  state: IWithArchivesViewState & IWithChatsEntityState,
  threadId: string,
): boolean {
  const archivedThread = getArchive(state, threadId);

  if (!archivedThread) {
    return false;
  }

  const thread = getLatestThreadByChatId(state, archivedThread.chatId);

  if (!thread) {
    return false;
  }

  if (!isClosedThread(thread) && (isMyChat(thread) || isSupervisedChat(thread) || isQueuedChat(thread))) {
    return true;
  }

  return false;
}

export function getDetailsConfigurationSelected(state: IWithAgentCustomPropertiesState): string[] {
  const customProperties = getAgentCustomProperties(state);

  return customProperties && (customProperties[AgentCustomPropertyName.ArchiveDetailsSelected] as string[]);
}

export function getDetailsConfigurationOrder(state: IWithAgentCustomPropertiesState): string[] {
  const customProperties = getAgentCustomProperties(state);

  return customProperties && (customProperties[AgentCustomPropertyName.ArchiveDetailsOrder] as string[]);
}

export function getListMode(state: IWithAgentCustomPropertiesState): ArchiveListMode {
  const customProperties = getAgentCustomProperties(state);

  return customProperties && (customProperties[AgentCustomPropertyName.ArchiveListMode] as ArchiveListMode);
}

export const getArchiveChatId = (state: IWithArchivesViewState, threadId: string): string => {
  const archive = getArchive(state, threadId);

  return archive?.chatId;
};

export const getFilterOperator = (state: IWithArchivesViewState, filter: Filter): FilterOperator => {
  const operators = getFiltersOperators(state);

  return operators[filter] || FilterOperator.Or;
};

export function getArchiveScrollPosition(state: IWithArchivesViewState, threadId: string): number {
  const scrollPosition = state.views.archivesView.scrollPosition[threadId];

  return typeof scrollPosition === 'number' ? scrollPosition : 0;
}

export const getArchivedMessageReaction = (state: IWithArchivesViewState, eventId: string): string | null => {
  const currentArchive = getCurrentArchive(state);
  const emojiReaction = currentArchive?.events?.find((event) => event.eventId === eventId)?.messageReaction;

  return emojiReaction;
};

export function getCurrentArchiveEvents(state: IWithArchivesViewState): IArchiveEvent[] {
  return state.views.archivesView.current?.events || (EMPTY_ARRAY as IArchiveEvent[]);
}

function getCurrentArchiveEvent(state: IWithArchivesViewState, eventId: string): IArchiveEvent | null {
  return state.views.archivesView.current?.events?.find((event) => event.eventId === eventId) || null;
}

export function getCurrentArchiveGroupsIds(state: IWithArchivesViewState): string[] {
  return state.views.archivesView.current?.groupIds.map(String) || (EMPTY_ARRAY as string[]);
}

function getCurrentArchiveThreadIntegrationProperties(
  state: IWithArchivesViewState,
  clientId: string,
): Properties | null {
  const thread = getCurrentArchive(state);

  if (!thread?.customProperties?.[clientId]) return null;

  return thread.customProperties[clientId] as Properties;
}

export function getCurrentArchiveThreadIntegrationProperty(
  state: IWithArchivesViewState,
  propertyName: string,
): string | null {
  const propertiesAgentApp = getCurrentArchiveThreadIntegrationProperties(state, CLIENT_ID);
  const propertiesSentiment = getCurrentArchiveThreadIntegrationProperties(state, SENTIMENT_CLIENT_ID);
  const properties = { ...propertiesAgentApp, ...propertiesSentiment };

  if (!properties) return null;

  return properties?.[propertyName]?.toString() || null;
}

export function getCurrentArchiveNumberOfMessagesFromLastChatSummary(
  state: IWithArchivesViewState,
  chatSummaryDate: Date,
): number {
  return getCurrentArchiveEvents(state).reduce((count, event) => {
    if (event.type === ChatEventType.Message && isAfter(event.timestampInMs, new Date(chatSummaryDate))) {
      return count + 1;
    }

    return count;
  }, 0);
}

export function getCurrentArchiveAgentChattingTime(state: IWithArchivesViewState): number | null {
  const { events, startedTimestamp, queuedFor } = getCurrentArchive(state);

  if (!events) return null;

  const lastEvent = getThreadStoppingEvent(events);

  if (!lastEvent) return null;

  const queueTime = queuedFor ?? 0;
  const agentChattingTime = (lastEvent.timestampInMs - startedTimestamp) / 1000 - queueTime;

  return agentChattingTime;
}

export function getIsMessageFormattedInArchives(state: IWithArchivesViewState, eventId: string): boolean {
  const event = getCurrentArchiveEvent(state, eventId);

  return Boolean(event?.formatting);
}
