import type { CannedResponse } from 'interfaces/canned-responses';

export enum SuggestedResponsesTrigger {
  OnBadRate = 'OnBadRate',
  OnGoodRate = 'OnGoodRate',
  OnIdle = 'OnIdle',
  OnStartChat = 'OnStartChat',
  OnTransferChat = 'OnTransferChat',
}

interface TriggerDetails {
  trigger: SuggestedResponsesTrigger;
  triggers?: SuggestedResponsesTrigger[];
  label: string;
}

export type ISuggestedCannedResponse = CannedResponse &
  Pick<TriggerDetails, 'trigger' | 'triggers'> & { isActive?: boolean };

export interface SuggestedResponse extends TriggerDetails {
  cannedResponses: CannedResponse[];
}

export type ISuggestedResponses = Record<SuggestedResponsesTrigger, SuggestedResponse>;
