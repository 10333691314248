import { LoginStatus } from 'constants/login-status';
import { AgentSerializer } from 'services/api/agent/v3/agent-serializer';
import type { IAppState } from 'services/app-state-provider';
import { agentsClient } from 'services/connectivity/configuration-api/agents/client';
import { AgentField, type Agent } from 'services/connectivity/configuration-api/agents/types';
import { AgentActions } from 'store/entities/agents/actions';
import { GroupActions } from 'store/entities/groups/actions';
import { SubscriptionViewActions } from 'store/views/subscription/actions';

export async function handleAgentCreated(payload: Agent, store: IAppState): Promise<void> {
  store.dispatch(SubscriptionViewActions.agentsCountChanged());
  const deserializedPayload = AgentSerializer.deserialize(payload, LoginStatus.Offline);
  store.dispatch(AgentActions.agentAdd(deserializedPayload));
  store.dispatch(GroupActions.groupsFetch());

  /**
   * This is needed to fetch agent accountId to properly send invite reminder.
   * Currently, payload of agent_created event doesn't contain accountId.
   * It will be added in agent-api 3.6, with migration of identification fields.
   *
   * @see {@link https://text.slack.com/archives/C2FAU97K2/p1728545093689559?thread_ts=1728544934.348059&cid=C2FAU97K2}
   */
  const { error, result } = await agentsClient.get({ id: deserializedPayload.login, fields: [AgentField.Groups] });

  if (error) {
    return;
  }

  const agent = AgentSerializer.deserialize(result, LoginStatus.Offline);
  store.dispatch(AgentActions.agentUpdate(agent));
}
