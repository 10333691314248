import {
  KnowledgeSourceItemStatus,
  KnowledgeSourceType,
  type KnowledgeSourceItem,
  type KnowledgeSourcePdfItem,
  type KnowledgeSourceFileItem,
} from 'interfaces/knowledge-sources';

export const getIsLearnedKnowledgeSource = (data: KnowledgeSourceItem[] = []): boolean =>
  data.some(
    (item) =>
      item.status === KnowledgeSourceItemStatus.COMPLETED ||
      (item.status === KnowledgeSourceItemStatus.FAILED && !!item?.learnedAt),
  );

export function isSourceFileType(item: unknown): item is KnowledgeSourceFileItem {
  return (item as KnowledgeSourcePdfItem).sourceType === KnowledgeSourceType.File;
}
