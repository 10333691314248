import { useEffect, type FC } from 'react';

import { setTitle } from 'helpers/routing';

export interface IRouteView {
  title?: string;
}

export const RouteView: FC<IRouteView> = ({ title, children }) => {
  useEffect(() => {
    if (title) {
      setTitle(title);
    }
  }, [title]);

  return <>{children}</>;
};
