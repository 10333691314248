import { useMutation, type UseMutateFunction } from '@tanstack/react-query';

import { getQueryClient } from 'services/query-client/client';

import { PINNED_WIDGETS_KEY } from './constants';
import { usePinnedFullscreenWidgetStorage } from './use-pinned-fullscreen-widgets-storage';

interface IUsePinnedFullscreenWidget {
  togglePin: UseMutateFunction<void, unknown, void, unknown>;
  isPinned: boolean;
}

export const updatePinnedFullscreenWidgets = (pinnedWidgetIds: string[], appId: string): string[] => {
  if (pinnedWidgetIds.includes(appId)) {
    return pinnedWidgetIds.filter((id) => id !== appId);
  }

  return [...pinnedWidgetIds, appId];
};

export const usePinnedFullscreenWidget = (appId: string): IUsePinnedFullscreenWidget => {
  const { data: pinnedWidgetIds, save } = usePinnedFullscreenWidgetStorage();

  const mutation = useMutation(
    async () => {
      const updatedValue = updatePinnedFullscreenWidgets(pinnedWidgetIds, appId);
      save(updatedValue);
    },
    {
      onSuccess: async () => {
        const queryClient = getQueryClient();
        await queryClient.invalidateQueries([PINNED_WIDGETS_KEY]);
      },
    }
  );

  return {
    togglePin: mutation.mutate,
    isPinned: pinnedWidgetIds?.includes(appId),
  };
};
