import { type FC, type MouseEvent } from 'react';

import { cx } from '@emotion/css';
import { Pinned, PinnedFilled } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';

import { usePinnedFullscreenWidget } from 'hooks/fullscreen-widgets/use-pinned-fullscreen-widget';

import { pin, pinIcon } from './styles';

interface Props {
  appId: string;
}

export const YourAppsItemPin: FC<Props> = ({ appId }) => {
  const { isPinned, togglePin } = usePinnedFullscreenWidget(appId);
  const icon = isPinned ? PinnedFilled : Pinned;

  const togglePinnedWidget = (event: MouseEvent<HTMLSpanElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    togglePin();
  };

  return (
    <span className={cx(['display-on-hover', 'hide-siblings-on-hover', pin])} onClick={togglePinnedWidget}>
      <Icon source={icon} className={pinIcon} />
    </span>
  );
};
