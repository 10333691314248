import type { FC } from 'react';

import { NavigationTopBarAlert } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { GlobalModal } from 'constants/global-modal';
import { InviteAgentsSource } from 'constants/invite-agents-source';
import { GlobalModalActions } from 'store/features/global-modals/actions';

interface Props {
  isVisible: boolean;
}

export const SendPendingInvitesBar: FC<Props> = ({ isVisible }) => {
  const dispatch = useDispatch();

  const handleCompleteInvites = (): void => {
    dispatch(
      GlobalModalActions.showModal(GlobalModal.InviteAgents, { source: InviteAgentsSource.PendingInvitesTopBar }),
    );
  };

  return (
    <NavigationTopBarAlert
      kind="warning"
      isVisible={isVisible}
      primaryCta={{
        label: 'Complete invites',
        onClick: handleCompleteInvites,
        kind: 'high-contrast',
      }}
    >
      <div>Some agents can’t start chatting. Finish inviting your team.</div>
    </NavigationTopBarAlert>
  );
};
