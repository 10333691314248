/* eslint-disable @typescript-eslint/naming-convention */

import { CustomDateRangeFilterId } from 'constants/filters/date-range-filter';
import { type ReportDistribution } from 'constants/reports/distribution';
import { type IReportsViewUpdateFiltersPayload } from 'interfaces/reports';

export const REPORT_URL_BY_NAME_CONFIG: Record<string, string> = {
  total_chats: '/reports/total-chats',
  chat_ratings: '/reports/chat-ratings',
  goals: '/reports/goals',
  dashboard: '/reports/dashboard',
  agents_performance: '/reports/agent-performance',
  most_frequent_questions: '/reports/top-customer-questions',
  tag_trends: '/reports/top-customer-questions',
};

export function mapResponseToViewFilters(filters: Record<string, unknown>): IReportsViewUpdateFiltersPayload {
  const viewFilters: IReportsViewUpdateFiltersPayload = {};

  if (Array.isArray(filters.agents)) {
    {
      viewFilters.agent = {
        agents: filters.agents,
      };
    }
  }

  if (filters.distribution) {
    viewFilters.distribution = filters.distribution as ReportDistribution;
  }

  if (filters.date_from && filters.date_to) {
    viewFilters.dateRange = {
      from: new Date(filters.date_from as string),
      to: new Date(filters.date_to as string),
      id: CustomDateRangeFilterId.CustomPeriod,
    };
  }

  if (filters.from && filters.to) {
    viewFilters.dateRange = {
      from: new Date(filters.from as string),
      to: new Date(filters.to as string),
      id: CustomDateRangeFilterId.CustomPeriod,
    };
  }

  return viewFilters;
}
