import { type FC } from 'react';

import { FilterOperator } from 'constants/filters/filter';

import { type FilterLabelValue } from './interfaces';

export type FilterLabelValueType = string | FilterLabelValue[];

interface IProps {
  value: FilterLabelValueType;
  operator?: FilterOperator;
}

export const SelectedFilterLabel: FC<IProps> = ({ value, operator = FilterOperator.Or }) => {
  if (!value) {
    return null;
  }

  if (Array.isArray(value)) {
    const divider = operator === FilterOperator.And ? operator : FilterOperator.Or;

    return (
      <span>
        {operator === FilterOperator.Exclude && <strong>Is not </strong>}
        {value.map(({ key, label }, index) =>
          index === 0 ? (
            <span key={key}>{label}</span>
          ) : (
            <span key={key}>
              <strong> {divider.toUpperCase()} </strong>
              <span>{label}</span>
            </span>
          )
        )}
      </span>
    );
  }

  return <span>{value}</span>;
};
