import type { KeyMap } from 'helpers/interface';
import type { CustomSectionDefinition } from 'interfaces/custom-section';

import { type EnityCollectionFetch } from '../interfaces';

export enum ApplicationWidgetPlacement {
  Plugin = 'plugin',
  Settings = 'settings',
  FullScreen = 'fullscreen',
  MessageBox = 'messagebox',
  Subscription = 'subscription',
}

export enum ChatApplicationDisabledFeatures {
  BanCustomer = 'ban_customer',
  Attachments = 'attachments',
}

export enum Frequency {
  Monthly = 'monthly',
  Yearly = 'yearly',
  Once = 'once',
}

export enum FrequencyResource {
  Monthly = 1,
  Yearly = 12,
  Once = 0,
}

export interface IPayment {
  creditCardRequired: boolean;
  frequency: Frequency;
  perAccount: boolean;
  price: number;
  trialDays: number;
}

export interface IChatActionButton {
  action: string;
  label: string;
  icon: string;
  id: string;
  key: string;
  url: string;
  appId: string;
  widgetId?: string;
}

export interface IApplicationWidget {
  id: string;
  placement: ApplicationWidgetPlacement;
  url: string;
  shortcut?: {
    initialState: string;
  };
  appId: string;
  appSlug?: string;
  name: string;
  icon: string;
  interfaceIcon?: string;
  interfaceDarkIcon?: string;
  alternativeFullscreenPath?: string;
  isInternalProduct?: boolean;
  tooltip?: {
    enabled: boolean;
    msg: string;
    onClose?: () => void;
  };
}

export interface IApplicationButton {
  action: string;
  id: string;
  label: string;
  placement: string;
  sourceUrl: string;
  url: string;
  widgetId?: string;
  appId?: string;
  icon?: string;
  key?: string;
}

export interface IApplicationElements {
  widgetIds: string[];
  widgets: KeyMap<IApplicationWidget>;
  buttonIds: string[];
  buttons: KeyMap<IApplicationButton>;
}

export interface IAppAuthorization {
  clientId: string;
  redirectUrl: string;
}

export interface IInstalledApps {
  byIds: KeyMap<IApplication>;
  channels: {
    byClientIds: KeyMap<IChatApplication>;
  };
}
export interface IApplicationsState {
  installed: IInstalledApps;
  private: IApplication[];
  inReview: IApplication[];
  privateWidgets: { byIds: KeyMap<CustomSectionDefinition> };
  appIdByWidgetId: KeyMap<string>;
  isInitialized: boolean;
  selectedApplications: string[];
  unselectedApplications: string[];
  applicationsOrder: string[];
}

export interface IChatApplication {
  clientId: string;
  channelIcon: string;
  isChatChannel: boolean;
  disabledFeatures: ChatApplicationDisabledFeatures[];
}

export interface IApplicationIcons {
  small: string;
  large: string;
  title?: string;
}

export interface IApplication {
  authorization?: IAppAuthorization;
  id: string;
  name: string;
  elements: IApplicationElements;
  slug: string;
  isService: boolean;
  hasSettingsHidden?: boolean;
  isUninstallableOnlyByOwners?: boolean;
  isInternalProduct?: boolean;
  isPaidApp?: boolean;
  clientId?: string;
  description?: string;
  publishedAt?: string;
  tutorialUrl?: string;
  ownerId?: string;
  directInstallUrl?: string;
  icons?: IApplicationIcons;
  interfaceIcons: IApplicationIcons;
  interfaceDarkIcons?: IApplicationIcons;
  payment?: IPayment;
  goToWebsiteUrl?: string;
  widgets?: IWidget[];
  isReportApp?: boolean;
  alternativeFullscreenWidgetPath?: string;
  customProps?: Record<string, unknown> | null;
}

export interface IWidget extends IApplicationWidget {
  appId: string;
  name: string;
  icon: string;
}

export interface IResourcePrice {
  cents: number;
  months: number;
  trialDays: number;
  perAgent: boolean;
}

export type ResourceDateFormat = Date | string | null;

export interface IResourceTransaction {
  id: string;
  quantity: number;
  switch: {
    enabled: ResourceDateFormat;
    disabled: ResourceDateFormat;
  };
  payment: {
    id: string;
    started: ResourceDateFormat;
    cancelled: ResourceDateFormat;
    perAgent: boolean;
    agents: number;
    centsPerUnit: number;
  };
  isActive?: boolean;
}

export interface IInAppUpgrade {
  appId: string;
  resourceId: string;
  upgradeId: string | null;
  transactions: IResourceTransaction[];
  quantity: number;
  isActive: boolean;
  name: string;
  price: IResourcePrice | null;
  description: string;
  multiplied: boolean;
  payPerAgent: boolean;
  trialDays: number;
}

export interface IApplicationCategory {
  id: string;
  name: string;
  slug: string;
}

export interface RefreshInstalledApplicationsData extends EnityCollectionFetch {
  notifyMarketplace?: boolean;
  installedAppId?: string;
}
