export const enum ReconnectionStatus {
  Idle = 'idle',
  Scheduled = 'scheduled',
  Retrying = 'retrying',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export const enum ReconnectorEvent {
  RECONNECTION_DETAILS_CHANGED = 'reconnectionDetailsChanged',
}

export type ReconnectorEventMap = {
  [ReconnectorEvent.RECONNECTION_DETAILS_CHANGED]: ReconnectionDetails;
};

export type ReconnectionDetails = {
  /**
   * Date (timestamp) of next reconnect attempt,
   */
  nextReconnectAttemptTimestamp: number | null;
  /**
   * Reconnector status:
   * "scheduled" - waiting for next reconnect attempt, it is known that it will happen
   * "retrying" - reconnect attempt is in progress
   * "failed" - all of given reconnect attempts failed
   * "succeeded" - reconnection succeeded; reconnector finished his job
   */
  status: ReconnectionStatus;
  /**
   * Displays a button for manual reconnection if set to "true" and when "status" is equal to "failed"
   */
  allowManual?: boolean;
};

export type Reconnection = {
  reason: string;
  maxAttempts?: number;
  onFail?: (error: unknown) => void;
};
