import { type FC } from 'react';

import { ChevronRight, ChevronUp } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { useLocation } from 'react-router-dom';

import { SideNavigationItem } from 'components/side-navigation-v2/SideNavigationItem';
import { SideNavigationList } from 'components/side-navigation-v2/SideNavigationList';
import { VISIBLE_APPS_BY_DEFAULT } from 'constants/apps/side-navigation';
import { useSessionStorage } from 'hooks/use-session-storage';
import { useAllVisibleApps } from 'routes/apps/hooks/use-all-visible-apps';
import { useSelectedVisibleApps } from 'routes/apps/hooks/use-selected-visible-apps';

import { normalizeListPathname } from '../helpers';

import { AppsConfigurationList } from './AppsConfigurationList/AppsConfigurationList';
import { INSTALLED_APPS_STORAGE_KEY } from './constants';
import { YourAppsItem } from './YourAppsItem/YourAppsItem';

import { labelStyles, listStyles } from './styles';

export const YourAppsList: FC = () => {
  const { pathname } = useLocation();
  const listPathname = normalizeListPathname(pathname);
  const [areAllAppsVisible, setAreAllAppsVisible] = useSessionStorage<boolean>(INSTALLED_APPS_STORAGE_KEY, false);

  const showMoreApplications = (): void => setAreAllAppsVisible(true);
  const showLessApplications = (): void => setAreAllAppsVisible(false);

  const [selectedVisibleApps] = useSelectedVisibleApps();
  const allApps = useAllVisibleApps();

  const displayedApps = areAllAppsVisible ? allApps : selectedVisibleApps;

  const areAppsExceedingVisibilityLimit = allApps.length > VISIBLE_APPS_BY_DEFAULT;
  const shouldDisplayExpansionItem = areAppsExceedingVisibilityLimit && selectedVisibleApps.length < allApps.length;

  if (!allApps.length) {
    return null;
  }

  return (
    <SideNavigationList
      label="Your apps"
      rightNode={allApps.length > 1 && <AppsConfigurationList />}
      className={listStyles}
      labelWrapperClassName={labelStyles}
    >
      {displayedApps.map((app) => (
        <YourAppsItem key={app.id} app={app} listPathname={listPathname} />
      ))}

      {shouldDisplayExpansionItem && areAllAppsVisible && (
        <SideNavigationItem icon={<Icon source={ChevronUp} />} label="Show less" onClick={showLessApplications} />
      )}

      {shouldDisplayExpansionItem && !areAllAppsVisible && (
        <SideNavigationItem icon={<Icon source={ChevronRight} />} label="Show more" onClick={showMoreApplications} />
      )}
    </SideNavigationList>
  );
};
